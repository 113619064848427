.N0drt {
  position: relative;
  display: flex;
  white-space: nowrap;
}

._1yV4d {
  padding: 6px 0;
  display: flex;
  transition: opacity 0.2s ease-in-out;
}

._1yV4d:hover {
  opacity: 0.7;
}

._1yV4d img {
  height: 28px;
}

.N0drt:hover ._10GhC {
  opacity: 1;
  visibility: visible;
}

._10GhC {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  right: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  background: #fff;
  transition: 0.1s ease-in-out;
}

._2hgaM {
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

._2hgaM img {
  margin-bottom: 24px;
}

@media screen and (max-width: 768px) {
  ._10GhC {
    position: fixed;
    left: 0;
    right: 0;
    top: 60px;
    background: white;
    border-radius: 0;
    border-width: 1px 0;
  }

  ._2hgaM {
    padding: 24px;
  }
}

@media (min-width: 1024px) {
  ._2sag2 {
    height: 60px;
    padding: 16px 0;
    cursor: pointer;
    margin-left: 48px;
  }

  .uOOHp {
    height: 28px;
  }

  ._2au4b.IaE73 {
    height: 295px;
    width: 650px;
  }

  ._28pJb ._3iptE h1 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin: 0 0 16px 0;
  }

  ._28pJb ._3iptE h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin: 0 0 11px 0;
  }

  ._28pJb ._3iptE span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
    margin-bottom: 10px;
  }

  ._28pJb ._3iptE a {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    color: #696969;
    text-decoration: none;
  }

  ._28pJb {
    margin: 30px 34px 31px 30px;
    display: flex;
  }

  ._28pJb .NMkkX {
    margin: auto;
    padding-right: 24px;
    margin-right: 24px;
    border-right: 1px solid #ccc;
  }

  ._28pJb .NMkkX,
  ._28pJb .NMkkX a {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-decoration: none;
  }

  ._28pJb .NMkkX li:not(:last-of-type) {
    margin-bottom: 12px;
  }

  ._28pJb .NMkkX span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #000000;
    text-decoration: none;
    cursor: pointer;
  }

  ._28pJb .NMkkX div {
    margin-bottom: 25px;
    white-space: nowrap;
    justify-content: space-between;
  }

  ._28pJb .NMkkX div:last-child {
    margin-bottom: 0;
  }

  .m_Y07 {
    height: 172px;
    left: 1246px;
    top: 117px;
    border: 1px solid #e9e9e9;
    margin: 32px 30px 30px 45px;
  }
}

@media (max-width: 1023px) {
  ._2sag2:hover > ._2au4b {
    opacity: 1;
    visibility: visible;
  }

  ._2au4b {
    display: flex;
    justify-content: center;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    height: 295px;
    width: 346px;
    position: absolute;
    top: 130%;
    right: 10%;
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
    background: #fff;
    transition: 0.1s ease-in-out;
  }

  .uOOHp {
    height: 26px;
    margin-right: 20px;
  }

  ._2au4b.IaE73 {
    height: 354px;
    width: 346px;
  }

  ._28pJb ._3iptE h1 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 16px 0;
  }

  ._28pJb ._3iptE h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 5px 0;
  }

  ._28pJb ._3iptE span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
    margin-bottom: 29px;
  }

  ._28pJb ._3iptE a {
    display: none;
  }

  ._28pJb {
    display: block;
    padding: 30px 18px 0 18px;
  }

  ._28pJb .NMkkX {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    /* padding: 0 23px; */
    align-items: baseline;
    justify-content: space-around;
  }

  ._28pJb .NMkkX a {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #000000;
    text-decoration: none;
  }

  ._28pJb .NMkkX span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #000000;
    text-decoration: none;
  }

  ._28pJb .NMkkX div {
    margin-bottom: 5px;
    white-space: nowrap;
    /*justify-content: space-between;*/
    background: #f7f7f7;
    border-radius: 3px;
    padding: 14px 0;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ._28pJb .NMkkX div:last-child {
    margin-bottom: 0;
  }

  .m_Y07 {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .uOOHp {
    width: 26px;
    height: 26px;
    margin-top: 13px;
    margin-bottom: 13px;
    justify-self: center;
    flex: auto;
    margin-right: 16px;
  }
}
