@media(min-width: 1024px) {
    .wrapper {
        padding: 51px 170px 50px;
        width: 1440px;
        margin: auto;
        display: flex;
        justify-content: space-between;
    }

    .signInWrapper {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 37px 53px 46px 53px;
        width: 527px;
        /*height: 502px;*/
    }



    .wrapper span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
    }

    .logWithApp img {
        margin-right: 8px;
    }

    .signInWrapper p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
        margin-bottom: 17px;
    }

    .inputStyle {
        background: #FFFFFF;
        border: 1px solid #777777;
        width: 100%;
        box-sizing: border-box;
        border-radius: 3px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        padding: 15px 19px;
        color: #A9A9A9;
    }
    .inputStyle:focus {
        background: #FBFBFB;
        text-decoration: none;
        outline: none;
    }

    .buttonConnect {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 244px;
        height: 48px;
        border: 1px solid #000000;
        border-radius: 3px;
        box-sizing: border-box;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-decoration: none;
        outline: none;
        cursor: pointer;
        background-color: #000000;
        color: #FFFFFF;
        transition: all .2s ease-in-out;
    }

    .buttonConnect:hover {
        background-color: #FFFFFF;
        color: #000000;
    }

    .registerWrapper .buttonConnect {
        margin-top: 28px;
    }

    .signInWrapper .buttonConnect {
        margin-top: 40px;
    }

    .rightObject {
        display: flex;
        justify-content: flex-end;
    }

    .rightObject a {
        margin-bottom: 50px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #696969;
    }

    .signInWrapper .logWithApp {
        display: flex;
        justify-content: space-between;
        margin-bottom: 46px;
        margin-top: 20px;
    }

    .registerWrapper .logWithApp {
        display: flex;
        justify-content: space-between;
        margin-bottom: 29px;
        margin-top: 20px;
    }

    .blockAppLog {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 191px;
        height: 47px;
        border: 1px solid #A7A7A7;
        box-sizing: border-box;
        border-radius: 3px;
        text-decoration: none;
    }

    .registerWrapper {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 25px 53px 46px 53px;
        width: 527px;
        height: 502px;
    }

    .registerWrapper p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 17px;
        color: #696969;
        margin-top: 5px;
        margin-bottom: 22px;
    }

    .wrapper h1 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #000000;
        margin: 0;
    }

    .blockReinsurance {
        display: flex;
        align-items: center;
        padding: 0 53px;
        margin-bottom: 18px;
    }

    .reinsurance {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: wrap;
    }

    .reinsurance span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #959595;
    }

}

@media(max-width: 768px) {
    .wrapper {
        /*padding: 51px 170px 50px;*/
        /*width: 1440px;*/
        margin: auto;
        display: block;
        justify-content: space-between;
    }

    .signInWrapper {
        background: #FFFFFF;
        border-top: 1px solid #F1F1F1;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 36px 21px 46px 21px;
        width: 100%;
        height: 502px;
        /*display: flex;*/
        /*align-items: center;*/
        text-align: center;
    }



    .wrapper span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
    }

    .logWithApp img {
        margin-right: 8px;
    }

    .signInWrapper p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
        margin-bottom: 17px;
    }

    .inputStyle {
        background: #FFFFFF;
        border: 1px solid #777777;
        width: 100%;
        box-sizing: border-box;
        border-radius: 3px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        padding: 15px 19px;
        color: #A9A9A9;
    }
    .inputStyle:focus {
        background: #FBFBFB;
        text-decoration: none;
        outline: none;
    }

    .buttonConnect {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 42px;
        border: 1px solid #000000;
        border-radius: 3px;
        box-sizing: border-box;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-decoration: none;
        outline: none;
        cursor: pointer;
        background-color: #000000;
        color: #FFFFFF;
        transition: all .2s ease-in-out;
    }

    .buttonConnect:hover {
        background-color: #FFFFFF;
        color: #000000;
    }

    .registerWrapper .buttonConnect {
        margin-top: 28px;
    }

    .signInWrapper .buttonConnect {
        margin-top: 40px;
    }

    .rightObject {
        display: flex;
        justify-content: flex-end;
    }

    .rightObject a {
        margin-bottom: 50px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #696969;
    }

    .signInWrapper .logWithApp {
        display: flex;
        justify-content: space-between;
        margin-bottom: 46px;
        margin-top: 20px;
    }

    .registerWrapper .logWithApp {
        display: flex;
        justify-content: space-between;
        margin-bottom: 29px;
        margin-top: 20px;
    }

    .registerWrapper {
        background: #FAFAFA;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 36px 21px 46px 21px;
        width: 100%;
        height: 502px;
        text-align: center;
    }

    .blockAppLog {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 146px;
        height: 47px;
        border: 1px solid #A7A7A7;
        box-sizing: border-box;
        border-radius: 3px;
        text-decoration: none;
    }

    .registerWrapper .optionalText   {
        display: none;
    }

    .registerWrapper p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 17px;
        color: #696969;
        margin-top: 5px;
        margin-bottom: 22px;
    }

    .wrapper h1 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #000000;
        margin: 0;
    }

    .blockReinsurance {
        display: flex;
        align-items: center;
        padding: 0 36px;
        margin-bottom: 18px;
        margin-top: 21px;
    }

    .reinsurance {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: wrap;
    }

    .reinsurance span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #959595;
    }

}
