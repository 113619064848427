._2c7-c {
  position: relative;
  text-decoration: none;
  overflow: hidden;
  transition: 0.1s opacity ease-in;
  border-radius: 4px;
}

._2c7-c.transparent {
  background: transparent;
}

._2c7-c.transparent ._1i17T {
  background: transparent;
}

._2c7-c > * {
  color: #111;
}

._2c7-c:hover {
  opacity: 0.8;
}

._2c7-c h3 {
  margin: 0;
}

._2c7-c h3 span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

._38cLy {
  color: #ef394f;
}

.gcx-S {
  margin-left: 5px;
  font-weight: normal;
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

._1nuwN {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #000;
}

._1zacO {
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

._1zacO,
._3GUlw {
  color: #000;
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
}

._3GUlw {
  padding-left: 12px;
  position: absolute;
  right: 12px;
  top: 12px;
}

._3GUlw::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  background: #ef394f;
  border-radius: 50%;
}

.rWCZQ,
._3QNZb {
  text-transform: capitalize;
}

._3niGs {
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase !important;
  color: #000000;
  font-size: 16px;
}

@media (min-width: 1024px) {
  ._2Qg4h {
    padding: 0 14px;
  }
  ._2c7-c {
    display: block;
    background: #fafafa;
    overflow: hidden;
  }

  ._2c7-c.transparent {
    background: transparent;
  }

  ._2c7-c ._1i17T {
    display: block;
    margin: auto;
    width: 180px;
    height: 260px;
  }

  ._1nuwN {
    display: flex;
    align-items: center;
    margin-top: 23px;
    margin-bottom: 14px;
  }

  .rWCZQ {
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin: 5px 0;
  }

  ._3QNZb {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin: 5px 0;
  }
}

@media (max-width: 1023px) {
  ._2c7-c {
    display: inline-block;
    overflow: hidden;
  }

  ._2c7-c ._1i17T {
    width: 100%;
    padding: 0 12px;
    height: auto;
    background: #fafafa;
  }

  ._3niGs {
    font-size: 13px;
  }

  ._2FjEw {
    position: absolute;
    margin: 14px 0 0 15px;
    display: flex;
    align-items: center;
  }

  ._2FjEw ._52u7U {
    margin-right: 4px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #ef394f;
  }

  ._2FjEw span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 15px;
    align-items: center;
    text-transform: uppercase;
  }

  .rWCZQ {
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #555;
  }

  ._3QNZb {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #000000;
  }

  .gcx-S {
    font-size: 12px;
  }

  ._1nuwN {
    font-size: 13px;
    margin-top: 12px;
  }
}

._1vmmm {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ef394f;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
}

._38ID4 {
  background-image: url(/assets/src/components/Card/green-beauty.png);
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: contain;
}

._28_LV {
  width: 44px;
  position: absolute;
  right: 12px;
  top: 12px;
  background-color: #3c004c;
  text-shadow: 0px 0px 4px #f900fa;
  color: #fff;
  font-family: "TT Norms", sans-serif;
  font-size: 10px;
  line-height: 13px;
  padding: 4px 0;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid #524273;
  white-space: normal;
  white-space: initial;
  border-radius: 2px;
}

@media (max-width: 768px) {
  ._1vmmm {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 11px;
  }
}
