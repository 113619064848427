._3L--K {
  max-width: 1440px;
  box-sizing: border-box;
  margin: auto;
  display: flex;
  align-items: center;
  position: relative;
}

._3L--K ._2I8MV {
  margin-left: -60px;

}

._2LJev {
  width: 54%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #F8F8F8;
}

._3L--K .UN4b- {
  display: block;
  margin-left: 86px;
}

.gbpbQ {
  padding: 26px 57px 44px 0;
}

.gbpbQ p {
  max-width: 600px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 158.7%;
  color: #696969;
  margin-bottom: 28px;
}

.gbpbQ h2 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 47px;
  line-height: 55px;
  color: #000000;
  margin: 0 0 12px 0;
}

._3wwN- {
  margin: 40px 0 0 28px;
}

._3wwN- h2 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #ACABAB;
}

._3wwN- p {
  max-width: 400px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

._96G1M {
  display: flex;
  align-items: center;
  margin-left: -46px;
}

._96G1M img {
  margin-right: 16px;
}

@media(min-width: 1024px) {
  ._25tKR img,
  ._3MGC3 {
    display: inline-block;
    vertical-align: top;
  }

  ._3MGC3 .UN4b- {
    display: inline-block;
  }

  ._1XZLr {
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 518px;
    background-size: cover;
    width: 100%;
    padding: 22px 22px 68px 0;
  }

  .iMSlT {
    display: flex;
  }

  ._3MGC3 {
    width: 95.5%;
    padding: 0 78px 28px 0;
    position: relative;
  }

  ._3MGC3:before {
    position: absolute;
    content: '';
    top: 48px;
    left: 12%;
    right: 0;
    bottom: 0;
    background: #F8F8F8;
    z-index: -1;
  }

  ._3MGC3 h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
  }

  ._3MGC3 ._1W10s {
    font-family: "TT Norms", sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 17px;
    color: #696969;
    margin-top: 4px;
  }

  ._3MGC3 p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 141.2%;
    color: #696969;
  }

  ._2PIcV {
    background-color: #ef394f;
    border-radius: 50%;
    width: 119px;
    height: 119px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
  }

  ._2PIcV span {
    font-family: "TT Norms", sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 31px;
    line-height: 37px;
    color: #FFFFFF;
  }

  .vw2VJ {
    display: block;
    margin-right: 20px;
    max-width: 490px;
    margin-left: 16px;
  }

  ._9c3wG {
    margin-left: 66px;
    display: block;
    margin-right: 20px;
    max-width: 490px;
  }

  ._3c6ag {
    margin-top: 56px;
    display: flex;
  }

  ._3c6ag img {
    max-width: 216px;
    max-height: 128px;
  }





  ._1YhK8 {
    display: flex;
    margin-top: 64px;
    margin-bottom: 58px;
    position: relative;
  }

  ._1YhK8 img {
    width: 100%;
  }

  ._1YhK8 ._2WJj6 {
    /*position: absolute;*/
    background: #FFFFFF;
    border: 9px solid #E6E6E6;
    box-sizing: border-box;
    max-width: 424px;
    padding: 32px;
    right: 22px;
    top: 22px;
  }

  ._2WJj6 ._2kHP- h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 31px;
    line-height: 37px;
    color: #EF394F;
    margin: 0;
  }

  ._2WJj6 ._2kHP- p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 148.2%;
    color: #696969;
  }

  ._2WJj6 ._3wQJ4 {
    display: block;
    margin-bottom: 45px;
  }

  ._2WJj6 ._3wQJ4 ._24KtY {
    display: flex;
    margin-bottom: 28px;
    align-items: center;
  }

  ._3wQJ4 ._24KtY img {
    max-width: 26px;
    max-height: 26px;
    margin-right: 18px;
  }

  ._3wQJ4 ._24KtY span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
  }

  ._1E-5w {
    display: flex;
    justify-content: flex-end;
  }


  ._2IGnG {
    background: #FFFFFF;
    box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }

  ._2IGnG img {
    width: 100%;
    display: flex;
  }

  ._2IGnG ._3BU0G {
    margin: 23px 0 42px 42px;
  }

  ._2IGnG ._3BU0G h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
  }

  ._2IGnG ._3BU0G p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
}


@media(max-width: 768px) {

  ._3L--K {
    box-sizing: border-box;
    margin: auto;
    display: block;
  }

  ._1XZLr {
    display: flex;
    position: relative;
    height: 518px;
    background-size: cover;
    width: 100%;
    padding: 50px 42px;
  }

  ._3L--K .UN4b- {
    display: block;
    margin-left: 0;
  }

  ._3L--K ._2I8MV {
    display: none;
  }


  ._2LJev {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #F8F8F8;
  }

  .iMSlT {
    display: block;
  }

  .vw2VJ {
    display: block;
    margin-right: 20px;
    max-width: 490px;
    margin-left: 16px;
  }

  ._9c3wG {
    margin-left: 66px;
    display: block;
    margin-right: 20px;
    max-width: 490px;
  }

  ._3c6ag {
    margin-top: 56px;
  }

  ._3c6ag img {
    max-width: 216px;
    max-height: 128px;
  }





  ._1YhK8 {
    display: flex;
    margin-top: 64px;
    margin-bottom: 58px;
    position: relative;
  }

  ._1YhK8 img {
    width: 100%;
  }

  ._1YhK8 ._2WJj6 {
    background: #FFFFFF;
    border: 9px solid #E6E6E6;
    box-sizing: border-box;
    max-width: 424px;
    padding: 22px 22px 30px 22px;
    right: 22px;
    top: 22px;
  }

  ._2WJj6 ._2kHP- h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 20px;
    color: #EF394F;
    margin: 0;
  }

  ._2WJj6 ._2kHP- p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 148.2%;
    color: #696969;
  }

  ._2WJj6 ._3wQJ4 {
    display: block;
  }

  ._2WJj6 ._3wQJ4 ._24KtY {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
  }

  ._3wQJ4 ._24KtY img {
    max-width: 26px;
    max-height: 26px;
    margin-right: 18px;
  }

  ._3wQJ4 ._24KtY span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #000000;
  }

  ._1E-5w {
    display: flex;
    justify-content: flex-end;
  }


  ._2IGnG {
    background: #FFFFFF;
    box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    margin: 21px;
  }

  ._2IGnG img {
    width: 100%;
    display: flex;
  }

  ._2IGnG ._3BU0G {
    margin: 14px 16px 14px 16px;
  }

  ._2IGnG ._3BU0G h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    color: #000000;
  }

  ._2IGnG ._3BU0G p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
  }

  .gbpbQ {
    padding: 20px 20px 28px 20px;
  }

  .gbpbQ h2 {
    font-size: 19px;
    line-height: 22px;
    margin: 0 0 12px 0;
  }

  .gbpbQ p {
    max-width: 600px;
    font-size: 11px;
    margin-bottom: 28px;
  }

  ._3wwN- {
    margin: 22px 21px;
  }

  ._3wwN- p {
    font-size: 11px;
  }

  ._96G1M {
    display: flex;
    align-items: center;
    margin-left: 0;
  }





}



