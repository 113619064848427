._9PTIt {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}


._9PTIt:last-child {
  margin-bottom: 0;
}

._9PTIt span {
  margin-left: 10px;
}

._3gopA {
  margin: 0;
}
