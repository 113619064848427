._1mbNa {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 24px;
}

._2JPqO {
  position: relative;
  display: flex;
  padding: 0 0 4px;
  margin-left: 24px;
  cursor: pointer;
  transition: opacity .2s ease-in-out;
}

._2JPqO:hover {
  opacity: .7;
}

._2JPqO span {
  position: absolute;
  right: -4px;
  bottom: -6px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #EF394F;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  color: #FFFFFF;
}

._2JPqO img {
  height: 32px;
}
