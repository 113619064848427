@media(min-width: 1024px) {
    .nrF0T {
        max-width: 1321px;
        height: 527px;
        margin: auto;
        padding: 20px 0 0 60px;
        display: flex;
    }

    ._3BSM1 {
        width: 668px;
        height: 507px;
        background: #F4F4F4;
    }

    ._2GgsO {
        display: none;
    }

    ._3BSM1 .dzxoK {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        color: #000000;
        position: relative;
        top: -20px;
        vertical-align: top;
        margin: 0 155px 19px 88px;
    }

    ._3BSM1 .NDAxl {
        margin: 0 155px 51px 88px;
        display: block;
    }
}

@media(max-width: 768px) {
    .nrF0T {
        max-width: 1321px;
        /*padding: 20px 0 0 60px;*/
        display: block;
    }

    ._3BSM1 {
        width: 100%;
        height: 100%;
        background: #FAFAFA;
    }

    .dzxoK {
        display: none;
    }

    ._2GgsO {
        display: flex;
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        color: #000000;
        position: relative;
        vertical-align: top;
        margin: 0 21px 18px 21px;
    }

    ._3BSM1 .NDAxl {
        margin: 0 155px 51px 88px;
        display: block;
    }
}



