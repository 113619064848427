._2DOXc {
  margin: 47px auto 40px;
  max-width: 1440px;
  box-sizing: border-box;
  display: block;
}

._19HZy {
  display: flex;
  justify-content: center;
}



._1h7CS {
  padding: 0 120px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 80px;
  grid-row-gap: 40px;
}

._34yGp {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #F5F5F5;
  border-radius: 3px;
  width: 210px;
  height: 50px;
  padding: 10px 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
  text-decoration: none;
}

@media(max-width: 768px) {
  ._2DOXc {
    margin: 47px auto 40px;
    max-width: 1440px;
    box-sizing: border-box;
    display: block;
  }

  ._19HZy {
    display: flex;
    justify-content: center;
  }



  ._1h7CS {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 15px;
  }

  ._34yGp {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #F5F5F5;
    border-radius: 3px;
    width: 144px;
    padding: 10px 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
    text-decoration: none;
  }

}

