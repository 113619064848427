._2q_kT > button {
  width: 360px;
  max-width: 100%;
}

._3T_0n {
  display: inline-block;
  margin-top: 4px;
}

._3T_0n img {
  width: 38px;
  margin-right: 4px;
}

.Qf6ei {
  display: flex;
  flex-wrap: wrap;
}

.Qf6ei > div {
  display: flex;
}

.Qf6ei > div:nth-child(odd) {
  width: 50%;
  padding-right: 24px;
}

._3mPGP {
  text-decoration: none;
  transition: color .2s ease-in-out;
}

._3mPGP:hover {
  color: #EF394F;
}

._3tAnB {
  display: flex;
}

._1Jli3 {
  margin: 0 36px 0 24px;
}

._247O2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

._247O2 span {
  font-family: serif;
  font-family: initial;
}

._7NJUx {
  display: flex;
}

._22x0f .PwrCB {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #000000;
  margin-left: 5px;
}

@media (min-width: 1024px) {
  ._22x0f ._3Q4Q8 {
    position: relative;
  }

  ._22x0f ._3Q4Q8 ._251V5 {
    right: 0;
    bottom: 15%;
    background: white;
  }

  ._22x0f ._2tpJf {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #000000;
  }

  ._22x0f h1 {
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: flex-end;
    width: 100%;
  }

  ._3mPGP {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
    order: 1;
    margin-bottom: 7px;
  }

  ._22x0f h1 ._2CKU- {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #515151;
    margin: 5px 0 7px 0;
    order: 2;
  }

  ._22x0f h1 .SxLfq {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
    order: 3;
  }

  ._22x0f ._27jSz {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 222%;
    color: #696969;
    margin: 14px 0 12px 0;
  }

  ._22x0f ._251V5, .QBgPX {
    position: absolute;
    bottom: -1em;
    z-index: 100;
    text-align: center;
  }

  ._22x0f .QBgPX {
    display: none;
  }

  ._22x0f ._251V5:target {
    display: none;
  }

  ._22x0f ._251V5:target ~ .QBgPX {
    display: block;
  }

  ._22x0f ._251V5:target ~ ._3A3Qu {
    max-height: 2000px;
  }


  ._22x0f ._3A3Qu {
    position: relative;
    max-height: 58px;
    overflow: hidden;
    transition: max-height 1s ease;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 222%;
    color: #696969;
    margin: 14px 0 12px 0;
  }


  .Qf6ei .HeT0q {
    min-width: 34px;
    height: 34px;
    background: #F2F2F2;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px 33px 0;
  }

  .Qf6ei p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #999;
    margin: 4px 0 16px;
  }

  .Qf6ei ._2CKU- {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    color: #515151;
    margin: 0;
  }


  /*.productOptions .button {*/
  /*  transition: 0.3s;*/
  /*  cursor: pointer;*/
  /*  position: relative;*/
  /*  display: block;*/
  /*  background: #EF394F;*/
  /*  border-radius: 3px;*/
  /*  width: 244px;*/
  /*  height: 48px;*/
  /*  font-family: "Inter", sans-serif;*/
  /*  font-style: normal;*/
  /*  font-weight: 500;*/
  /*  font-size: 14px;*/
  /*  text-align: center;*/
  /*  color: #FFFFFF;*/
  /*  border-width: 0;*/
  /*  box-sizing: border-box;*/
  /*  text-decoration: none;*/
  /*  outline: none;*/
  /*  margin: 0 43px 0 21px;*/
  /*}*/
  /*@keyframes button-anim {*/
  /*  from {*/
  /*    transform: rotate(0);*/
  /*  }*/
  /*  to {*/
  /*    transform: rotate(360deg);*/
  /*  }*/
  /*}*/

  /*.productOptions .button:after {*/
  /*  content: '';*/
  /*  display: block;*/
  /*  width: 1.5em;*/
  /*  height: 1.5em;*/
  /*  position: absolute;*/
  /*  left: 50%;*/
  /*  top: 50%;*/
  /*  margin: -1em;*/
  /*  border: 0.25em solid transparent;*/
  /*  border-right-color: white;*/
  /*  border-radius: 50%;*/
  /*  animation: button-anim 0.7s linear infinite;*/
  /*  opacity: 0;*/
  /*}*/

  /*.productOptions .button.loading {*/
  /*  color: transparent;*/
  /*}*/

  /*.productOptions .button.loading:after {*/
  /*  opacity: 1;*/
  /*}*/

  .RECBW {
    width: 44px;
    height: 44px;
    border-radius: 20px;
    background: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .RECBW img {
    width: 18px;
    height: 18px;
  }


  ._22x0f ._1DjAG:last-child {
    margin-right: 0;
  }


  /*.productOptions .variation img {*/
  /*    margin: 0 6px 0 9px;*/
  /*}*/
  ._1Gyyv {
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    width: 118px;
    height: 47px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  ._1Gyyv div {
    display: flex;
    justify-content: space-between;
    margin: 4px 10px;
  }

  ._1Gyyv input:disabled {
    cursor: not-allowed;
  }

  ._1Gyyv input {
    font-family: "Apercu Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #A9A9A9;
    border: 0;
    background: transparent;
  }

  ._1Gyyv .bxNAL {
    font-family: "Apercu Pro", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin: 0 23px;
    width: 100%;
  }


  ._2q_kT {
    display: flex;
    margin-bottom: 40px;
    margin-top: 40px;
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .Qf6ei > div:nth-child(odd) {
    padding-right: 12px;
  }

  ._3T_0n {
    margin-top: 2px;
  }

  ._3T_0n img {
    width: 26px;
    margin-right: 2px;
  }

  ._22x0f ._3mffx {
    display: flex;
    flex-direction: row-reverse;
    width: -moz-fit-content;
    width: fit-content;
  }

  ._22x0f ._3Q4Q8 {
    display: none;
    order: 3;
  }

  ._22x0f ._2tpJf {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #000000;
  }

  ._22x0f {
    max-width: 500px;

  }
  ._22x0f h1 {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 0;
    order: 2;
    width: 100%;
  }

  ._3mPGP {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
    order: 1;
    margin-bottom: 5px;
    text-decoration: none;
    transition: color .2s ease-in-out;
  }



  ._22x0f h1 ._2CKU- {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #515151;
    margin: 5px 0 7px 0;
    font-size: 13px;
    line-height: 16px;
    order: 2;
  }

  ._22x0f h1 .SxLfq {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 14px;
    color: #696969;
    font-size: 11px;
    order: 3;
  }

  ._22x0f ._3A3Qu {
    position: relative;
    max-height: 58px;
    overflow: hidden;
    transition: max-height 1s ease;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 222%;
    color: #696969;
    margin: 14px 0 12px 0;
  }

  .Qf6ei {
    display: flex;
    word-wrap: break-word;

  }


  .Qf6ei .HeT0q {
    min-width: 34px;
    height: 34px;
    background: #F2F2F2;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px 33px 0;
  }

  ._29Zm0 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #A3A3A3;
    margin-bottom: 44px;
  }

  .Qf6ei p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #A3A3A3;
    max-width: 100px;
    margin-top: 2px
  }

  .Qf6ei ._2CKU- {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: #515151;
    margin: 0;
  }

  /*.productOptions .blockButtons button {*/
  /*  background: #EF394F;*/
  /*  border-radius: 3px;*/
  /*  width: 218px;*/
  /*  font-family: "Inter", sans-serif;*/
  /*  font-style: normal;*/
  /*  font-weight: 500;*/
  /*  font-size: 14px;*/
  /*  text-align: center;*/
  /*  color: #FFFFFF;*/
  /*  border-width: 0;*/
  /*  display: block;*/
  /*  box-sizing: border-box;*/
  /*  text-decoration: none;*/
  /*  cursor: pointer;*/
  /*  outline: none;*/
  /*  margin: 0 0 0 21px;*/
  /*}*/

  .RECBW {
    display: none;
  }

  .RECBW img {
    display: none;
  }


  ._22x0f ._1DjAG:last-child {
    margin-right: 0;
  }


  /*.productOptions .variation img {*/
  /*    margin: 0 6px 0 9px;*/
  /*}*/
  ._1Gyyv {
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    width: 86px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  ._1Gyyv div {
    display: flex;
    justify-content: space-between;
    margin: 4px 10px;
  }

  ._1Gyyv input:disabled {
    cursor: not-allowed;
  }

  ._1Gyyv input {
    font-family: "Apercu Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #A9A9A9;
    border: 0;
    background: transparent;
  }

  ._1Gyyv .bxNAL {
    font-family: "Apercu Pro", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin: 0 23px;
    width: 100%;
  }

  ._3e_lK {
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    width: 117px;
    height: 47px;
    display: flex;
    align-items: center;
    margin-right: 27px;
    cursor: pointer;
    position: relative;
  }

  ._2q_kT {
    margin: 12px 0 36px;
  }

  ._22x0f ._13TNv {
    display: flex;
    flex-direction: column;
    padding: 0 21px;
  }

  ._2iYtL {
    padding: 0 21px;
  }

  ._1Jli3 {
    margin: 0;
  }

  ._3tAnB {
    flex-direction: row-reverse;
  }

  ._3tAnB .PwrCB {
    margin-top: 20px;
  }
}

/* GWP Block */
@keyframes _372LN {
  0% {
    outline: 0px solid transparent;
 }
  50% {
    outline: 10px solid transparent;
 }
  100% {
    outline: 0px solid #EF394F;
 }
}

.ZPIK- {
  display: flex;
  padding: 12px 20px;
  margin-bottom: 24px;
  color: #696969 !important;
  border-radius: 4px;
  border: 1px solid #CCC;
  flex-wrap: nowrap;
  align-items: center;
  flex: 0;
  position: relative;
}

.ZPIK-::before {
  position: absolute;
  content: '';
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  background: #EF394F;
  border-radius: 50%;
  animation: _372LN 1.5s ease-out infinite;
}

.ZPIK- img {
  width: 50px;
  height: 70px;
  margin-right: 18px;
  border-radius: 2px;
}

._9FgZu {
  display: block;
}

._2dVGS {
  font-weight: bold;
  margin-bottom: 0;
  font-size: .9em;
  margin-top: 0;
  color: #000;
}

._3z7FH {
  color: #696969;
  font-size: .85em;
  margin: .5em 0 0;
}