.HwS0V {
    width: 100%;
}

@media(min-width: 1024px) {
    ._3gwll img,
    ._29vo- {
        display: inline-block;
        vertical-align: top;
    }

    ._29vo- ._2NG0Y {
        display: inline-block;
    }

    .foViW {
        display: flex;
        justify-content: flex-end;
    }

    ._29vo- {
        width: 95.5%;
        padding: 0 78px 28px 0;
        position: relative;
    }

    ._29vo-:before {
        position: absolute;
        content: '';
        top: 48px;
        left: 12%;
        right: 0;
        bottom: 0;
        background: #F8F8F8;
        z-index: -1;
    }

    ._29vo- h2 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #000000;
    }

    ._29vo- ._2Tz4K {
        font-family: "TT Norms", sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 17px;
        color: #696969;
        margin-top: 4px;
    }

    ._29vo- p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 141.2%;
        color: #696969;
    }

    ._1M0A1 {
        background-color: #ef394f;
        border-radius: 50%;
        width: 119px;
        height: 119px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #FFFFFF;
    }

    ._1M0A1 span {
        font-family: "TT Norms", sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 31px;
        line-height: 37px;
        color: #FFFFFF;
    }

    ._2dCxF {
        display: block;
        margin-right: 20px;
        max-width: 490px;
        margin-left: 16px;
    }

    ._3hgHP {
        margin-left: 66px;
        display: block;
        margin-right: 20px;
        max-width: 490px;
    }

    ._3a_y9 {
        margin-top: 56px;
        display: flex;
    }

    ._3a_y9 img {
        max-width: 216px;
        max-height: 128px;
    }





    ._2djRU {
        display: flex;
        margin-top: 64px;
        margin-bottom: 58px;
        position: relative;
    }

    ._2djRU img {
        width: 100%;
    }

    ._2djRU ._2B5r6 {
        position: absolute;
        background: #FFFFFF;
        border: 9px solid #E6E6E6;
        box-sizing: border-box;
        max-width: 424px;
        padding: 32px;
        right: 22px;
        top: 22px;
    }

    ._2B5r6 ._2PMhB h2 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 37px;
        color: #EF394F;
        margin: 0;
    }

    ._2B5r6 ._2PMhB p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 148.2%;
        color: #696969;
    }

    ._2B5r6 .dOnWp {
        display: block;
        margin-bottom: 45px;
    }

    ._2B5r6 .dOnWp ._1yHTg {
        display: flex;
        margin-bottom: 28px;
        align-items: center;
    }

    .dOnWp ._1yHTg img {
        max-width: 26px;
        max-height: 26px;
        margin-right: 18px;
    }

    .dOnWp ._1yHTg span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #000000;
    }

    ._3osRX {
        display: flex;
        justify-content: flex-end;
    }


    ._2G9ak {
        background: #FFFFFF;
        box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        position: relative;
        overflow: hidden;
    }

    ._2G9ak img {
        width: 100%;
        display: flex;
    }

    ._2G9ak ._1zK7w {
        margin: 23px 0 42px 42px;
    }

    ._2G9ak ._1zK7w h2 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #000000;
    }

    ._2G9ak ._1zK7w p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }
}


@media(max-width: 768px) {
    ._3gwll img,
    ._29vo- {
        display: block;
        vertical-align: top;
    }

    ._29vo- ._2NG0Y {
        display: block;
    }

    .foViW {
        display: block;
    }

    ._29vo- {

    }

    ._29vo- h2 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #000000;
    }

    ._29vo- ._2Tz4K {
        font-family: "TT Norms", sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 17px;
        color: #696969;
        margin-top: 4px;
    }

    ._29vo- p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 141.2%;
        color: #696969;
    }

    ._1M0A1 {
        background-color: #ef394f;
        border-radius: 50%;
        width: 119px;
        height: 119px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #FFFFFF;
    }

    ._1M0A1 span {
        font-family: "TT Norms", sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 31px;
        line-height: 37px;
        color: #FFFFFF;
    }

    ._2dCxF {
        display: block;
        margin-right: 20px;
        max-width: 490px;
        margin-left: 16px;
    }

    ._3hgHP {
        margin-left: 66px;
        display: block;
        margin-right: 20px;
        max-width: 490px;
    }

    ._3a_y9 {
        margin-top: 56px;
    }

    ._3a_y9 img {
        max-width: 216px;
        max-height: 128px;
    }





    ._2djRU {
        display: flex;
        margin-top: 64px;
        margin-bottom: 58px;
        position: relative;
    }

    ._2djRU img {
        width: 100%;
    }

    ._2djRU ._2B5r6 {
        position: absolute;
        background: #FFFFFF;
        border: 9px solid #E6E6E6;
        box-sizing: border-box;
        max-width: 424px;
        padding: 32px;
        right: 22px;
        top: 22px;
    }

    ._2B5r6 ._2PMhB h2 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 37px;
        color: #EF394F;
        margin: 0;
    }

    ._2B5r6 ._2PMhB p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 148.2%;
        color: #696969;
    }

    ._2B5r6 .dOnWp {
        display: block;
        margin-bottom: 45px;
    }

    ._2B5r6 .dOnWp ._1yHTg {
        display: flex;
        margin-bottom: 28px;
        align-items: center;
    }

    .dOnWp ._1yHTg img {
        max-width: 26px;
        max-height: 26px;
        margin-right: 18px;
    }

    .dOnWp ._1yHTg span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #000000;
    }

    ._3osRX {
        display: flex;
        justify-content: flex-end;
    }


    ._2G9ak {
        background: #FFFFFF;
        box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        position: relative;
        overflow: hidden;
    }

    ._2G9ak img {
        width: 100%;
        display: flex;
    }

    ._2G9ak ._1zK7w {
        margin: 23px 0 42px 42px;
    }

    ._2G9ak ._1zK7w h2 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #000000;
    }

    ._2G9ak ._1zK7w p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }
}



