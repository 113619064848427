.qW2Lk {
  width: 100%;
  margin-bottom: 50px;
}

._1p8eD {
  display: flex;
  justify-content: flex-end;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
  text-decoration: none;
}

._1E_nG {
  display: block;
}

._3cCbm {
  display: flex;
  justify-content: flex-end;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
  text-decoration: none;
}

._3cCbm:hover {
  color: #EF394F;
}

._33-Y7 {
  display: flex;
  align-items: center;
}

._33-Y7 img {
  width: 56px;
}

._33-Y7 h2 {
  margin: 0 0 30px 33px;
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
}

._1meAP {
  margin-left: 90px;
}

._1meAP.active {
  background: #FAFAFA;
}

.DsKAj {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 10px 10px;
  cursor: pointer;
  border-top: 1px solid #E8E8E8;
}

.DsKAj img {
  transform: rotate(-90deg);
  margin-right: 14px;
  transition: all .3s ease-in-out;

}

.DsKAj.active img {
  transform: rotate(90deg);

}

._1meAP .DsKAj div {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.DsKAj.active div {
  font-weight: 600;
  font-size: 15px;
}

._54ywV {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 222%;
  color: #696969;
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 0.5s linear;
  padding: 0px 10px 10px 10px;

}

._54ywV.active {
  display: block;
  visibility: visible;
  opacity: 1;
}

._2Ux2g {
  margin-top: 70px;
}

@media(max-width: 768px) {
  .qW2Lk {
    margin-top: 15px;
  }

  ._1meAP {
    margin-left: 0;
  }

  ._54ywV {
    padding: 0px 21px 10px 21px;
  }

  .DsKAj {
    padding: 16px 21px 10px 21px;
  }

  ._3cCbm {
    padding-right: 21px;
  }

  ._33-Y7 {
    padding: 16px 21px 10px 21px;
  }

  ._33-Y7 h2 {
    margin-left: 20px;
    margin-bottom: 0;
    font-size: 19px;
    line-height: 22px;
  }
  ._2Ux2g {
    margin-top: 45px;
  }

}
