._3BrrA > div {
    flex: auto;
}

._1NFLS {
    display: flex;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    justify-content: space-between;
}

._2a3g- {
    width: 459px;
    height: 104px;
    margin-left: 50px;
    margin-top: 3vw;
}

._2a3g-._1W5ZI {
    margin-top: 14px;
    margin-left: 0;
}

._2a3g-._1W5ZI span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-top: 14px;
}

._2a3g- ._2yyzG {
    color: #FFFFFF;
}

._3zRbl {
  overflow: hidden;
  width: 100%;
  height: 18.0555554vw;
  position: relative;
}

._1u-18 {
  display: flex;
}

._1u-18 .react-multi-carousel-list {
  display: flex;
  overflow: hidden;
  position: relative;
  height: 100%;
}

._1u-18 .react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition
}

._1u-18 .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer
}

._1u-18 .react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.8)
}

._1u-18 .react-multiple-carousel__arrow::before {
  font-size: 20px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative
}

._1u-18 .react-multiple-carousel__arrow--left {
  left: calc(4% + 1px)
}

._1u-18 .react-multiple-carousel__arrow--left::before {
  content: "\E824"
}

._1u-18 .react-multiple-carousel__arrow--right {
  right: calc(4% + 1px)
}

._1u-18 .react-multiple-carousel__arrow--right::before {
  content: "\E825"
}

._1u-18 .react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center
}

._1u-18 .react-multi-carousel-dot button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background .5s;
  border-width: 1px;
  border-style: solid;
  border-color: grey;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  background: transparent;
  cursor: pointer
}

._1u-18 .react-multi-carousel-dot button:hover:active {
  background: #080808
}

._1u-18 .react-multi-carousel-dot--active button {
  background: #C1C1C1
}

._1u-18 .react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden
}

@media(max-width: 768px) {
  ._1u-18 .react-multi-carousel-item {
    height: 100%;
  }
  ._1u-18 .react-multi-carousel-list {
    height: 100%;
  }
  ._1u-18 .react-multi-carousel-track {
    height: 100%;
  }
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
  ._1u-18 .react-multi-carousel-item {
    flex-shrink: 0 !important
  }

  ._1u-18 .react-multi-carousel-track {
    overflow: visible !important
  }
}

._2dyqm img {
  position: absolute;
  width: 53px;
  height: 53px;
  margin-top: -35px;
  z-index: 10;
  transition: visibility 1s 0s, opacity .2s ease-in-out;
  opacity: 0.5;
  cursor: pointer;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  right: 50px;
  border-radius: 50%;
}

._2dyqm img:hover {
  opacity: 1;
}

._2dyqm._15LSx img {
  right: 0;
  left: 50px;
  transform: rotate(-180deg);
}

._2pxke {
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  width: 1200px;
}


