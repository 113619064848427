._18V1J {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-column-gap: 24px;
  -moz-column-gap: 24px;
       column-gap: 24px;
  grid-row-gap: 15px;
  row-gap: 15px;
}

@media (max-width: 768px) {
  ._18V1J {
    grid-template-columns: 1fr;
  }

}
