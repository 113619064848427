._2tqlg {
  margin: 0 auto 40px;
  box-sizing: border-box;
  display: block;
}

.cE2ZE {
  display: flex;
}

._2Bu-m {
  position: relative;
  display: flex;
  width: 100%;
}

._2Bu-m img {
  position: relative;
  display: flex;
  width: 100%;
}


.TVqXJ {
  display: block;
  margin-right: 170px;
}

.TVqXJ h2 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 37px;
  color: #FFFFFF;
  margin-top: 0;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.45);
}

.xFcVg {
  background: #FAFAFA;
  width: 100%;
}

._1Y14X {
  flex-direction: column;
  display: flex;
  padding: 58px 0 52px 45px;
}

._1Y14X a {
  max-width: 405px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
  text-decoration: none;
  margin-bottom: 16px;
}

._1Y14X a:hover {
  color: #EF394F;
}

._1Y14X h2 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 20px;
}

.hMTMB {
  display: grid;
  grid-row-gap: 26px;
  grid-column-gap: 40px;
  grid-template-columns: repeat(4, 165px);
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  margin: 60px auto 70px auto;
}

.zdOoN {
  background: #FAFAFA;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1200px;
  height: 282px;
  margin: auto;
}

._2IyUv {
  display: flex;
  flex-direction: column;
  align-items: center;

}

._2IyUv h2 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

._2IyUv span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
}

._1XhIF {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

@media(max-width: 768px) {
  .cE2ZE {
    display: block;
  }
  ._1Y14X {
    flex-direction: column;
    display: flex;
    padding: 21px 46px 30px 20px;
  }

  .TVqXJ {
    margin-right: 0px;
    padding: 48px 21px 33px;
    width: 100%;
  }

  .TVqXJ h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    color: #FFFFFF;
    text-align: center;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .hMTMB {
    display: grid;
    grid-row-gap: 11px;
    grid-column-gap: 11px;
    grid-template-columns: repeat(3, 99px);
    align-items: center;
    justify-content: center;
    margin: 60px auto 70px auto;
  }

  .zdOoN {
    background: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 282px;
  }
  ._2IyUv h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align: center;
  }
}


