._2aWMZ {
  display: flex;
  width: 100%;
  padding-right: 120px;
  margin-top: 80px;
}

._2kdki {
  background: #F4F4F4;
  position: relative;
  padding: 38px 50px 48px 50px;
  width: 100%;
}

._2kdki ._3pX8i {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 37px;
  color: #000000;
  position: absolute;
  top: -4%;
}

._2kdki .CmaUp {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 18px;
}

._2efkH {
  display: flex;
  width: 100%;
  margin-bottom: 26px;
}

._2efkH ._2cusR {
  display: flex;
  width: 45px;
  align-items: center;
  margin-right: 25px;
}

._2efkH div {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 222%;
  color: #000000;
}

._2efkH ._2U5ZU {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  -moz-column-gap: 10px;
       column-gap: 10px;
}

._2efkH ._1Kht_ {
  margin-left: 105px;
}

._1Kht_ span {
  color: #22BC7B;
}

@media (max-width: 768px) {
  ._2aWMZ {
    display: block;
    width: 100%;
    padding-right: 0;
  }

  ._2kdki {
    position: relative;
    padding: 20px 21px 48px 21px;
    width: 100%;
  }

  ._2kdki ._3pX8i {
    font-size: 26px;
    position: relative;
  }

  ._2efkH ._1Kht_ {
    margin-left: 0;
  }


  ._2efkH:first-of-type {
    display: block;
  }

  ._2aWMZ .mLwFn  {
    width: 100%;
  }

  ._2efkH ._2cusR {
    justify-content: center;
  }
}
