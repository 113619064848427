
@media(min-width: 1024px) {
  ._3BJWx {
    height: 100%;
    display: flex;
    justify-content: center;
  }
  ._30NPw {
    background-color: #FAFAFA;
    position: relative;
  }

  ._30NPw .HwS8Z {
    max-width: 1440px;
    margin: auto;
    padding-left: 45px;
    position: relative;
  }

  .HwS8Z .KPr9s {
    display: flex;
  }

  ._2PJOZ {
    display: list-item;
    list-style: none;
  }

  .HwS8Z ._21KmH {
    padding: 16px 0 16px 75px;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    width: 465px;
    margin-right: 114px;
  }



  .dtiOT {
    position: relative;
  }
  .dtiOT ._1PgO8 {
    right: 0;
  }



  ._1PzPx:hover {
    background: #F3F3F3;
  }


  ._1PgO8 {
    position: absolute;
    top: 120%;
    right: 0;
    z-index: 998;
    background: #FAFAFA;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    width: 313px;
    max-height: 216px;
    overflow: auto;
    padding: 15px 9px 15px 15px;
  }

  ._1PgO8._3Ac65 {
    width: 378px;
    height: 138px;
  }

  ._1PgO8._3IzWk {
    width: 382px;
    height: 312px;
  }


  ._1PgO8._3Ac65 ._14qZP {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }

  ._1PgO8._14qZP {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }


  ._2XEat {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 4px;
    /*width: 338px;*/
    height: 104px;
    margin: 2px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ._1xmDO .NkQiM {
    width: 20px;
    height: 20px;
    border-radius: 26px;
    overflow: hidden;
    top: 50%;
    background: #696969;
    margin-left: 20px;

  }

  ._1xmDO.y1xag {
    position: absolute;
    right: 35px;
    top: 23%;
  }

  ._1xmDO._34Idp {
    position: absolute;
    left: 35px;
    top: 23%;
  }

  ._1xmDO div {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 3px;
    width: 65px;
    height: 27px;
    top: 30%;
  }

  ._1xmDO span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #696969;
    margin: 6px 8px;
  }

  .pPaBw {
    padding-top: 18px;
    margin-right: 17px;
  }

  .pPaBw span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #696969;
    cursor: pointer;
  }

  .ygFrf {
    background: #FFFFFF;
    border-radius: 3px;
    margin-bottom: 6px;
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-height: 42px;
  }

  .ygFrf:hover span {
    color: #EF394F;

  }

  ._1PzPx {
    height: 100%;
    background: #FAFAFA;
    cursor: pointer;
    white-space: nowrap;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  ._1PzPx._14qZP {
    background: #F3F3F3;
  }

  ._1PzPx._14qZP span {
    text-shadow: 0 0 0.01px #696969;
  }

  ._1PzPx:hover {
    background: #F3F3F3;
  }
  ._1PzPx span::before {
    display: block;
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  ._1PzPx span {
    display: inline-block;
    margin-right: 10px;
    margin-left: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
    font-weight: normal;
  }

  ._1PzPx img {
    margin-right: 20px;
    opacity: 0.5;
    transform: rotate(-180deg);
  }

  ._1PzPx._14qZP img {
    transform: rotate(0);
    opacity: 1;
  }

  .ygFrf span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
    margin-left: 27px;
  }

  .ygFrf._14qZP span {
    font-weight: 600;
    color: #000000;
  }



  ._2fl6v {
    display: flex;
    position: absolute;
    right: 0;
    top: 14%;
    margin-right: 20px;
  }

  ._1FL3X {
    display: block;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-left: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    outline: none;
  }

}


@media(max-width: 1023px) {
  ._30NPw {
    background-color: #FAFAFA;
    position: relative;
  }

  ._30NPw .HwS8Z {
    max-width: 1440px;
    margin: auto;
    padding-left: 45px;
    position: relative;
  }

  .HwS8Z .KPr9s {
    display: flex;
  }

  ._2PJOZ {
    display: list-item;
    list-style: none;
  }

  .HwS8Z ._21KmH {
    padding: 16px 0 16px 75px;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    width: 465px;
    margin-right: 114px;
  }


  .dtiOT {
    position: relative;
  }
  ._1PgO8 {
    /*position: absolute;*/
    display: block;
    position: relative;
    /*opacity: 0;*/
    /*visibility: hidden;*/
    /*z-index: 998;*/
    /*background: #FAFAFA;*/
    /*box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);*/
    /*border-radius: 3px;*/
    /*width: 313px;*/
    padding-bottom: 15px;
    /*padding-top: 15px;*/
    /*left: -50%;*/
  }

  ._1PgO8._2-QkL {
    max-height: 216px;
    overflow: auto;
  }



  ._1PgO8._3Ac65 {
    width: 378px;
    height: 138px;
  }

  ._1PgO8._3IzWk {
    width: 382px;
    height: 312px;
  }


  ._1PgO8._3Ac65 ._14qZP {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }

  ._1PgO8._14qZP {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }


  ._2XEat {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 4px;
    /*width: 338px;*/
    height: 104px;
    margin: 2px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ._1xmDO .NkQiM {
    width: 20px;
    height: 20px;
    border-radius: 26px;
    overflow: hidden;
    top: 50%;
    background: #696969;
    margin-left: 20px;

  }

  ._1xmDO.y1xag {
    position: absolute;
    right: 35px;
    top: 23%;
  }

  ._1xmDO._34Idp {
    position: absolute;
    left: 35px;
    top: 23%;
  }

  ._1xmDO div {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 3px;
    width: 65px;
    height: 27px;
    top: 30%;
  }

  ._1xmDO span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #696969;
    margin: 6px 8px;
  }

  .pPaBw {
    padding-top: 18px;
    margin-right: 17px;
  }

  .pPaBw span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #696969;
    cursor: pointer;
  }

  .ygFrf {
    background: #FFFFFF;
    border-radius: 3px;
    margin: 0 9px 6px 5px;
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-height: 42px;
  }

  .ygFrf div {
    margin-right: 10px;
  }


  .ygFrf span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  .ygFrf._14qZP span {
    font-weight: 600;
    color: #000000;
  }

  ._1PzPx img {
    opacity: 0.5;
    transform: rotate(-180deg);
  }

  ._1PzPx._14qZP img {
    transform: rotate(0);
    opacity: 1;
  }

  .f5exY {
    margin-bottom: 40px;
  }
  ._2fl6v {
    display: none;
    position: absolute;
    right: 0;
    top: 28px;
    margin-right: 10px;
  }

  ._1FL3X {
    position: fixed;
    display: block;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 330px;
    max-width: 100%;
    height: 44px;
    padding-left: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    outline: none;
  }
  ._1PzPx._14qZP span {
    color: #000000;
  }

}

