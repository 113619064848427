

._111E4 progress {
  width: 225px;
  height: 8px;
  margin-right: 10px;
}

._111E4 progress {
  -webkit-appearance: none;
}

._111E4 progress::-webkit-progress-value {
  background-color:#EF394F;
}

._111E4 progress::-webkit-progress-bar {
  background-color: #E9E9E9;
}

._111E4 ._3pV0R {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #B0B0B0;
}

._3fFop {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

._3fFop ._1d93g {
  font-family: "TT Norms", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  color: #000000;
  margin-right: 8px;
}

._3fFop span {
  font-family: serif;
  font-family: initial;
  margin-right: 3px;
}


@media(min-width: 1024px) {
  ._5W3Z6 {
    display: flex;
  }

  .lYN0Z {
    display: block;
  }

  .lYN0Z ._111E4 {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
  }

  .lYN0Z img {
    margin: 0 7px 0 6px;
  }

  .lYN0Z ._111E4 .KUoOR {
    width: 225px;
    height: 8px;
    background: #E9E9E9;
    margin: 0;
  }
  .lYN0Z ._3vG-X {
    font-family: 'Apercu Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }


  ._2MarR {
    display: block;
    margin-left: 370px;
    width: 100%;
    height: 264px;
    overflow: hidden;
  }

  ._2jzOM {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  ._2jzOM div {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }



}

@media(max-width: 768px) {

  .lYN0Z ._111E4 {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
  }

  ._111E4 img {
    margin: 0 7px 0 6px;
  }


}



