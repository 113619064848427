._3_kH1 > ._236bZ:nth-of-type(5)::before {
  background-color: #00ac5d;
}

._3_kH1 > ._236bZ:nth-of-type(5) a {
  color: #00ac5d;
}

@media (min-width: 1024px) {
  ._3_kH1 {
    /*padding: 0 50px 0 0;*/
    margin: 0;
    list-style: none;
    width: 214px;
  }

  ._236bZ {
    position: relative;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
  }

  ._236bZ:not(:last-child) {
    margin-bottom: 20px;
  }

  ._236bZ:before {
    content: '';
    opacity: 0;
    width: 5px;
    height: 5px;
    position: absolute;
    left: -15px;
    border-radius: 50%;
    background-color: #EF394F;
    transition: all .1s ease-in-out;
    top: 50%;
    margin-top: -2.5px;
  }

  ._236bZ a {
    color: black;
    text-decoration: none;
    text-align: left;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    white-space: nowrap;
  }

  ._236bZ.active a {
    color: #EF394F;
    font-weight: bold;
  }


  /* Selected */
  /*.item:global(.active) a {*/
  /*    color: #EF394F;*/
  /*}*/
  ._236bZ.active:before {
    opacity: 1;
  }
}

@media (max-width: 1023px) {
  ._3_kH1 {
    /*padding: 0 50px 0 0;*/
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
    -moz-column-gap: 12px;
         column-gap: 12px;
    grid-row-gap: 12px;
    row-gap: 12px;
    flex-wrap: wrap;
  }

  ._236bZ {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 143px;
    height: 42px;
    background: #F7F7F7;
    border-radius: 3px;
  }

  ._236bZ a {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    white-space: nowrap;
    color: #000;
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    font-family: TT Norms, sans-serif;
  }

}
