._2yThj {
  padding: 0 21px;
}

@media(min-width: 1024px) {
  ._2yThj {
    padding: 50px 205px;
    width: 1440px;
    margin: auto;
  }
}
