._3LmtQ {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

._3LmtQ:last-child {
  margin-bottom: 0;
}

/* Subtotal row */
._3LmtQ:first-child span:last-child {
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  color: #979797;
}

@media (min-width: 1024px) {
  ._2rnsb {
    display: flex;
    flex-direction: column;
    box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
    height: 100vh;
    position: fixed;
    width: 475px;
  }

  .WjQc8 {
    background: linear-gradient(#FFFFFF 33%, rgba(211, 61, 186, 0)),
    linear-gradient(rgba(211, 61, 186, 0), #FFFFFF 66%) 0 100%,
    radial-gradient(farthest-side at 50%, at 0, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50%, at 100%, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)) 0 100%;
    background: linear-gradient(#FFFFFF 33%, rgba(211, 61, 186, 0)),
    linear-gradient(rgba(211, 61, 186, 0), #FFFFFF 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size: 100% 45px, 100% 45px, 100% 15px, 100% 15px;
    padding: 0;
    margin: 0 0 30px 0;
    list-style: none;
    overflow-x: auto;
    flex: 1;
  }

  .WjQc8::-webkit-scrollbar {
    /*display: none;*/
  }

  ._1zskg {
    display: flex;
    justify-content: flex-end;
  }

  ._1zskg span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #696969;
  }

  ._1zskg span:last-child {
    color: #979797;
  }

  ._3pmM0 {
    width: 100%;
    height: 1px;
    margin: 25px 0;
    background-color: #E9E9E9;
  }

  ._3ldXZ {
    display: block;
    margin-bottom: 100px;
  }

  ._3ldXZ span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #A6A6A6;
  }

  .u-0HR {
    display: flex;
    justify-content: space-between;
  }

  ._2p0Bh {
    height: 48px;
    width: 276px;
    padding: 15px 0 16px 19px;
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #A9A9A9;
    outline: none;
    margin-right: 15px
  }

  ._2sxwr {
    width: 144px;
    background: #F8F8F8;
    border-radius: 3px;
    border: 0;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #A6A6A6;
    cursor: pointer;
  }

  ._2Hy4J {
    margin-top: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #EF394F;
  }

  ._2sxwr:hover {
    font-weight: bold;
  }

  ._3LmtQ span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #A6A6A6;
  }

  ._3TK9c {
    position: relative;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #A6A6A6;
  }

  ._3TK9c div:last-child {
    position: absolute;
    right: 0;
    top: 10%;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    text-align: right;
    color: #000000;
  }

  ._3TK9c ._3q3rp {
    font-family: "Apercu Pro", sans-serif;
    font-size: 13px;
    color: #696969;
  }
}

@media (max-width: 1023px) {
  ._2rnsb {
    display: block;
    box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
  }

  .WjQc8 {
    background: linear-gradient(#FFFFFF 33%, rgba(211, 61, 186, 0)),
    linear-gradient(rgba(211, 61, 186, 0), #FFFFFF 66%) 0 100%,
    radial-gradient(farthest-side at 50%, at 0, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50%, at 100%, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)) 0 100%;
    background: linear-gradient(#FFFFFF 33%, rgba(211, 61, 186, 0)),
    linear-gradient(rgba(211, 61, 186, 0), #FFFFFF 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size: 100% 45px, 100% 45px, 100% 15px, 100% 15px;
    padding: 0;
    margin: 0 0 30px 0;
    list-style: none;
    overflow-x: auto;
    max-height: 244px;
    display: none;
  }

  .WjQc8::-webkit-scrollbar {
    /*display: none;*/
  }

  ._1zskg {
    display: none;
    justify-content: flex-end;
  }

  ._1zskg span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #696969;
  }

  ._1zskg span:last-child {
    color: #979797;
  }

  ._3pmM0 {
    width: 100%;
    height: 1px;
    margin: 25px 0;
    background-color: #E9E9E9;
  }


  ._3ldXZ {
    margin-left: 10px;
    display: none;
    margin-bottom: 100px;
  }

  ._3ldXZ span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #A6A6A6;
  }

  .u-0HR {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
  }

  ._2p0Bh {
    height: 48px;
    width: 276px;
    padding: 15px 0 16px 19px;
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #A9A9A9;
    outline: none;
  }


  ._2sxwr {
    width: 144px;
    background: #F8F8F8;
    border-radius: 3px;
    border: 0;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #A6A6A6;
    cursor: pointer;
  }


  ._2Erzv {
    margin: 0 24px
  }

  ._3LmtQ span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #A6A6A6;
  }

  ._3TK9c {
    position: relative;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #A6A6A6;
    margin: 0 24px;
  }

  ._3TK9c div:last-child {
    position: absolute;
    right: 0;
    top: 10%;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    text-align: right;
    color: #000000;
  }
}
