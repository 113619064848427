._FyeM {
  display: none;
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #000;
  height: 480px;
  max-width: 800px;
}

._FyeM.visible {
  display: flex;
  align-items: center;
}

._3FFwB {
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  margin-bottom: 24px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  white-space: nowrap;
}

._1AyfC {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.wBiTs {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
  background: #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
}

.wBiTs:before,
.wBiTs:after {
  position: absolute;
  left: 14px;
  top: 5px;
  content: " ";
  height: 20px;
  width: 1px;
  background-color: #000;
  opacity: 0.7;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
}

.wBiTs:before {
  transform: rotate(45deg);
}
.wBiTs:after {
  transform: rotate(-45deg);
}

.wBiTs:hover::before,
.wBiTs:hover::after {
  opacity: 1;
}

._5HZdp {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  display: none;
  opacity: 0.5;
  background-color: #000;
}

._3mG3q {
  text-align: center;
  padding: 24px;
  width: 360px;
}

._5HZdp.visible {
  display: block;
}

._3fMum {
  display: none;
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  ._3FFwB,
  ._1AyfC,
  ._3mG3q > p {
    display: none;
  }

  ._3mG3q {
    position: absolute;
    bottom: 6px;
    min-width: 100%;
    padding: 0;
  }

  .wBiTs {
    top: -8px;
    right: -8px;
  }

  ._FyeM {
    height: auto;
    width: 90%;
  }

  ._3fMum {
    display: block;
  }

  ._1AyfC {
    margin: 12px 24px;
  }

  ._3mG3q > a {
    margin-bottom: 32px;
  }
}
