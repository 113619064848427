.VMVgr progress {
  width: 225px;
  height: 8px;
  margin-right: 10px;
}

.VMVgr progress {
  -webkit-appearance: none;
}

.VMVgr progress::-webkit-progress-value {
  background-color:#EF394F;
}

.VMVgr progress::-webkit-progress-bar {
  background-color: #E9E9E9;
}

.VMVgr ._2BYMY {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #B0B0B0;
}

.mP33E {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.mP33E ._2_HPd {
  font-family: "TT Norms", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  color: #000000;
  margin-right: 8px;
}

._1JH5W ._1W2Qr {
  display: flex;
  justify-content: flex-end;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
}

._1JH5W p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #696969;
}

._23cec span {
  margin-right: 3px;
}

._23cec h2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

@media(min-width: 1024px) {

  ._2Zqtt {
    display: block;
    width: 488px;
    max-height: 264px;
    overflow: auto;
  }

  ._23cec {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  ._2Zqtt ._1cZvf {
    width: 100%;
    border: 1px solid #CCCCCC;
    margin: 0 0 12px 0;
  }
}

@media(max-width: 768px) {

  ._2Zqtt {
    display: block;
    width: 100%;
    /*max-height: 264px;*/
    /*overflow: auto;*/
    margin-top: 32px;
  }

  [dir="ltr"] ._2Zqtt ._1W2Qr {
    text-align: right;
  }

  [dir="rtl"] ._2Zqtt ._1W2Qr {
    text-align: left;
  }
  ._2Zqtt ._1cZvf {
    width: 100%;
    border: 1px solid #CCCCCC;
    margin: 0 0 5px 0;
  }
}



