._1a-mG {
  margin: 48px 0 0;
  font-family: "Inter", sans-serif;
}

._1a-mG dl {
  -moz-columns: 2;
       columns: 2;
  -moz-column-fill: balance;
       column-fill: balance;
  grid-column-gap: 10%;
  -moz-column-gap: 10%;
       column-gap: 10%;
  word-break: break-word;
}

._1a-mG dl > div {
  -moz-column-break-inside: avoid;
       break-inside: avoid-column;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  margin: 0 0 12px;
}

._1a-mG dt {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  color: #696969;
}

._1a-mG dd {
  margin: 0;
  font-size: 14px;
  color: #696969;
}
