._2LSkm {
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 54px;
}

.wB8Xq span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #696969;
  cursor: pointer;
  white-space: nowrap;
}

._1rBr3 {
  font-family: 'TT Norms', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  text-decoration: none;
  outline: none;
  color: #000000;
  white-space: nowrap;
}

._2xio1 {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  text-decoration: none;
  outline: none;
  color: #000000;
  white-space: nowrap;
}

._2xio1 ul {
  margin: 0;
}

._2xio1 ul li {
  display: inline-block;
  margin-right: 6px;
}

._2xio1 ul li > span {
  margin-left: 6px;
}

._2xio1 ul li:last-child {
  margin-right: 0;
}

._2xio1 a {
  text-decoration: none;
  outline: none;
  color: #000000;
}

._2xio1 a:hover,
._2xio1 li._2WEd0 {
  color: #EF394F;
}

._3trlb {
  display: none;
}

@media (min-width: 1024px) {
  ._3T0og {
    background-color: #FAFAFA;
  }

  ._24ZoS {
    display: none;
  }

  ._24n9F {
    position: relative;
  }

  ._24n9F ._3Ia4A {
    max-width: 1440px;
    margin: auto;
    padding-left: 45px;
    position: relative;
  }

  ._3Ia4A ._3fWGF {
    display: flex;
    align-items: baseline;
  }

  ._27MMx {
    display: flex;
  }

  ._3Ia4A ._1QBzd:last-child {
    padding: 16px 0 16px 5px;
    margin-right: 114px;
    width: 400px;
  }

  ._1fvTC {
    display: flex;
    align-items: center;
  }

  ._1fvTC ._2LI7T {
    height: 100%;
    padding-top: 18px;
    background: #FAFAFA;
    cursor: pointer;
    padding-bottom: 17px;
  }

  ._1fvTC ._2LI7T._3Jtne {
    background: #F3F3F3;
  }

  ._1fvTC span {
    margin-right: 10px;
    margin-left: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
  }

  ._1fvTC img {
    margin-right: 20px;
  }

  ._1fvTC ._2LI7T._3Jtne img {
    transform: rotate(-180deg);
  }

  ._3vpo_ {
    position: absolute;
    top: 120%;
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
    background: #FAFAFA;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    width: 313px;
    height: 216px;
    overflow: auto;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  ._3vpo_.CIixJ {
    width: 378px;
    height: 138px;
  }

  ._3vpo_._2Hc8i {
    width: 382px;
    height: 312px;
  }

  ._3vpo_.CIixJ ._3Jtne {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }

  ._3vpo_._3Jtne {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }

  ._2ngSM {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 4px;
    width: 338px;
    height: 104px;
    margin: 2px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ._1qQGR ._1FS4r {
    width: 20px;
    height: 20px;
    border-radius: 26px;
    overflow: hidden;
    top: 50%;
    background: #696969;
    margin-left: 20px;
  }

  ._1qQGR._2JfOp {
    position: absolute;
    right: 35px;
    top: 23%;
  }

  ._1qQGR._3K-4e {
    position: absolute;
    left: 35px;
    top: 23%;
  }

  ._1qQGR div {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 3px;
    width: 65px;
    height: 27px;
    top: 30%;
  }

  ._1qQGR span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #696969;
    margin: 6px 8px;
  }

  .wB8Xq {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
  }

  ._131Dg {
    background: #FFFFFF;
    border-radius: 3px;
    margin: 0 9px 6px 15px;
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-height: 42px;
  }

  ._131Dg:last-child {
    margin: 0 9px 0 15px;
  }

  ._131Dg span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._131Dg._3Jtne span {
    font-weight: 600;
    color: #000000;
  }


  ._2Gf1Z {
    display: flex;
    position: absolute;
    right: 0;
    top: 9%;
    margin-right: 10px;
  }

  ._2dAkb {
    display: block;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 339px;
    height: 44px;
    margin: 5px 0 13px 15px;
    padding-left: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    outline: none;
  }

  .wB8Xq img {
    display: none;
  }

  ._3fKQg {
    display: none;
  }
}

@media (max-width: 768px) {
  ._3trlb {
    display: flex;
    padding: 15px 0 0 22px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: #000000;
  }

  ._24ZoS {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }

  ._3T0og {
    display: block;
    background-color: #FAFAFA;
    padding: 12px 21px;
  }

  ._2LSkm {
    display: none;
    overflow-x: hidden;
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    z-index: 1000;
    box-sizing: border-box;
    width: 100%
  }

  ._3fKQg {
    display: flex;
    justify-content: space-between;
    padding: 21px 21px 21px 21px;
    align-items: center;
    box-shadow: 0 1px 9px rgba(0, 0, 0, 0.4);
  }

  ._3fKQg div {
    text-decoration: none;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
  }

  ._2LSkm._1mCs5 {
    display: block;
  }

  ._24n9F ._3Ia4A {
    max-width: 1440px;
    margin: auto;
    padding-left: 25px;
    position: relative;
  }

  .wB8Xq {
    padding: 0 21px 0 21px;
    border-bottom: 3px solid #E1E1E1;
  }

  .wB8Xq span {
    margin-right: 16px;
  }

  ._3Ia4A ._3fWGF {
    display: flex;
    align-items: baseline;
  }

  ._3Ia4A ._1QBzd {
    display: flex;
    padding: 16px 0 16px 5px;
  }

  ._1rBr3,
  ._2xio1 {
    display: none;
  }

  ._3Ia4A ._1QBzd a {
    text-decoration: none;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
  }

  ._3Ia4A ._1QBzd:last-child {
    padding: 16px 0 16px 5px;
    margin-right: 114px;
    width: 400px;
  }

  ._1fvTC {
    display: flex;
    align-items: center;
  }

  ._1fvTC ._2LI7T {
    height: 100%;
    padding-top: 18px;
    background: #FAFAFA;
    cursor: pointer;
    padding-bottom: 17px;
  }

  ._1fvTC ._2LI7T._3Jtne {
    background: #F3F3F3;
  }

  ._1fvTC span {
    margin-right: 10px;
    margin-left: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
  }

  ._1fvTC img {
    margin-right: 20px;
  }

  ._1fvTC ._2LI7T._3Jtne img {
    transform: rotate(-180deg);
  }
}
