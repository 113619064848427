@media (min-width: 1024px) {
  ._3mGld {
    background: #FAFAFA;
    width: 605px;
    height: 507px;
    margin-right: 48px;
    display: flex;
  }

  ._3mGld ._2SYcb {
    display: inline-block;
    padding-top: 20px;
    padding-left: 35px;
  }

  ._3mGld ._1GbkH {
    height: 85px;
    /*margin-left: 40px;*/
    cursor: pointer;
  }

  /*.carouselImg .thumbActive {*/
  /*    width: 53px;*/
  /*    height: 3px;*/
  /*    background-color: #EF394F;*/
  /*    border-radius: 5px;*/
  /*    margin-top: 6px;*/
  /*    margin-bottom: 0;*/
  /*    margin-left: 45px;*/
  /*}*/
  ._3mGld ._31Dr_ {
    display: none;
    height: 100%;
  }

  ._3mGld ._31Dr_.XOeOn {
    display: flex;
    position: relative;
    overflow: hidden;
    padding: 38px 40px;
  }
}

@media (max-width: 1023px) {
  ._3mGld {
    background: #FAFAFA;
    width: 100%;
    /*height: 507px;*/
    margin-right: 48px;
    display: block;

  }

  ._3mGld ._2SYcb {
    display: inline-flex;
    padding-top: 20px;
    overflow: auto;
    width: 100%;
    padding-right: 36px;
  }

  ._3mGld ._1GbkH {
    width: 85px;
    height: 85px;
    cursor: pointer;
  }

  ._3Lzv_ {
    margin-bottom: 35px;
    margin-left: 36px;
  }


  /*.carouselImg .thumbActive {*/
  /*    width: 53px;*/
  /*    height: 3px;*/
  /*    background-color: #EF394F;*/
  /*    border-radius: 5px;*/
  /*    margin-top: 6px;*/
  /*    margin-bottom: 0;*/
  /*    margin-left: 55px;*/
  /*}*/
  ._3mGld ._31Dr_ {
    display: none;
    height: 100%;
  }

  ._3mGld ._31Dr_.XOeOn {
    display: flex;
    position: relative;
    overflow: hidden;
    padding: 46px 40px;
  }
}


._3Lzv_ {
  margin-bottom: 25px;
}

._2_arl {
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background-color: transparent;
}

._2_arl._2exxV {
  background-color: #EF394F;
}
