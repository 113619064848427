._2V8o6 {
  display: flex;
  align-items: center;
  /*padding: 5px 5px 5px 5px;*/
  width: 286px;
  height: 92px;
  text-decoration: none;
}

._2V8o6:hover {
  background: #FAFAFA;
}

._2V8o6 img {
  max-height: 100%;
}

.QWBKU {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
}

.QWBKU h2 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  color: #000000;
  margin: 0 0 3px 0;
}

.QWBKU ._1c21q {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
  text-overflow: ellipsis;
  overflow: hidden;
}

.QWBKU ._2Zy1f {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #979797;
  margin-bottom: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.QWBKU ._2Z1AA {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

.aJ90n {
  color: #EF394F;
}

._12xl8 {
  margin-left: 5px;
  text-decoration: line-through;
  font-weight: normal;
}
