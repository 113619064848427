.FRfN5 {
  z-index: 790;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.-zGL5 a {
  color: #79c47d !important;
  position: relative;
}

.-zGL5 a::after {
  width: 14px;
  height: 14px;
  background-image: url(/assets/src/components/Card/green-beauty-leaf.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-15px);
  content: "";
}

@media screen and (max-width: 768px) {
  .-zGL5 a::after {
    display: none;
  }
}

.gAyeX {
  background-color: #FFFFFF;
  max-width: 1440px;
  margin: auto;
  position: relative;
}

._1WSOX {
  cursor: pointer;
}

._3k45D {
  display: inline-block;
  color: #FFF;
  transition: all .1s ease-in-out;
}

._3k45D.IuOcN > a {
  color: #EF394F;
}

._2tibn {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 18px 25px;
  background-color: inherit;
  left: 4.86%;
  right: 85%;
  top: 7.16%;
  bottom: 91.19%;
  font-style: normal;
  font-weight: bold;
  font-family: "TT Norms", sans-serif;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin: 0;
  transition: all .1s ease-in-out;
}

._331rK {
  padding-right: 50px;
}

/* Add a white background color to navbar links on hover */
._181K7:hover > ._2tibn {
  background-color: white;
  color: black;
}

._181K7:hover:not(._2hz83) > ._3OxLh {
  display: flex;
}

._2tibn {
  color: white;
}

._3k45D > a,
._3k45D > ._1WSOX {
  display: inline-block;
  color: #FFFFFF;
  padding: 0 24px;
  line-height: 54px;
  font-style: normal;
  font-weight: bold;
  font-family: "TT Norms", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
}

.FRfN5 {
  background-color: #000;
}

._3k45D:hover {
  background-color: #FFFFFF;
}

._3k45D:hover > a,
._3k45D:hover > ._1WSOX {
  color: #000000;
}

._3k45D.T4d4p > div {
  display: flex;
}

@media (min-width: 1024px) {
  .gAyeX {
    padding-left: 45px;
    background: #000000;
  }

  ._2q0fA {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  ._2tibn {
    color: white;
  }

  ._3k45D > a,
  ._3k45D > ._1WSOX {
    display: inline-block;
    color: #ffffff;
    padding: 0 14px;
    line-height: 54px;
    font-style: normal;
    font-weight: bold;
    font-family: "TT Norms", sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
  }

  .FRfN5 {
    background-color: #000;
  }

  ._3k45D:hover {
    background-color: #FFFFFF;
  }

  ._3k45D:hover > a,
  ._3k45D:hover > ._1WSOX {
    color: #000000;
  }

  ._3k45D:hover > div {
    display: flex;
  }

  ._2q0fA {
    display: none;
  }
}

@media (max-width: 1023px) {
  .gAyeX {
    display: grid;
  }

  ._3k45D > a,
  ._3k45D > ._1WSOX {
    /*display: inline-block;*/
    color: #FFFFFF;
    padding: 0 24px;
    line-height: 54px;
    font-style: normal;
    font-weight: bold;
    font-family: "TT Norms", sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ._3k45D:first-child ._2q0fA {
    display: none;
  }

  ._2q0fA {
    transform: rotate(180deg) translate(-100%);
  }

  ._2tibn {
    color: #000000;
  }

  .FRfN5 {
    background-color: #FFFFFF;
    overflow-x: hidden;
  }

  ._3k45D > a,
  ._3k45D > ._1WSOX {
    color: #000000;
    width: 100%;
  }

  ._3k45D:first-child > div {
    display: block;
  }

  ._3k45D ._1WSOX {
    display: none;
  }
}
