._1U7mS {
    max-width: 1321px;
    /*height: 527px;*/
    margin: auto;
    padding: 20px 0 0 60px;
    display: block;
}

._1U7mS h1 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 31px;
    line-height: 37px;
    color: #000000;
}

._1U7mS ._24dQy {
    display: flex;
}

._1BDrz {
    display: flex;
    margin-right: 48px;
}

._1BDrz .v4lb1 {
    width: 605px;
    height: 507px;
    background: #FAFAFA;
    display: flex;
}

._1BDrz .v4lb1 ._3Z3GD {
    display: inline-block;
    margin: 26px 60px 26px 41px;
}

.v4lb1 ._3Z3GD .KYXu7 {
    margin-bottom: 14px;
    cursor: pointer;

}

.v4lb1 ._3Z3GD .KYXu7 img {
    width: 85px;
    height: 85px;
}

.v4lb1 ._3Z3GD .KYXu7:last-child {
    margin-bottom: 0;
}

._1BDrz ._211vz {
    position: relative;
}

._211vz ._2hgS6 {
    position: absolute;
    width: 347px;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    top: 5%;
    display: flex;
    align-items: center;
}

._211vz ._2hgS6 h1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-right: 17px;
}

._211vz ._2hgS6 span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #696969;
    width: 232px;
}

._211vz ._2hgS6 .uXurX {
    margin-bottom: 32px;
    cursor: pointer;
}

._2jws- {
    width: 384px;
    height: 415px;
    background: #FAFAFA;
    margin: 46px 41px 46px 0;
    display: none;
    align-items: center;
    justify-content: center;
}

._2jws-._3MXZ- {
    display: flex;
}


._1x2je {
    display: block;
}

._1x2je .U8apC {
    width: 668px;
    height: 507px;
    background: #F4F4F4;
    display: block;
}

.sktpc {
    padding: 56px 0 51px 88px;
    display: none;
    align-items: center;
    justify-content: center;
}

.sktpc._3MXZ- {
    display: block;
}

.sktpc h1 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
}

.sktpc h2 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #696969;
    margin: 6px 0 27px 0;
}

.sktpc p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 222%;
    color: #000000;
    width: 425px;
    height: 174px;
    margin: 63px 0 28px 0;
    align-items: center;
    display: flex;
}

.sktpc ._33E51 {
    border-radius: 30px;
    margin-right: 10px;
    width: 28px;
    height: 29px;
    cursor: pointer;
}

.sktpc ._33E51._3MXZ- {
    border: 3px solid #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}

.sktpc ._2pbk6 {
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    width: 117px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 26px;
}

.sktpc ._2pbk6._3MXZ- {
    border: 1px solid #000000;
}

.sktpc ._2pbk6._3MXZ- span {
    color: #000000;
}

.dlnPV ._2pbk6 img {
    margin-right: 16px;
}

.sktpc ._2pbk6 span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
}

.sktpc ._2gCrG {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
}

.sktpc ._3IXS_ {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #696969;
    cursor: pointer;
}

._3gdpD {
    background: #000000;
    border-radius: 3px;
    width: 244px;
    height: 47px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #FFFFFF;
}
