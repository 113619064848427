@media(min-width: 1024px) {
    ._1AifO {
        padding: 51px 170px 50px;
        width: 1440px;
        margin: auto;
        display: flex;
        justify-content: space-between;
    }

    ._2YL5e {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 37px 53px 46px 53px;
        width: 527px;
        height: 502px;
    }



    ._1AifO span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
    }

    .ZEjfq img {
        margin-right: 8px;
    }

    ._2YL5e p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
        margin-bottom: 17px;
    }

    .Z71wM {
        background: #FFFFFF;
        border: 1px solid #777777;
        width: 421px;
        box-sizing: border-box;
        border-radius: 3px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        padding: 15px 19px;
        color: #A9A9A9;
    }
    .Z71wM:focus {
        background: #FBFBFB;
        text-decoration: none;
        outline: none;
    }

    ._2z_Gj {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 244px;
        height: 48px;
        border: 1px solid #000000;
        border-radius: 3px;
        box-sizing: border-box;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-decoration: none;
        outline: none;
        cursor: pointer;
        background-color: #000000;
        color: #FFFFFF;
        transition: all .2s ease-in-out;
    }

    ._2z_Gj:hover {
        background-color: #FFFFFF;
        color: #000000;
    }

    .ifY4X ._2z_Gj {
        margin-top: 28px;
    }

    ._2YL5e ._2z_Gj {
        margin-top: 40px;
    }

    ._24dfA {
        display: flex;
        justify-content: flex-end;
    }

    ._2YL5e .ZEjfq {
        display: flex;
        justify-content: space-between;
        margin-bottom: 46px;
        margin-top: 20px;
    }

    .ifY4X .ZEjfq {
        display: flex;
        justify-content: space-between;
        margin-bottom: 29px;
        margin-top: 20px;
    }

    .KilCH {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 191px;
        height: 47px;
        border: 1px solid #A7A7A7;
        box-sizing: border-box;
        border-radius: 3px;
        text-decoration: none;
    }

    .ifY4X {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 25px 53px 46px 53px;
        width: 527px;
        height: 502px;
    }

    .ifY4X p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 17px;
        color: #696969;
        margin-top: 5px;
        margin-bottom: 22px;
    }

    ._1AifO h1 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #000000;
        margin: 0;
    }

    ._3Pyn6 {
        display: flex;
        align-items: center;
        padding: 0 53px;
        margin-bottom: 18px;
    }

    ._3II1a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: wrap;
    }

    ._3II1a span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #959595;
    }
    ._1FrIa {
        display: none;
    }
}

@media(max-width: 1023px) {
    ._1AifO {
        /*padding: 51px 170px 50px;*/
        /*width: 1440px;*/
        margin: auto;
        display: block;
        justify-content: space-between;
    }

    ._2YL5e {
        background: #FFFFFF;
        border-top: 1px solid #F1F1F1;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 36px 21px 46px 21px;
        width: 100%;
        height: 502px;
        /*display: flex;*/
        /*align-items: center;*/
        text-align: center;
    }



    ._1AifO span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
    }

    .ZEjfq img {
        margin-right: 8px;
    }

    ._2YL5e p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
        margin-bottom: 17px;
    }

    .Z71wM {
        background: #FFFFFF;
        border: 1px solid #777777;
        width: 100%;
        box-sizing: border-box;
        border-radius: 3px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        padding: 15px 19px;
        color: #A9A9A9;
    }
    .Z71wM:focus {
        background: #FBFBFB;
        text-decoration: none;
        outline: none;
    }

    ._2z_Gj {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 42px;
        border: 1px solid #000000;
        border-radius: 3px;
        box-sizing: border-box;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-decoration: none;
        outline: none;
        cursor: pointer;
        background-color: #000000;
        color: #FFFFFF;
        transition: all .2s ease-in-out;
    }

    ._2z_Gj:hover {
        background-color: #FFFFFF;
        color: #000000;
    }

    .ifY4X ._2z_Gj {
        margin-top: 28px;
        margin-bottom: 18px;
    }

    ._2YL5e ._2z_Gj {
        margin-top: 40px;
    }

    ._24dfA {
        display: flex;
        justify-content: flex-end;
    }

    ._2YL5e .ZEjfq {
        display: flex;
        justify-content: space-between;
        margin-bottom: 46px;
        margin-top: 20px;
    }

    .ifY4X .ZEjfq {
        display: flex;
        justify-content: space-between;
        margin-bottom: 19px;
        margin-top: 20px;
    }

    .ifY4X {
        background: #FAFAFA;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 36px 21px 46px 21px;
        width: 100%;
        height: 502px;
        text-align: center;
    }

    .KilCH {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 146px;
        height: 47px;
        border: 1px solid #A7A7A7;
        box-sizing: border-box;
        border-radius: 3px;
        text-decoration: none;
    }

    .ifY4X .qguH3   {
        display: none;
    }

    .ifY4X p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        color: #696969;
        margin-top: 5px;
        margin-bottom: 22px;
    }

    ._1AifO h1 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 23px;
        line-height: 27px;
        color: #000000;
        margin: 0;
    }

    ._3Pyn6 {
        display: flex;
        align-items: center;
        padding: 0 36px;
        margin-bottom: 18px;
        margin-top: 21px;
    }

    ._3II1a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: wrap;
    }

    ._3II1a span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #959595;
    }

}

@media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  ._1AifO {
    padding: 50px 80px 50px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  ._2YL5e {
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 37px 53px 46px 53px;
    width: 527px;
    height: 502px;
    margin-bottom: 30px;
  }
}

@media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  ._1AifO {
    padding: 50px 80px 50px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

}
