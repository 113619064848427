@media(min-width: 1024px) {
    ._1YXtD {
        display: block;
        width: 100%;
        position: relative;
    }

    .VGlQ2 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #A6A6A6;
        margin: 23px 0;
    }

    ._2C077 {
        background: #FAFAFA;
        height: 69px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-left: 51px;
    }

    ._2C077 span:first-child {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #696969;
    }

    ._2C077 span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #696969;
    }

    .mLYCS {
        display: block;
        background: #F8F8F8;
        width: 100%;
        border: 1px solid #FAFAFA;
        box-sizing: border-box;
        padding: 18px 41px;
    }

    ._1R5rR {
        display: flex;
        justify-content: space-between;
    }

    ._3vMOQ {
        display: flex;
        justify-content: space-evenly;
    }

    .apndg {
        display: flex;
    }
}

@media(max-width: 768px) {
    ._1YXtD {
        display: block;
        width: 100%;

    }

    .VGlQ2 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #A6A6A6;
        margin: 23px 0;
    }

    ._2C077 {
        height: 69px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    ._2C077 span:first-child {
        display: none;
    }

    ._2C077 span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #696969;
    }

    .mLYCS {
        display: block;
        background: #F8F8F8;
        width: 100%;
        border: 1px solid #FAFAFA;
        box-sizing: border-box;
        padding: 18px 41px;
    }

    ._1R5rR {
        display: flex;
        justify-content: space-between;
    }

    ._3vMOQ {
        display: flex;
        justify-content: space-evenly;
    }

    .apndg {
        display: flex;
    }
}
