@media(min-width: 1024px) {
    ._2xOV3 {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
    }
}

@media(max-width: 768px) {
    ._2xOV3 {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        grid-gap: 32px;
    }

    ._2xOV3 > div:nth-child(2) {
        padding: 21px;
    }

}


