._63gnC ._3NILS {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*width: 100%;*/
}

._63gnC a {
  text-decoration: none;
}

._3NILS ._35Tlf {
  display: flex;
  align-items: center;
}

._63gnC ._3NILS div {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #696969;
}

._63gnC ._3NILS ._2nhtv {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #000000;
  margin-top: 0;
  margin-bottom: 4px;
  width: 140px;
}

._63gnC ._3NILS div:last-child {
  font-weight: normal;
}

._2ilj5 {
  height: 82px;
  margin-right: 22px;
}

._63gnC img:last-child {
  cursor: pointer;
}


._63gnC ._2W5I8 {
  margin: 0 68px 0 29px;
  justify-content: space-between;
  display: flex;
}

._63gnC ._2W5I8 span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #A6A6A6;
}

._63gnC ._2W5I8 span:last-child {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  color: #000000;
}


._29GqJ {
  width: 100%;
  height: 1px;
  margin: 25px 0;
  background-color: #E9E9E9;
}


.w5I_D {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 29px;

}

._63gnC ._3NILS ._2R0RN {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

._63gnC ._3NILS ._28rJt {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #EF394F;
}

._63gnC ._3NILS .UQoyz {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  text-decoration: line-through;
}

._3XuGh {
  position: absolute;
  right: 0;
}

._2Z7jk {
  display: flex;
  justify-content: flex-end;
}


@media (min-width: 1024px) {
  ._63gnC ._1aFc5 {
    width: 150px;
    display: block;
  }

  ._63gnC ._1IfWG {
    display: contents;
  }

  ._16HdM {
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    margin-left: 25px;
  }


  ._16HdM span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: textfield;
    flex: none;
    text-align: center;
    width: 15px;
    cursor: default;
  }

  ._16HdM span::-webkit-inner-spin-button, ::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  ._16HdM button {
    font-family: "Apercu Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #A9A9A9;
    background: none;
    border: none;
    padding: 4px 10px;
    cursor: pointer;
    outline: inherit;
  }

  ._16HdM .GMW7- {
    font-size: 8px;
    padding: 4px 10px;
  }


}

@media (max-width: 1023px) {
  ._63gnC ._1aFc5 {
    width: 150px;
    display: block;
  }

  ._63gnC ._1IfWG {
    display: block;
  }

  ._16HdM {
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    display: inline-block;
  }


  ._16HdM span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: textfield;
    flex: none;
    text-align: center;
    width: 15px;
    cursor: default;
  }

  ._16HdM span::-webkit-inner-spin-button, ::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  ._16HdM button {
    font-family: "Apercu Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #A9A9A9;
    background: none;
    border: none;
    padding: 4px 10px;
    cursor: pointer;
    outline: inherit;
  }

  ._16HdM .GMW7- {
    font-size: 8px;
    padding: 4px 10px;
  }


}
