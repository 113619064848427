.U5_rw {
  display: none;
  position: fixed;
  background: #ffffff;
  z-index: 99999;
  top: 50%;
  left: 50%;
  padding: 15px 50px 50px 50px;
  transform: translate(-50%, -50%);
  max-width: 500px;
  border-radius: 5px;
}

.U5_rw._1Io5a {
  display: block;
}

._2Vsiw {
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 46px;
  color: #696969;
}

._2UPLT {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 35px;
}

._2UPLT img {
  cursor: pointer;
}

.U5_rw .MXJne {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}

.U5_rw span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
  margin-bottom: 12px;
}

.WcFK9 {
  background: #ffffff;
  z-index: 9999;
}

._1m1MP {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  display: none;
  opacity: 0.5;
  background-color: #000;
}

._1m1MP._1Io5a {
  display: block;
}

.WcFK9 button {
  margin-top: 20px;
}


