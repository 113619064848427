.oeKmi {
  display: none;
}

@keyframes _3tEa7 {
  0% {
    transform: translate3d(0, 80px, 0);
    opacity: 0;
  }
  1.8%,
  31.5% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  33.3% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}

@media (min-width: 1024px) {
  .oeKmi {
    margin-left: 68px;
    display: flex;
    flex: 1;
  }

  ._25Qpv {
    margin-top: 27px;
    margin-bottom: 27px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #000000;
    display: inline-block;
  }

  ._1mbeR {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 58px;
    margin-left: 7px;
    line-height: 58px;
    display: inline-block;
    font-family: "Inter", sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: 14px;
    color: #000000;
  }

  ._1mbeR div {
    position: absolute;
    opacity: 0;
  }

  ._1mbeR div:nth-of-type(1) {
    -webkit-animation: _3tEa7 16.8s 0s ease infinite;
  }

  ._1mbeR div:nth-of-type(2) {
    -webkit-animation: _3tEa7 16.8s 5.6s ease infinite;
  }

  ._1mbeR div:nth-of-type(3) {
    -webkit-animation: _3tEa7 16.8s 11.2s ease infinite;
  }
}
