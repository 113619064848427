@media(min-width: 1024px) {
    .tyE1W h2 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        margin: 0 0 6px 0;
        text-transform: uppercase;
        color: #000000;
    }

    .tyE1W p {
        margin-top: 53px;
        margin-bottom: 28px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 222%;
        color: #000000;
    }


    .tyE1W {
        margin: 0 155px 51px 88px;
        display: none;
    }

    .tyE1W.xM5xx {
        margin: 0 155px 51px 88px;
        display: block;
    }


    .tyE1W .fcuTU {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 20px;
        margin-top: 13px;
        color: #000000;
      margin-bottom: 30px;
    }

    .tyE1W .J3HuX {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
    }

    .tyE1W ._1IJ_L {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 4px;
        color: #696969;
    }

    .tyE1W ._3ipL8 {
        background: #EF394F;
        border-radius: 3px;
        width: 244px;
        height: 47px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;
        border-width: 0;
        display: block;
        line-height: 48px;
        box-sizing: border-box;
        text-decoration: none;
        outline: none;
    }

    .tyE1W ._2epik {
      margin-top: 0;
      max-height: 150px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
}

@media(max-width: 1023px) {
    .tyE1W h2 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        margin: 0 0 6px 0;
        text-transform: uppercase;
        color: #000000;
    }

    .tyE1W p {
        margin-top: 0;
        margin-bottom: 28px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 178%;
        color: #000000;
    }

    .tyE1W {
        padding: 0 21px 51px 21px;
        display: none;
    }

    .tyE1W.xM5xx {
        display: block;
    }

    .tyE1W .fcuTU {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        margin-top: 13px;
        color: #000000;
        margin-bottom: 10px;
    }

    .tyE1W .J3HuX {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        color: #696969;
    }

    .tyE1W ._1IJ_L {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 4px;
        color: #696969;
    }

    .tyE1W ._3ipL8 {
        background: #EF394F;
        border-radius: 3px;
        width: 244px;
        height: 47px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;
        border-width: 0;
        display: block;
        line-height: 48px;
        box-sizing: border-box;
        text-decoration: none;
        outline: none;
    }

}



