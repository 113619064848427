.YERVQ {
  background: #fff;
  border: 1px solid #000;
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  padding: 0 15px;
  height: 49px;
  color: #111;
}

.YERVQ:focus {
  background: #fbfbfb;
  text-decoration: none;
  outline: none;
  color: #111;
}

.YERVQ:-webkit-autofill:active {
  background: transparent;
  -webkit-text-fill-color: transparent;
}

.YFaLs {
  background: #000000;
  border-radius: 3px;
  width: 218px;
  height: 48px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #000000;
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.YFaLs:hover {
  background-color: transparent;
  color: #000000;
  transition: 0.5s;
  border: 1px solid #000000;
}
