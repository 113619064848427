._2oroL {
  display: none;
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  /* background: #3c004c; */
  background-image: url(/assets/src/components/FreeShippingPopIn/benlux-paques-2025-bg.jpg);
  background-size: cover;
  padding: 20px;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

._2oroL.visible {
  display: flex;
}

._6wm-4 {
  font-weight: 800; /* 300 */
  font-size: 26px /* 38px */;
  /* line-height: 39px; */
  text-align: center;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.17);*/

  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.uUDnU {
  /* margin-top: 30px; */
  font-weight: 600;
  text-transform: uppercase;
}

/* .description span:first-of-type {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.06em;
} */

.uUDnU span:last-of-type {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

._6wm-4,
.uUDnU {
  /* text-shadow: 0 0 4px #f900fa; */
}

._3D9Hl {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
  background: #FFF;
  border-radius: 50%;
  cursor: pointer;
}

._3D9Hl:before,
._3D9Hl:after {
  position: absolute;
  left: 14px;
  top: 5px;
  content: " ";
  height: 20px;
  width: 2px;
  background-color: #000;
  opacity: 0.7;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
}

._3D9Hl:before {
  transform: rotate(45deg);
}
._3D9Hl:after {
  transform: rotate(-45deg);
}

._3D9Hl:hover::before,
._3D9Hl:hover::after {
  opacity: 1;
}

._2wliP {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  display: none;
  opacity: 0.5;
  background-color: #000;
}

.ZUWrF {
  padding: 20px;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
}

._2izh9 {
  font-weight: 400;
  line-height: 50px;
  margin: 0 5px 0 0px;
  font-size: 45px !important;
  height: 70px;
  color: #fff;
  opacity: 0.7;
  text-align: center;
}

._1qypR {
  display: inline-block;
  background: #f0f0f0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 60px;
  line-height: 60px;
  font-weight: 500;
  font-size: 35px;
  color: #000;
  text-align: center;
  margin-right: 5px;
  border-radius: 10px;
}

._36Pai {
  font-weight: 300;
  font-size: 12px;
  margin-top: 6px;
  color: #444;
  text-align: center;
  text-transform: uppercase;
}

._2wliP.visible {
  display: block;
}

._1Rzel {
  display: none;
}

@media (max-width: 768px) {
  ._2O6hp {
    display: none;
  }

  ._1Rzel {
    display: block;
    max-width: 100%;
    margin: -12px 0 12px;
  }

  ._3D9Hl {
    top: -8px;
    right: -8px;
  }

  /* .modal img {
    display: none;
  } */

  .ZUWrF {
    padding: 10px;
  }
}
