@media(min-width: 1024px) {
    ._1rkRJ {
        display: block;
        background: #F8F8F8;
        width: 100%;
        border: 1px solid #FAFAFA;
        box-sizing: border-box;
        padding: 18px 41px;
        margin-bottom: 26px;
    }

    ._14YWt {
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
    }

  ._14YWt span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

    ._2Q3i7 {
        display: flex;
    }

    ._3XAyY {
        display: flex;
        margin-top: 40px;
    }

    ._3XAyY a:first-child {
        margin-right: 50px;
    }

    ._1cjeb {
        display: flex;
        flex-direction: column;
    }

    ._1cjeb:not(:last-child) {
        margin-right: 50px;
    }

    ._1cjeb ._1YrlB {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #A6A6A6;
        margin-bottom: 8px;
    }

    ._1cjeb span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
    }

    ._1cjeb ._1RO1e {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        color: #696969;
    }
}

@media(max-width: 1023px) {
    ._1rkRJ {
        display: block;
        background: #F8F8F8;
        width: 100%;
        border: 1px solid #FAFAFA;
        box-sizing: border-box;
        padding: 9px 14px;
        margin-bottom: 20px;
    }

    ._14YWt {
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        color: #696969;
    }

    ._14YWt span {
      font-family: "TT Norms", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
    }

    ._14YWt div {
        display: none;
    }

    ._2Q3i7 {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 14px;
    }

    ._3XAyY {
        display: block;
        margin-top: 40px;
    }

    ._3XAyY a:first-child {
        margin-bottom: 10px;
    }

    ._3XAyY a {
        width: 100%;
    }

    ._1cjeb {
        display: flex;
        flex-direction: column;
    }

    ._1cjeb:not(:last-child) {
        margin-right: 50px;
    }

    ._1cjeb ._1YrlB {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #A6A6A6;
        margin-bottom: 8px;
    }

    ._1cjeb span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
    }

    ._1cjeb ._1RO1e {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        color: #696969;
    }
}

