@media(min-width: 1024px) {
    ._2MPaM {
        max-width: 1440px;
        margin: 0 auto 90px;
        padding: 0 120px;
    }

    .lDCtE {
        display: inline-block;
        text-align: center;
        margin-right: 9px;
        background: #FAFAFA;
        vertical-align: middle;
    }

    .lDCtE > ._3k1Pm {
        margin: auto;
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .lDCtE > ._2NIUE {
        width: 161px;
        height: 75px;
        display: flex;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
    }

    .lDCtE:last-child {
        margin-right: 0;
    }
}

@media(max-width: 768px) {
    ._2MPaM {
        max-width: 1440px;
        padding: 0 21px;
        width: 100vw;
        overflow-x: auto;
        position: relative;
        white-space: nowrap;
        overflow-style: unset;
      margin-bottom: 45px;
    }

    ._2MPaM::-webkit-scrollbar {
        display: none;
    }

    .lDCtE {
        display: inline-block;
        text-align: center;
        margin-right: 7px;
        background: #FAFAFA;
        vertical-align: middle;
    }

    .lDCtE > ._3k1Pm {
        margin: auto;
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .lDCtE > ._2NIUE {
        width: 161px;
        height: 75px;
        display: flex;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
    }

    .lDCtE:last-child {
        margin-right: 0;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  ._2MPaM {
    margin: 47px auto 40px;
    padding: 0 80px;
    white-space: nowrap;
    overflow: auto;
  }

  .lDCtE {
    display: inline-block;
    text-align: center;
    margin-right: 9px;
    background: #FAFAFA;
    vertical-align: middle;
  }

  .lDCtE > ._3k1Pm {
    margin: auto;
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .lDCtE > ._2NIUE {
    width: 161px;
    height: 75px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
  }

  .lDCtE:last-child {
    margin-right: 0;
  }

}
