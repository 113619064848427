._2aijm span {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

@media (min-width: 1024px) {
  ._1arb3 {
    max-width: 1440px;
    padding: 0 120px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 72px;
    margin: 0 auto 72px;
  }

  ._2aijm {
    display: inline-flex;
    align-items: center;
  }

  ._2aijm img {
    margin-right: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  ._1arb3 {
    max-width: 1024px;
    padding: 0 80px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 28px;
    margin: 0 auto 72px;
  }

  ._2aijm {
    display: inline-flex;
    vertical-align: middle;
  }

  ._2aijm img {
    margin-right: 15px;
  }
}

@media (max-width: 1023px) {
  ._1arb3 {
    padding: 0px 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    margin: 0 auto 72px;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  ._2aijm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}



