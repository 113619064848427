._3qJ8V._3Yttm span {
  font-weight: bold;
}


@media(min-width: 1024px) {

  ._1E2vt {
    margin: 47px auto 40px;
    max-width: 1440px;
    box-sizing: border-box;
    padding: 0 120px;
    display: flex;
  }

  .N4v7J {
    display: block;
    margin-right: 60px;
  }

  ._3qJ8V {
    display: flex;
    background: #FAFAFA;
    width: 269px;
    height: 48px;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid #E6E6E6;
    padding-left: 20px;
  }

  ._1Dv5w {
    border-bottom: none;
    margin-bottom: 22px;
  }

  ._3qJ8V span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
  }


  ._1zyu1 {
    width: 100%;
  }
  ._5nO8m {
    display: none;
  }
}

@media(max-width: 768px) {

  ._1E2vt {
    display: block;
  }

  .N4v7J {
    display: flex;
    width: 100%;
    overflow: scroll;
  }

  ._3qJ8V {
    display: flex;
    background: #FAFAFA;
    width: 100%;
    height: 48px;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid #E6E6E6;
    padding-left: 20px;
    white-space: nowrap;
  }

  ._1Dv5w {
    border-bottom: none;
    margin-bottom: 22px;
  }

  ._3qJ8V span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
  }


  ._1zyu1 {
    background-color: #ffffff;
    transition: all .3s ease-in-out;
    width: 100%;
    height: 100%;
    padding: 0 21px;
    display: block;
  }



  .ZcV12 {
    width: 100%;
    position: relative;
  }

  ._26HJS {
    display: flex;
    position: absolute;
    justify-content: space-around;
    width: 100%;
    top: 16%;
    height: 48px;
    align-items: center;
  }

  ._26HJS img {
    display: none;
  }

  ._26HJS.active img {
    display: flex;
  }

  ._26HJS div {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    color: #FFFFFF;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }
  ._1zyu1 {
    padding: 0;
  }

}


