._3g61k {
  margin-top: 28px;
}

._3g61k:not(._3l4hX) {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: flex-start;
}

._3g61k:not(._3l4hX)[\:not-has\(\:nth-child\(4\)\)] {
  grid-gap: 46px;
  gap: 46px;
  justify-content: unset;
}

._3g61k:not(._3l4hX):not(:has(:nth-child(4))) {
  grid-gap: 46px;
  gap: 46px;
  justify-content: unset;
}

._3g61k a {
  font-size: 0;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
}

._3g61k a:hover {
  opacity: 0.8;
}

._3g61k._3l4hX a {
  width: 200px;
  display: inline-block;
  margin-right: 24px;
  font-size: 0;
}

._3g61k img {
  width: 100%;
}

._3g61k span {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  white-space: pre-wrap;
  padding: 20px 12px;
  color: #000000;
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

._3g61k a.blackFriday span {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 300;
  background: none;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}

@media (min-width: 1024px) {
  ._3g61k a {
    width: 200px;
  }

  ._3g61k._3l4hX a {
    margin-right: 48px;
  }
}

@media (max-width: 1023px) {
  ._3g61k:not(._3l4hX) {
    display: grid;
    padding: 0 21px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
    -moz-column-gap: 12px;
         column-gap: 12px;
    grid-row-gap: 12px;
    row-gap: 12px;
  }

  ._3g61k span {
    padding: 6px 12px;
  }

  ._3g61k:not(._3l4hX) span {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  ._3g61k span {
  }
}
