._3cctW {
  display: flex;
  margin-left: 48px;
}

.Nnnnw {
  display: flex;
  align-items: flex-start;
}


.Nnnnw a {
  font-size: 0;
  position: relative;
  overflow: hidden;
  transition: all .2s ease-in-out;
}

.Nnnnw a:hover {
  opacity: .8;
}

.Nnnnw img {
  width: 100%;
  height: 100%;
}

.Nnnnw span {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  white-space: pre-wrap;
  padding: 20px 12px;
  color: #000000;
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

._3o7RS {
  height: calc(201/1116 * 100vw);;
  background-size: cover;
  background-position: center;
  position: relative;
}

._3o7RS.dior {
  max-width: 1200px;
  margin: 0 auto 4px;
}

._2b1CP {
  max-width: 1200px;
  margin: auto;
}

._26M25 {
  position: absolute;
  color: #FFFFFF;
  right: 20px;
  top: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  line-height: 16px;
  text-shadow: 0 0 20px #000;
}

.Se9QJ {
  background-color: #FAFAFA;
  margin-bottom: 24px;
}

._1nDKm {
  max-width: 1200px;
  margin: auto;
  padding: 32px 24px;
  text-align: center;
  display: flex;
  justify-content: center;
}

._1nDKm.xZoaQ {
  padding: 0;
  text-align: left;
}

._1nDKm.xZoaQ ._1wZVq {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;
}

._1nDKm h1 {
  margin: 0;
  font-family: 'TT Norms', sans-serif;
  font-weight: bold;
  font-size: 30px;
}

._1nDKm p {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 160%;
  max-width: 800px;
  margin: 6px auto 0;
}

._2b1CP .lS973 {
  max-width: 1440px;
  margin: auto;
  padding-left: 120px;
  position: relative;
}

.lS973 .kQoG9 {
  display: block;
  padding-right: 33px;
}

._3B74r .KWFhW img {
  margin-right: 6px;
}

.kQoG9 .KWFhW img {
  margin-right: 6px;
}

.lS973 .kQoG9 {
  display: block;
  padding-right: 33px;
}


.kQoG9 span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 222%;
  color: #000000;
  max-width: 508px;
}

.kQoG9 h1 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 37px;
  margin: 0;
  padding: 31px 0 9px 0;
  color: #000000;
}


.kQoG9 .KWFhW span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #717171;
}

.kQoG9 .KWFhW {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.R_NAM ._1I5gP {
  background: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  position: absolute;
  z-index: 2;
  top: 75%;
  width: 180px;
  height: 56px;
  align-items: center;
  justify-content: center;
  display: flex;
}

._2-jxH {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  margin: 0;
}

.R_NAM {
  display: inline-block;
  position: relative;
  cursor: pointer;
}


.R_NAM img {
  width: 180px;
  height: 227px;
}

@media(min-width: 1024px) {
  .Nnnnw a {
    width: 180px;
    height: 100%;
  }

  ._1nDKm {
    max-height: 227px;
  }

  ._1xRNS {
    display: none;
  }
}


@media(max-width: 1023px) {
    .Se9QJ.dior ._2Y5jQ {
      height: auto;
      height: initial;
    }
    .Se9QJ.dior ._2rB2c {
      display: none;
    }

  .Nnnnw a {
    width: 160px;
    height: 100%;
  }

  .Nnnnw span {
    padding: 10px 12px;
    font-size: 12px;
  }

  ._1wZVq p:first-of-type {
    display: none;
  }

  ._1wZVq ._2rB2c {
    text-decoration: underline;
  }

  ._2Y5jQ {
    display: block;
    overflow: hidden;
    height: 50px;
  }

  ._2Y5jQ._1I-04 {
    height: 100%;
  }

  ._1nDKm {
    display: block;
  }

  ._1nDKm.xZoaQ ._1wZVq {
    padding: 20px 21px;
  }

  ._3cctW {
    display: flex;
    margin-left: 0;
    overflow: auto;
    -ms-overflow-style: none;
  }

  ._3cctW::-webkit-scrollbar {
    display: none;
  }

  ._3o7RS {
    height: 170px;
  }
}

/* Dior Header */
._1nnE8 {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,.3);
  z-index: 2;
}

._2PO7p {
  z-index: 3;
}

._1nnE8,
._2PO7p > ul:first-of-type {
  visibility: hidden;
  opacity: 0;
  transition: opacity .1s ease-in-out;
}

._2PO7p:hover + ._1nnE8,
._2PO7p:hover > ul:first-of-type {
  visibility: visible;
  opacity: 1;
}

._2PO7p a {
  color: #000;
  text-decoration: none;
}

._2PO7p a:hover {
  font-weight: 500;
}

._2PO7p > ul:first-of-type > li > span > a {
  font-weight: 600;
}

._1VL1- {
  margin: 24px auto;
  text-align: right;
  width: 100%;
  max-width: 1200px;
  background: #FFF;
  display: flex;
  grid-gap: 24px;
  gap: 24px;
}

@media(max-width: 768px) {
  ._1VL1- {
    padding: 0 12px;
    grid-gap: 12px;
    gap: 12px;
    flex-direction: column;
  }

  ._2PO7p ul {
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
    width: calc(100vw - 24px);
  }
}

