._2zaJr {
  background-color: #000;
  position: fixed;
  bottom: 0;
  padding: 25px 0;
  width: 100%;
  z-index: 1001;
  display: none;
}

._2zaJr._1_9wq {
  display: block;
}

._1M7x2 {
  max-width: 1440px;
  margin: auto;
  padding: 0 120px;
}

.r4GbN {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  margin: 0 0 12px;
  padding: 0;
  color: #ffffff;
}

._1M7x2 p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 16px;
  margin: 12px 0;
  padding: 0;
  color: #ffffff;
}

._1_-0f {
  display: flex;
  justify-content: flex-end;
}

._1_-0f button:first-child {
  margin-right: 10px;
  border-color: #ffffff;
}

@media (max-width: 1023px) {
  ._1M7x2 {
    padding: 0 21px;
  }

  ._1_-0f button:first-child {
    padding: 0 22px;
  }
}

