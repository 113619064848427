@media (min-width: 1024px) {
  ._3EYHh {
    display: block;
    position: relative;
    transform-origin: top left;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: left;
  }

  .A24Cv {
    display: block;
    position: relative;
    transform-origin: top left;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: left;
    max-width: 1440px;
  }

  ._2Em0w {
    position: absolute;
    width: 53px;
    height: 53px;
    top: 50%;
    border-radius: 50%;
    margin-top: -45px;
    z-index: 10;
    transition: visibility 1s 0s, opacity .2s ease-in-out;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    background-image: url(/assets/src/assets/arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, .7);
  }

  ._2Em0w._2_CEd {
    visibility: visible;
    opacity: .9;
  }

  ._2Em0w:hover {
    opacity: 1;
  }

  ._2Em0w._2DLHb {
    left: 20px;
    transform: rotate(-180deg);
  }

  ._2Em0w._1nr8B {
    position: absolute;
    right: 20px;
  }

  ._2Em0w._3wXb2 {
    position: absolute;
    left: 20px;
  }


  ._3UFKy {
    display: block;
    overflow-x: auto;
    position: relative;
    white-space: nowrap;
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;

    padding-left: calc((100% - 1200px) / 2);
  }

  ._107gp {
    display: block;
    overflow-x: auto;
    position: relative;
    white-space: nowrap;
    padding-left: calc((-100% - 1200px) / 2);
  }

  ._3UFKy::-webkit-scrollbar {
    display: none;
  }

  /*.semiScrollList::-webkit-scrollbar {*/
  /*  display: none;*/
  /*}*/

  ._39lmK {
    display: inline-flex;
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;

    padding-right: calc((100vw - 1200px) / 2);
  }

  ._3zlpx {
    display: inline-flex;
    padding-right: calc((100vw - 1200px) / 2);
  }

  ._39lmK > div {
    margin-right: 50px;
    width: 263px;
  }

  ._39lmK > div:last-child {
    margin-right: 0 !important;
  }

  ._39lmK > a {
    margin-right: 50px;
    width: 263px;
  }

  ._39lmK > a:last-child {
    margin-right: 0 !important;
  }
}

/* Mobile */

@media (max-width: 1023px) {
  ._3EYHh {
    display: block;
    position: relative;
    transform-origin: top left;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: left;
    width: 100vw;
  }

  ._2Em0w {
    position: absolute;
    width: 53px;
    height: 53px;
    top: 50%;
    border-radius: 50%;
    margin-top: -45px;
    z-index: 10;
    transition: visibility 1s 0s, opacity .2s ease-in-out;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    background-image: url(/assets/src/assets/arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, .7);
  }

  ._2Em0w._2_CEd {
    visibility: visible;
    opacity: .9;
  }

  ._2Em0w:hover {
    opacity: 1;
  }

  ._2Em0w._2DLHb {
    left: 20px;
    transform: rotate(-180deg);
  }

  ._2Em0w._1nr8B {
    position: absolute;
    right: 20px;
  }

  ._3UFKy {
    display: block;
    overflow-x: auto;
    position: relative;
    white-space: nowrap;
    padding-left: 18px;
  }

  ._3UFKy::-webkit-scrollbar {
    display: none;
  }

  ._39lmK {
    padding-right: 18px;
  }

  ._39lmK > a {
    width: 162px;
    margin-right: 12px;
  }

  ._39lmK > div:last-child {
    margin-right: 18px;
  }
}

