
._3y74y {
    margin: 25px 0 50px 30px
}

.JNndz {
    display: inline-flex;
}

._33CSJ {
    text-align: center;
}

.OBTkP {
    max-width: 90px;
    margin-right: 18px;
}

._33CSJ a {
    width: 90px;
    height: 111px;
    background: #FAFAFA;
}
._33CSJ img {
    margin: auto;
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.OBTkP span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #696969;
    display: inline-block; /* so height and width actually take affect */
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

._3y74y h1 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin: 0 0 18px;
}

._1jTLZ {
    text-decoration: none;
    display: block;
}