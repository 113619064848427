@media (min-width: 1025px) {
    ._3HEJi {
        max-width: 1200px;
        margin: auto;
    }

    ._2b8eL ._2IP-N {
        display: inline-block;
    }

    ._3HEJi ._6eRz- {
        width: 37.5%;
        display: inline-block;
        vertical-align: top;
        margin-top: 50px;
    }

    ._2Ia7A {
        display: none;
    }
}

@media(max-width: 1023px) {
    ._3HEJi {
        max-width: 1200px;
        margin-top: 53px;
    }

    ._6eRz- {
        display: none;
    }

    ._3HEJi ._2Ia7A {
        width: 32.5%;
        display: inline-block;
        vertical-align: top;
        margin-top: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
  ._3HEJi {
    margin: 53px 150px 0;
  }

}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
  ._3HEJi {
    max-width: 1200px;
  }

  ._2b8eL ._2IP-N {
    display: inline-block;
  }

  ._3HEJi ._6eRz- {
    width: 26.5%;
    display: inline-block;
    vertical-align: top;
    margin-top: 100px;
  }

  ._2Ia7A {
    display: none;
  }
}



