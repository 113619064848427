._1w1mt {
  position: absolute;
  right: 0;
  top: 0;
}

.jEPge {
  padding: 0 0.25em;
  text-decoration: underline;
  color: #696969;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
}

.jEPge:hover {
  text-decoration: none;
}

.jEPge._124t9 {
  text-decoration: none;
  font-weight: bold;
}
