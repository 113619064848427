._2nZPz {
  display: flex;
}

@media (min-width: 1024px) {
  ._2jIm8 {
    display: block;
    width: 167px;
  }

  .DAaFJ {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    width: 167px;
    height: 77px;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: .2s ease-in-out;
  }

  .DAaFJ:hover {
    opacity: .8;
  }

  .DAaFJ img {
    width: 100%;
  }

  ._2jIm8 ._10_K9 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
    cursor: pointer;
  }

  ._2jIm8 ._10_K9 span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #696969;
    margin-left: 9px
  }

  ._2jIm8 {
    display: block;
  }

  ._2jIm8 ._3vJ5i {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    width: 167px;
    height: 77px;
    justify-content: center;
    display: flex;
    align-items: center;
  }


  ._2jIm8 ._10_K9 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
    cursor: pointer;
  }

  ._2jIm8 ._10_K9 span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #696969;
    margin-left: 9px
  }


  .FUwP1 {
    position: relative;
    flex: 1;
    margin-right: 212px;
  }

  ._2nZPz ._3jl7Q {
    position: relative;
    overflow: hidden;
  }
}

@media (max-width: 1023px) {
  ._2jIm8 {
    display: block;
  }

  ._2jIm8 a {
    width: 100px;
    height: 46px;
    background: #ffffff;
    display: flex;
    align-items: center;
  }

  ._2jIm8 .DAaFJ img {
    width: 100%;
    display: flex;
    height: 100%;
  }

  ._2jIm8 ._10_K9 {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 46px;
    background: #FFFFFF;
    margin-left: 10px;
  }

  ._2jIm8 ._10_K9 img {
    width: 14px;
    height: 14px;
  }

  ._2jIm8 ._10_K9 span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    margin-left: 9px;
    font-size: 13px;
    line-height: 16px;
    color: #0B0B0B;
  }

  ._2nZPz {
    flex-direction: column-reverse;
  }
  
  ._2jIm8 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .FUwP1 {
    position: relative;
    max-width: 772px;
  }

  ._2nZPz ._3jl7Q {
    position: relative;
    overflow: hidden;
  }
}
