._3MNd9 {
  background: #FFFFFF;
  border: 1px solid #777777;
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  padding: 15px 19px;
  color: #A9A9A9;
}

._3MNd9:focus {
  background: #FBFBFB;
  text-decoration: none;
  outline: none;
  color: #858585;
}

._3MNd9:-webkit-autofill:active {
  background: transparent;
}

._1sLTI {
  position: relative;
  display: flex;
}

._1sLTI img {
  position: absolute;
  top: 12px;
  right: 36px;
  height: 24px;
  cursor: pointer;
}


