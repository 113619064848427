
._2RwJH {
    margin: 15px 12px 13px 14px;
    display: flex;
    max-height: 72px;
}

._1Ml4r {
    width: 385px;
    height: 72px;
    background: #FAFAFA;
    border: 1px solid #D8D8D8;
    box-sizing: border-box;
    border-radius: 3px;
    margin-bottom: 29px;
}

._1Ml4r div {
    white-space: nowrap;
    justify-content: space-between;
}

._2RwJH img {
    margin: 0px 19px 0px 0px;
}

._2RwJH ._2d_IW {
    font-family: "TT Norms", sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #696969;
    margin-bottom: 5px;
}

._2RwJH ._2F9N9 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
}

._2RwJH ._3quie {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #696969;
    margin-bottom: 11px;
}

._2RwJH ._1Chrk {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #696969;
    text-align: right;
}