.RWn_a {
    background: #FAFAFA;
    width: 605px;
    height: 507px;
    margin-right: 48px;
    display: flex;
}

.RWn_a ._3P_I0 {
    display: inline-block;
    padding-top: 20px;
}

.RWn_a ._1IGnj {
    width: 85px;
    height: 85px;
    margin-left: 40px;
    cursor: pointer;
}

.RWn_a ._19AGE {
    width: 53px;
    height: 3px;
    background-color: #EF394F;
    border-radius: 5px;
    margin-top: 6px;
    margin-bottom: 0;
    margin-left: 55px;
}

.RWn_a ._2fjOp {
    display: none;
    height: 100%;
}

.RWn_a ._2fjOp.sFWlP {
    display: flex;
    position: relative;
    overflow: hidden;
    padding: 46px 40px;
}