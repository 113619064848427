._2ZGgZ {
  display: flex;
  flex-direction: column;
  background: #FAFAFA;
  border-radius: 3px;
  width: 165px;
  height: 165px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

._2ZGgZ:hover {
  background: #F0F0F0;;
}

._2ZGgZ div {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-align: center;
  margin-top: 25px;
}

@media(max-width: 768px) {
  ._2ZGgZ {
    width: 99px;
    height: 98px;
  }
  ._2ZGgZ div {
    font-size: 11px;
    line-height: 17px;
    color: #000000;
    text-align: center;
    margin-top: 5px;
  }
}
