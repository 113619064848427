.OX6I5 {
  display: block;
}

._3VdSj {
  background-color: #fafafa;
}

._3VdSj > div:first-child {
  max-width: 1440px;
  margin: auto;
  padding: 16px 120px 18px 120px;
  position: relative;
}

._3VdSj a {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

._3VdSj span {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

/*.carousel {*/
/*  display: -webkit-box;*/
/*  max-width: 1440px;*/
/*  margin: auto;*/
/*}*/

._1vwOP {
  margin-bottom: 50px;
}

._1vwOP ._2UC8L {
  padding-right: calc((100% - 1200px) / 2);

}

._1vwOP:nth-child(2n) {

}
