@media(min-width: 1024px) {
    ._3mb07 {
        border: 1px dashed #D6D6D6;
        box-sizing: border-box;
        border-radius: 2px;
        width: 330px;
        height: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    ._3mb07 div {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
    }
}

@media(max-width: 1023px) {
    ._3mb07 {
        border: 1px solid #D6D6D6;
        box-sizing: border-box;
        border-radius: 2px;
        text-align: center;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 14px 21px;
    }

    ._3mb07 div {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        color: #000000;
    }
}
