.bqZoE img {
  width: 100%;
}

.bqZoE {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 375px;
  height: 415px;
}

@media(max-width: 1023px) {
  .bqZoE {
    width: 100%;
    height: 100%;
  }

  ._5UDjG {
    display: none;
  }
}
