._2jnwz {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #696969;
  flex-basis: 100%;
  margin: 12px 0 0;
}

._3leIq input:disabled {
  cursor: not-allowed;
}

._1xJAF {
  margin: 36px 0 0;
}

._1Te_k input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  border: 0;
  border-radius: 50%;
  outline: none;
}

._3DG9c {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 4px solid #FFFFFF;
  transition: .2s ease-in-out;
}

._3DG9c:hover {
  opacity: .7;
}

._3DG9c.active {
  box-shadow: 0 0 10px rgba(0,0,0,.35);
}

._1Te_k {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  grid-column-gap: 8px;
  -moz-column-gap: 8px;
       column-gap: 8px;
  grid-row-gap: 8px;
  row-gap: 8px;
  margin-left: -4px;
}

@media (max-width: 768px) {
  ._2jnwz {
    font-size: 13px;
  }

  ._1Te_k {
    margin-top: -12px;
    grid-column-gap: 10px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  ._3DG9c {
    width: 30px;
    height: 30px;
    border-width: 3px;
  }

  ._3DG9c.active {
    box-shadow: 0 0 6px rgba(0,0,0,.35);
  }
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    ._3DG9c {
      margin-right: 1rem;
    }
  }
}
