@media(min-width: 1024px) {
  ._1375N {
    margin: 47px auto 40px;
    max-width: 1440px;
    box-sizing: border-box;
    display: block;
  }

  ._3tQuL {
    display: none;
    justify-content: center;
  }
  ._3tQuL.active {
    display: flex;
  }

  ._3JnHf {
    display: flex;
    margin: 72px 0 69px 0;
    justify-content: center;
  }

  ._1npdD span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #939393;
  }

  ._1npdD.active span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  ._1npdD {
    padding: 8px 0;
    width: 254px;
    height: 40px;
    background: transparent;
    border-radius: 3px;
    text-align: center;
    margin-right: 20px;
    cursor: pointer;
  }

  ._1npdD.active {
    background-color: #F5F5F5;
  }


  ._3Jjyu span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  ._2FjBm span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #939393;
  }

  ._1lA3M {
    padding: 0 120px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 80px;
    grid-row-gap: 40px;
  }

  ._2gKSA {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #F5F5F5;
    border-radius: 3px;
    width: 210px;
    height: 50px;
    padding: 10px 10px;
  }

  ._2gKSA a {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
    text-decoration: none;
  }
}

@media(max-width: 1023px) {
  ._1npdD {
    display: flex;
    margin: 21px 0 25px 0;
    justify-content: center;
  }

  ._1npdD > span {
    font-family: "TT Norms", sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }

  ._1npdD div {
    width: 144px;
    height: 35px;
    background: #F5F5F5;
    border-radius: 3px;
    margin-right: 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  ._1npdD ._2FjBm {
    background-color: transparent;
  }


  ._3Jjyu span {
    font-family: "TT Norms", sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }

  ._2FjBm span {
    font-family: "TT Norms", sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #939393;
  }
}

