._3FbXY {
  display: none;
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 680px;
  width: 680px;
  background-image: url(/assets/src/components/ChristmasOfferPopIn/benlux-offre-noel-pop-up-bg.jpg), linear-gradient(180deg, #FFC9B1 0%, #FFE9D1 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #ffcdb4;
  border-radius: 20px;
  color: #FFF;
  padding: 36px 32px;
}

._3FbXY.visible {
  display: block;
}

._3FbXY img {
  display: block;
  margin: 8px auto;
  height: 160px;
}

._2Z5H6 {
  font-weight: 700;
  font-size: 29px;
  line-height: 27px;
  text-align: center;

  letter-spacing: 0.07em;
  text-transform: uppercase;
  text-shadow: 0px 0px 20px #FFFFFF;
}

._3ycy8 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 11px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.06em;
}

._2e4t0 {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 50%;
  cursor: pointer;
}

._2e4t0:before, ._2e4t0:after {
  position: absolute;
  left: 14px;
  top: 5px;
  content: " ";
  height: 20px;
  width: 2px;
  background-color: #000;
  opacity: .7;
  transition: all .2s ease-in-out;
  border-radius: 2px;
}

._2e4t0:before {
  transform: rotate(45deg);
}
._2e4t0:after {
  transform: rotate(-45deg);
}

._2e4t0:hover::before,
._2e4t0:hover::after {
  opacity: 1;
}

.mT9hL {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  display: none;
  opacity: 0.5;
  background-color: #000;
}

._2speX {
  text-align: center;
  display: inline-block;
  width: 354px;
}

.EMq1N {
  margin-bottom: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.mT9hL.visible {
  display: block;
}

@media(max-width: 768px) {
  ._2e4t0 {
    top: -8px;
    right: -8px;
  }

  .EMq1N {
    margin: 0 0 24px;
  }

  ._3FbXY {
    height: auto;
    text-align: center;
    max-width: 90%;
    padding: 30px 20px;
    background: linear-gradient(180deg, #FFBAB5 0%, #D38585 100%);
  }

  ._3FbXY img {
    width: 100%;
    height: auto;
  }

  ._2speX {
    width: unset;
  }
}
