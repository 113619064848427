._2VBGM._3D82v span {
  font-weight: bold;
}

@media(min-width: 1024px) {

    ._165mr {
        margin: 47px auto 40px;
        max-width: 1440px;
        box-sizing: border-box;
        padding: 0 120px;
        display: flex;
    }

    ._2Al0- {
        display: block;
        margin-right: 60px;
    }

    ._2VBGM {
        display: flex;
        background: #FAFAFA;
        width: 269px;
        height: 48px;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        border-bottom: 1px solid #E6E6E6;
        padding-left: 20px;
    }

    .r6qI7 {
        border-bottom: none;
        margin-bottom: 22px;
    }

    ._2VBGM span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
    }

    ._2VBGM img {
        width: 26px;
        height: 26px;
        margin-right: 20px;
    }

    ._1KiDD {
        width: 100%;
    }
    .X2Npt {
        display: none;
    }
}

@media(max-width: 1023px) {

    ._165mr {
        box-sizing: border-box;
        display: flex;
        max-width: 100vw;
        overflow: hidden;
        position: relative;
    }

    ._2Al0- {
        display: block;
        width: 100%;
    }

    ._2VBGM {
        display: flex;
        background: #FAFAFA;
        width: 100%;
        height: 48px;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        border-bottom: 1px solid #E6E6E6;
        padding-left: 20px;
    }

    .r6qI7 {
        border-bottom: none;
        margin-bottom: 22px;
    }

    ._2VBGM span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
    }

    ._2VBGM img {
        width: 26px;
        height: 26px;
        margin-right: 20px;
    }

    ._1KiDD {
        left: 100%;
        position: absolute;
        background-color: #ffffff;
        transition: all .3s ease-in-out;
        width: 100%;
        height: 100%;
        padding: 0 21px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    ._1KiDD.active {
        transform: translateX(-100%);
        display: block;
    }

    ._1KiDD.disable {
        display: none;
    }

    ._2kwin {
        width: 100%;
        position: relative;
    }

    ._2bzP- {
        display: flex;
        position: absolute;
        justify-content: space-around;
        width: 100%;
        top: 16%;
        height: 48px;
        align-items: center;
    }

    ._2bzP- img {
        display: none;
    }

    ._2bzP-.active img {
        display: flex;
    }

    ._2bzP- div {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 22px;
        color: #FFFFFF;
        text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    }

    .X2Npt {
        position: relative;
    }
}
