.dyayQ progress {
  width: 225px;
  height: 8px;
  margin-right: 10px;
}

.dyayQ progress {
  -webkit-appearance: none;
}

.dyayQ progress::-webkit-progress-value {
  background-color:#D2D2D2;
}

.dyayQ progress::-webkit-progress-bar {
  background-color: #E9E9E9;
}

._1l63I span {
  font-family: serif;
  font-family: initial;
}

._1l63I div {
  outline: none;
}

.dyayQ ._30kFX {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #B0B0B0;
}

._1fpU8 {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

._1fpU8 .wDXuP {
  font-family: "TT Norms", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  color: #000000;
  margin-right: 8px;
}

._2KeYx .EcF_J {
  display: flex;
  justify-content: flex-end;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
}

._2KeYx p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #696969;
}

@media(min-width: 1024px) {
  ._2xl1c {
    display: flex;
  }

  ._2xl1c ._1BSez {
    display: block;
  }

  ._2xl1c ._1BSez .dyayQ {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
  }

  ._2xl1c ._1BSez img {
    margin: 0 7px 0 6px;
  }

  ._2xl1c ._1BSez .dyayQ .ovetc {
    width: 225px;
    height: 8px;
    background: #E9E9E9;
    margin: 0;
  }
  ._2xl1c ._1BSez .zI8VD {
    font-family: 'Apercu Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }


  ._2xl1c .ShE-H {
    display: block;
    margin-left: 370px;
    width: 100%;
    height: 264px;
    overflow: hidden;
  }

  .Dsg91 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .Dsg91 div {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }



  [dir="ltr"] ._2xl1c .ShE-H .EcF_J {
    text-align: right;
  }



  [dir="rtl"] ._2xl1c .ShE-H .EcF_J {
    text-align: left;
  }

  ._2xl1c .ShE-H ._2uAu7 {
    width: 489px;
    border: 1px solid #CCCCCC;
    margin: 0;
  }

  ._2xl1c ._2a7Ag {
    background: #FAFAFA;
    bottom: 2%;
    right: 45%;
    z-index: 100;
    text-align: center;
    position: absolute;
  }

  ._3Xryk {
    display: flex;
    margin-bottom: 10px
  }

  ._3Xryk div {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
    margin-right: 40px;
  }

  ._1l63I {
    display: block;
  }

  ._1l63I .ojSxd {
    display: flex;
    margin-bottom: 13px;
    margin-top: 20px;
  }

  .ojSxd input:first-of-type {
    width: 349px;
    margin-right: 19px;
  }

  .ojSxd input {
    width: 100%;
    height: 47px;
  }


  .ojSxd input {
    background: #FFFFFF;
    border: 1px solid #777777;
    box-sizing: border-box;
    border-radius: 3px;
    padding-left: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #A9A9A9;
    outline: none;
  }

  ._1l63I textarea {
    background: #FFFFFF;
    border: 1px solid #777777;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #A9A9A9;
    width: 100%;
    height: 122px;
    margin-bottom: 53px;
    padding: 15px 20px;
    outline: none;
  }

  ._1l63I ._24zxx {
    display: flex;
    justify-content: center;
  }

  ._2abYc._2i4Mf {
    background-color: #000000;
    color: #FFFFFF;
    margin-left: 41px;
  }

  ._2abYc {
    display: block;
    width: 244px;
    height: 48px;
    border: 1px solid #000000;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: transparent;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    outline: none;
    cursor: pointer;
  }

  ._24zxx button:last-child {
    margin-left: 41px;
  }
}

@media(max-width: 768px) {

  ._2xl1c ._2a7Ag {
    background: #FAFAFA;
    bottom: 2%;
    right: 18%;
    z-index: 100;
    text-align: center;
    position: absolute;
  }

  ._3Xryk div {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  ._1l63I {
    display: block;
  }

  ._1l63I ._34pd8 {
    width: 100%;
    height: 47px;
    margin-bottom: 12px;
  }

  ._1l63I ._3Dj0a {
    width: 100%;
    height: 47px;
    margin-bottom: 12px;
  }

  ._1l63I .ojSxd {
    display: block;
    margin-bottom: 12px;
  }


  .ojSxd input:first-of-type {
    margin-bottom: 12px;
  }



  .ojSxd input {
    background: #FFFFFF;
    border: 1px solid #777777;
    box-sizing: border-box;
    border-radius: 3px;
    padding-left: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    color: #A9A9A9;
    outline: none;
  }

  ._1l63I textarea {
    background: #FFFFFF;
    border: 1px solid #777777;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 13px 15px 0 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #A9A9A9;
    width: 100%;
    height: 218px;
    margin-bottom: 34px;
  }

  ._1l63I ._24zxx {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }

  ._24zxx button:last-child {
    margin: 0 0 15px 0;
  }

  ._1l63I span {
    margin: 12px 0 19px 0;
  }
}



