._2VwFz {
  background-size: cover;
  background-image: url(/assets/src/assets/newsletter.jpg);
}

._3eQrR {
  padding: 12px 21px;
  margin: auto;
}

._3c13- span {
  display: block;
}

._3c13- span:first-child {
  font-family: 'TT Norms' ,sans-serif;
  font-weight: 700;
  letter-spacing: .03em;
  text-transform: uppercase;
  color: #fff;
  font-size: 2rem;
  line-height: 2rem;
}

._3c13- span:last-child {
  font-family: Inter,sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: #fff;
}

._3eQrR form {
  width: 100%;
  max-width: 420px;
  height: 46px;
  border-radius: 3px;
  margin: 14px 0 13px 0;
  display: inline-flex;
  position: relative;
  vertical-align: top;
}

._3eQrR form > div {
  width: 100%;
}

._3eQrR button {
  background-color: transparent;
  border-width: 0;
  position: absolute;
  right: 10px;
  top: 0;
  height: 46px;
  line-height: 46px;
  outline: none;
}

._3eQrR input {
  width: 100%;
  border-width: 0;
  outline: none;
}

._3eQrR img {
  position: relative;
  width: 40px;
  cursor: pointer;
  margin: 0;
  display: flex;
}

@media (min-width: 1024px) {
  ._3eQrR {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    padding: 0 70px;
  }

  ._3c13- {
    display: flex;
    align-items: center;
  }

  ._3c13- span:first-child {
    margin-right: 48px;
  }
}
