.i4DBn {
}

._1DuaP {
  cursor: pointer;
  padding: 6px 0;
  display: flex;
  margin-left: 24px;
  transition: opacity .2s ease-in-out;
}

._1DuaP:hover {
  opacity: .7;
}

._1DuaP img {
  height: 28px;
}

._1xQqZ {
  display: none;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  height: 100%;
  /* top: 60px; */
  top: 117px;
  margin: auto;
  z-index: 780;
  left: 0;
}

._1xQqZ.active {
  display: block;
}

.GekId ul {
  display: grid;
  grid-template-columns: 1fr 600px 1fr;
  grid-column-gap: 60px;
}

._3A7Ls {
  display: none;
}

._3A7Ls.active {
  display: flex;
  align-items: center;
}

.XpY_i {
  display: flex;
  align-items: center;
}

.XpY_i input {
  display: flex;
  flex: auto;
  outline: none;
  background-color: #E8E8E8;
  height: 36px;
  width: 340px;
  padding: 0 16px;
  border-width: 0;
  border-radius: 16px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #000000;
}

.XpY_i input:target .GekId {
  display: grid;
}

._23VGt h2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  margin: 0 0 12px;
}

._23VGt ._3ww37 {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: max-content;
  grid-row-gap: 8px;
  display: grid;
  grid-column-gap: 25px;
  margin-bottom: 20px;
  list-style: none;
}

._23VGt ._3B6ru {
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: max-content;
  grid-row-gap: 8px;
  display: grid;
  margin-bottom: 24px;
}

._3dpnT {
  grid-row-gap: 12px !important;
}

._3a9-h {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
  text-decoration: none;
}

._3a9-h:hover {
  color: #EF394F;
}

@media (min-width: 1024px) {
  .GekId {
    position: absolute;
    background: #FFFFFF;
    margin: auto;
    width: 1440px;
    top: 100%;
    z-index: 799;
    left: 0;
    right: 0;
    padding: 20px 45px 34px;
  }

  .XpY_i input {
    -webkit-appearance: none;
  }

  ._23VGt {
    list-style: none;
  }

  ._1UB2z {
    display: none;
  }

  .Yd0fl {
    text-align: center;
  }

  .Yd0fl a {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #000000;
  }

  .XpY_i input {
    height: 36px;
    width: 340px;
    padding: 0 16px;
  }

  .BJvbr {
    height: 28px;
    cursor: pointer;
  }

  .quinU {
    display: none;
  }

  ._35tsh {
    height: 28px;
    margin-left: 30px;
  }
}

@media (max-width: 1023px) {
  ._1UB2z {
    width: 334px;
    height: 1px;
    background: #E8E8E8;
    display: none;
  }

  ._1UB2z.active {
    display: flex;
  }

  .XpY_i.active {
    padding: 16px 21px 0 21px;

  }

  .XpY_i input {
    /*display: none;*/
    opacity: 0;
    border: 0;
    width: 100%;
    outline: none;
  }

  .XpY_i.active input {
    display: flex;
    opacity: 1;
  }

  ._23VGt {
    list-style: none;
    padding-bottom: 16px;
  }

  ._23VGt h2 {
    font-family: "TT Norms", sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  ._3a9-h {
    font-size: 13px;
    line-height: 16px;
  }

  .Yd0fl a {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-decoration: none;
    color: #000000;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
    text-align: center;
    background: #F7F7F7;
    border-radius: 3px;
  }

  .quinU {
    display: none;
    margin: 14px;
    z-index: 999;
  }

  .i4DBn.active .quinU {
    display: flex;
  }

  .quinU img {
    width: 14px;
    height: 14px;
  }
  .BJvbr {
    height: 26px;
    margin-right: 20px;
  }

  ._1xQqZ.active {
    display: none;
  }

  .GekId {
    box-sizing: border-box;
    grid-template-columns: 1fr;
    grid-column-gap: 60px;
    background: #FFFFFF;
    margin: auto;
    z-index: 9999;
    min-height: 100%;
    padding: 16px 21px;
    justify-content: space-between;
  }

  .GekId ul {
    display: block;
  }

  .i4DBn  {
    transform: translateX(-100%);
    display: contents;
  }

  ._19Use.active {
    display: block;
    z-index: 999;
    height: 100%;
    background: #FFFFFF;
    overflow: scroll;
    overflow-x: hidden;
  }

  .i4DBn.active {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(0%);
    z-index: 999;
    height: 100%;
    transition: all .3s ease-in-out;
    background: #FFFFFF;
    overflow: scroll;
    width: 100%;

  }

  .GekId.active {
    display: block;
  }

  .i4DBn::before {
    position: fixed;
    opacity: 0;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    z-index: 998;
    transform: translateX(-100%);
    transition: all .3s ease-in-out;
    visibility: hidden;
  }

  .i4DBn.active::before {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }

  ._23VGt ._3ww37 {
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    grid-row-gap: 8px;
    display: grid;
    grid-column-gap: 25px;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .BJvbr {
    width: 26px;
    height: 26px;
    margin-top: 13px;
    margin-bottom: 13px;
    justify-self: center;
    flex: auto;
    margin-right: 16px;
  }
}
