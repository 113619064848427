._3wX1D ._39GaW:checked {
  border-width: 1px;
  border-style: solid;
  border-color: black;
  border-radius: 3px;
}

@media (min-width: 1024px) {
  ._22fon {
    background: #FFFFFF;
    box-sizing: border-box;
    min-width: 117px;
    height: 47px;
    display: flex;
    align-items: center;
    margin-right: 27px;
    cursor: pointer;
    position: relative;
    justify-content: center;
    padding: 4px 8px;
    text-align: center;
    margin: 0 12px 12px 0;
    padding: 4px 8px;
  }

  ._22fon ._39GaW {
    border: 1px solid #D5D5D5;
    -webkit-appearance: none;
  }

  ._22fon ._39GaW:not(:checked):hover {
    border-color: #000000;
  }

  ._22fon input:disabled {
    cursor: not-allowed;
  }

  ._22fon img {
    margin: 0 6px 0 9px;
    width: 34px;
    height: 34px;
  }

  ._22fon span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._3wX1D ._39GaW {
    width: 100%;
    height: 100%;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    margin: 0;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    transition: border .2s ease-in-out;
  }

  ._3wX1D ._3F-Y_ {
    display: block;
  }

  ._3XWv1 {
    display: flex;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .mcdhz {
    margin-top: 24px;
    display: flex;
    flex-flow: wrap;
  }
}

@media (max-width: 768px) {
  .mcdhz {
    margin-top: 10px;
    display: flex;
    flex-flow: wrap;
  }

  ._22fon {
    background: #FFFFFF;
    min-width: 96px;
    height: 42px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    justify-content: center;
    transition: 0.3s;
    margin: 0 12px 12px 0;
    padding: 4px 8px;
    text-align: center;
  }

  ._22fon input:disabled {
    cursor: not-allowed;
  }

  ._22fon img {
    margin: 0 12px 0 9px;
    width: 26px;
    height: 26px;
  }

  ._22fon span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._3wX1D ._39GaW {
    width: 100%;
    height: 100%;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    margin: 0;
    outline: none;
    border-radius: 3px;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
  }

  ._3wX1D ._3F-Y_ {
    display: block;
  }

  ._3XWv1 {
    display: flex;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    ._22fon {
      margin-right: 1rem;
    }
  }
}
