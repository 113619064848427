@media(min-width: 1024px) {

  ._19TTD span {
    margin-right: 10px;
    margin-left: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
  }
  ._19TTD {
    height: 55px;
    display: flex;
    align-items: center;
    background: #FAFAFA;
    cursor: pointer;
    padding-right: 10px;
  }

  ._19TTD.active {
    background: #F3F3F3;
  }

  ._19TTD.active img {
    transform: rotate(-180deg);
  }


  .K1iwz {
    display: flex;
    flex-direction: column;
    list-style: none;
    overflow: scroll;
    padding: 0;
    margin: 0;
    height: 100%;
  }

  ._106mk.active {
    display: block;
  }

  ._106mk {
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
    background: #FFFFFF;
    border-radius: 3px;
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-bottom: 8px;
  }

  ._106mk.active {
    background: #000000;
    border-radius: 3px;
    color: #ffffff;
    display: flex;
    align-items: center;
  }

  ._106mk:hover {
    font-weight: bold;
  }

  ._3mhmH {
    display: flex;
    position: absolute;
    right: 8%;
    top: 9%;
    margin-right: 10px;
  }

  ._2AECr {
    display: block;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 339px;
    height: 44px;
    margin: 5px 0 13px 0;
    padding-left: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    outline: none;
  }

  ._34AKv {
    position: absolute;
    display: none;
    z-index: 998;
    background: #FAFAFA;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    left: 56%;
    width: 382px;
    height: 312px;
    padding: 15px 0 15px 15px;
  }

  ._34AKv.active {
    display: block;
  }
}

@media(max-width: 1023px) {

  .K1iwz {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 18px;
    grid-row-gap: 6px;
    white-space: nowrap;
  }

  ._106mk {
    outline: none;
  }

  ._106mk li{
    margin-bottom: 10px;
  }

  ._106mk a {
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._106mk a:hover {
    color: #EF394F;
  }

  ._19J8k {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin-top: 16px;
  }

  ._3mhmH {
    display: flex;
    position: absolute;
    right: 0;
    top: 14%;
    margin-right: 10px;
  }

  ._2AECr {
    display: block;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 339px;
    height: 44px;
    margin: 5px 0 13px 15px;
    padding-left: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    outline: none;
  }

  ._34AKv {
    position: absolute;
    top: 120%;
    display: none;
    z-index: 998;
    background: #FAFAFA;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    width: 313px;
    height: 216px;
    overflow: auto;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  ._34AKv.active {
    display: block;
  }
}

