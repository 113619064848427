@media(min-width: 1024px) {
    ._2YUoy {
        margin: 15px 12px 13px 14px;
        display: flex;
        max-height: 72px;
    }

    ._7JVL8 {
        width: 385px;
        height: 72px;
        background: #FAFAFA;
        border: 1px solid #D8D8D8;
        box-sizing: border-box;
        border-radius: 3px;
        margin-bottom: 29px;
    }

    ._7JVL8 div {
        white-space: nowrap;
        justify-content: space-between;
    }

    ._2YUoy img {
        margin: 0px 19px 0px 0px;
    }

    ._2YUoy ._3L1Yf {
        font-family: "TT Norms", sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #696969;
        margin-bottom: 5px;
    }

    ._2YUoy ._2_iX5 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #000000;
    }

    ._2YUoy ._2ig2i {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #696969;
        margin-bottom: 11px;
    }

    ._2YUoy ._1NLiK {
        font-family: "Circular Std", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #696969;
        text-align: right;
    }
}

@media(max-width: 768px) {
    ._2YUoy {
        margin: 15px 12px 13px 14px;
        display: flex;
        max-height: 72px;
    }

    ._7JVL8 {
        width: 315px;
        height: 63px;
        background: #FAFAFA;
        border: 1px solid #D8D8D8;
        box-sizing: border-box;
        border-radius: 3px;
        margin-bottom: 29px;
    }

    ._7JVL8 div {
        white-space: nowrap;
        justify-content: space-between;
    }

    ._2YUoy img {
        margin: 0px 11px 0px 0px;
    }

    ._2YUoy ._3L1Yf {
        font-family: "TT Norms", sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
        margin-bottom: 1px;
    }

    ._2YUoy ._2_iX5 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        color: #000000;
    }

    ._2YUoy ._2ig2i {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 14px;
        color: #696969;
        margin-bottom: 3px;
    }

    ._2YUoy ._1NLiK {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #696969;
        text-align: right;
    }
}

