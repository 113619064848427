.eEPab {
  width: 20px;
  height: 20px;
  border: 1px solid #d8d8d8;
  background: #696969;
  border-radius: 50%;
  outline: none;
  z-index: 2;
  box-shadow: 0 2px 2px #dbdbdb;
  margin-left: -12px;
  top: -5px;
}

.eEPab:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #dadfe8;

}

.eEPab span {
  position: absolute;
  top: -100%;
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #696969;
  left: -50%;
  margin: 0;
}
