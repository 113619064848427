.hD38V {
  max-width: 1440px;
  margin: 47px auto 40px;
  padding: 0 120px;
}

.hD38V a:hover {
  color: #EF394F;
}


._2cP7m {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 30px;
}

.FVdBA {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
  text-decoration: none;
}

._3qdrV {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  margin-top: 20px;
}

._25clG {
  display: flex;
  grid-column-gap: 100px;
  -moz-column-gap: 100px;
       column-gap: 100px;
}

._3ctcp {
  -moz-column-count: 3;
       column-count: 3;
  width: 100%;
}

._3ctcp li {
  -moz-column-break-inside: avoid;
       break-inside: avoid-column;
  margin-bottom: 10px;
}

._2OZqI {
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  font-family: "TT Norms",sans-serif;
  font-size: 18px;
  color: #000;
  padding: 0 20px 0 0;
}


.DhI4L span {
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  font-family: "TT Norms",sans-serif;
  font-size: 30px;
  color: #000;
  padding: 0 20px 0 0;
  position: relative;
  display: inline-block;
  z-index: 2;
  background: #fff;
}

a.DhI4L:hover {
  cursor: pointer;
}

a.DhI4L:hover span {
  color: #EF394F;
}

._1hIvK span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 29px;
  line-height: 35px;
  color: #696969;
}

._1hIvK p a {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #696969;
  display: inline;
  display: initial;
  margin: 0;
  text-decoration: underline;
}

._1hIvK p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-top: 16px;
  color: #696969;
}

._1hIvK img {
  width: 20px;
  height: 20px;
  margin-right: 13px;
}

._1hIvK a {
  display: block;
  font-family: "Inter", sans-serif;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #696969;
  cursor: pointer;
  text-decoration: none;
}

._1hIvK {
  white-space: normal;
  white-space: initial;
}

._1hIvK a:hover {
  color: #EF394F;
}

.DhI4L {
  display: flex;
  position: relative;
  z-index: 1;
  text-decoration: none;
  margin: 60px 0 30px 0;
  cursor: auto;
  cursor: initial;
}

.DhI4L.rCdSa {
  margin: 0px 0 30px 0;
}


.DhI4L:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 1;
  background: #d1d1d1;
  content: "";
}

._3WoaS {
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 17px;
  font-family: "TT Norms",sans-serif;
  font-size: 18px;
  color: #000;
  outline: none;
  white-space: nowrap;
}

._3mEiY {
  font-family: "Inter",sans-serif;
  text-decoration: none;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: .875rem;
  line-height: 1.25rem;
  color: #696969;
  outline: none;
  white-space: nowrap;
}

._1-Y5c {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 50px;
}

.FVdBA._1z4Rf {
  color: #EF394F;
}

@media(max-width: 768px) {
  .hD38V {
    max-width: 1440px;
    margin: 47px auto 40px;
    padding: 0 20px;
  }



  ._2cP7m {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 80px;
    grid-row-gap: 10px;
  }

  ._3qdrV {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 30px;
    margin-top: 20px;
  }

  ._25clG {
    display: block;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  ._3ctcp {
    -moz-column-count: 1;
         column-count: 1;
    width: 100%;
  }

  .DhI4L span {
    font-size: 24px;
  }

  .DhI4L {
    margin: 30px 0 30px 0;
  }
}
