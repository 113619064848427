.YsI5R {
  width: 44px;
  height: 44px;
  background: #ef394f;
  border-radius: 50%;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
}

._3gVft {
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 14px;
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}

._3gVft,
._2AKG5 {
  font-size: 14px;
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
}

._3w3mx {
  background-image: url(/assets/src/components/Card/green-beauty.png);
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-size: contain;
}

._2AKG5 {
  z-index: 1;
  padding-left: 12px;
  position: absolute;
  top: 12px;
  right: 14px;
}

._2AKG5::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  background: #ef394f;
  border-radius: 50%;
}

._1WLS0 {
  position: relative;
}

._2XbuD .iiz {
      margin: 0;
      position: relative;
      overflow: hidden;
      display: inline-block;
      cursor: zoom-in;
    }

._2XbuD .iiz--drag .iiz__zoom-img--visible {
      cursor: grab;
    }

._2XbuD .iiz__img {
      max-width: 100%;
      height: auto;
      display: block;
      pointer-events: none;
      visibility: visible;
      opacity: 1;
    }

._2XbuD .iiz__img--invisible {
      visibility: hidden;
      opacity: 0;
    }

._2XbuD .iiz__zoom-img {
      width: auto !important;
      max-width: none !important;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      display: block;
    }

._2XbuD .iiz__zoom-img--visible {
      visibility: visible;
      opacity: 1;
      cursor: zoom-out;
    }

._2XbuD .iiz__zoom-portal {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10000;
    }

._2XbuD .iiz__close {
      top: 10px;
      right: 10px;
      visibility: hidden;
      opacity: 0;
    }

._2XbuD .iiz__close--visible {
      visibility: visible;
      opacity: 1;
    }

._2XbuD .iiz__close::before {
      content: " ";
      width: 29px;
      height: 29px;
      background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
      background-size: 100% 1px, 1px 100%;
      transform: rotate(45deg);
    }

._2XbuD .react-multi-carousel-list {
      display: flex;
      overflow: hidden;
      position: relative;
    }

._2XbuD .react-multi-carousel-track {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      position: relative;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      will-change: transform, transition;
    }

._2XbuD .react-multiple-carousel__arrow {
      position: absolute;
      outline: 0;
      transition: all 0.5s;
      border-radius: 35px;
      z-index: 1000;
      border: 0;
      background: rgba(0, 0, 0, 0.5);
      min-width: 43px;
      min-height: 43px;
      opacity: 1;
      cursor: pointer;
    }

._2XbuD .react-multiple-carousel__arrow:hover {
      background: rgba(0, 0, 0, 0.8);
    }

._2XbuD .react-multiple-carousel__arrow::before {
      font-size: 20px;
      color: #fff;
      display: block;
      font-family: revicons;
      text-align: center;
      z-index: 2;
      position: relative;
    }

._2XbuD .react-multiple-carousel__arrow--left {
      left: calc(4% + 1px);
    }

._2XbuD .react-multiple-carousel__arrow--left::before {
      content: "\E824";
    }

._2XbuD .react-multiple-carousel__arrow--right {
      right: calc(4% + 1px);
    }

._2XbuD .react-multiple-carousel__arrow--right::before {
      content: "\E825";
    }

._2XbuD .react-multi-carousel-dot-list {
      position: absolute;
      bottom: 0;
      display: flex;
      left: 0;
      right: 0;
      justify-content: center;
      margin: auto;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
    }

._2XbuD .react-multi-carousel-dot button {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      opacity: 1;
      padding: 5px 5px 5px 5px;
      box-shadow: none;
      transition: background 0.5s;
      border-width: 1px;
      border-style: solid;
      border-color: #cccccc;
      padding: 0;
      margin: 0;
      margin-right: 6px;
      outline: 0;
      background: transparent;
      cursor: pointer;
    }

._2XbuD .react-multi-carousel-dot button:hover:active {
      background: #080808;
    }

._2XbuD .react-multi-carousel-dot--active button {
      background: #cccccc;
    }

._2XbuD .react-multi-carousel-item {
      transform-style: preserve-3d;
      backface-visibility: hidden;
    }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      ._2XbuD .react-multi-carousel-item {
        flex-shrink: 0 !important;
      }

      ._2XbuD .react-multi-carousel-track {
        overflow: visible !important;
      }
    }

._2XbuD {
  display: flex;
}

._2nI_6 {
  display: none;
}

._1DL4J {
  background: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
}

._48hsy {
  background: #fafafa;
}

._2nI_6 img {
  height: 100%;
}

._3FhRD,
.IZ5Oc {
  width: 375px;
}

.IZ5Oc img {
  width: 100%;
}

@media (min-width: 1024px) {
  ._1WLS0 {
    margin: 0 126px 0 80px;
  }

  ._2JW3I {
    display: none !important;
  }

  ._2nI_6 {
    display: block;
  }

  ._2nI_6 ._3QpBf:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.04);
    opacity: 0;
    transition: 0.3s ease-in-out;
  }

  ._2nI_6 ._3QpBf.active:before {
    opacity: 1;
  }

  ._2nI_6 ._3QpBf {
    position: relative;
    height: 87px;
    margin: 0 0 26px 0;
    cursor: pointer;
  }

  ._1DL4J {
    width: 375px;
    height: 537px;
  }

  ._1DL4J img {
    width: 100%;
  }

  ._1DL4J._1x8ee {
    display: flex;
  }
}

@media (max-width: 768px) {
  ._2nI_6 ._3QpBf {
    height: 87px;
    margin: 0 0 26px 0;
    cursor: pointer;
  }

  ._3FhRD {
    align-items: center;
  }

  ._3FhRD {
    width: 100vw;
  }

  ._1DL4J img {
    height: 325px !important;
  }

  ._1DL4J._1x8ee {
    display: flex;
  }

  ._28gy3 {
    background: transparent;
    border-width: 0;
    position: absolute;
    right: 20px;
  }
  ._2GD7N {
    background: transparent;
    border-width: 0;
    position: absolute;
    left: 20px;
    transform: rotate(-180deg);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  ._1DL4J {
    margin: 0;
  }
}

._3NSOs {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
}

._3NSOs > div {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
  opacity: 0.7;
  position: relative;
}

._3NSOs > div > i {
  border-width: 9px 0 9px 17px;
  border-color: transparent transparent transparent #202020;
  position: absolute;
  top: 6px;
  border-style: solid;
  left: 8px;
  z-index: 1;
}

._2fGVf {
  width: 44px;
  z-index: 9;
  position: absolute;
  right: 14px;
  top: 12px;
  background-color: #11062b;
  color: #fff;
  font-family: "TT Norms", sans-serif;
  font-size: 10px;
  line-height: 13px;
  padding: 6px 0;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid #524273;
  white-space: normal;
  white-space: initial;
}
