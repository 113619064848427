._34qHk {
  display: none;
}

._34qHk.active {
  display: grid;
}

@media(min-width: 1024px) {
  ._2Qeo8:hover:not(._3BXZt) > .cJI8W {
    display: flex;
  }

  ._2UGOd {
    display: none;
  }

  ._3OROx {
    display: none;
  }

  ._1czpe {
    display: block;
    margin-right: 100px;
  }

  ._3bAUg {
    display: flex;
    width: 360px;
  }

  ._34qHk {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 50px;
    position: absolute;
    right: 45px;
    top: 20px;
    bottom: 34px;
    overflow: auto;
  }

  ._34qHk.active {
    display: grid;
  }

  ._3Tr2j {
    display: flex;
    justify-content: space-between;
  }

  ._3bY7F {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    cursor: pointer;
    margin-bottom: 16px;
  }
  ._3bY7F:hover {
    color: #EF394F;
  }

  ._39FPz:hover {
    color: #EF394F;
  }

  ._1-1HZ:not(._3BXZt) {
    box-sizing: border-box;
    display: none;
    position: absolute;
    background: #FFFFFF;
    margin: auto;
    max-width: 1440px;
    top: 100%;
    width: 100%;
    left: 0;
    right: 0;
    justify-content: space-between;
    padding: 20px 70px 34px;
    box-shadow: 0px 2038px 0px 2038px rgba(0,0,0,0.3);
  }

  ._39FPz {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin-top: 16px;
  }

  .jgr26 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 35px;
    grid-row-gap: 6px;
    white-space: nowrap;
  }

  ._3CjRu.active {
    display: flex;
  }


  ._3CjRu a {
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._72Cok {
    display: inline-flex;
    vertical-align: top;
  }

  ._72Cok ._3FE0U {
    margin-bottom: 12px;
    display: inline-flex;
    width: 107px;
    min-width: 50px;
    margin-right: 40px;

  }

  ._1pIaD {
    display: inline-block;
    margin-left: 50px;
    vertical-align: top;
    margin-top: 30px;
  }

  ._33Mj_ {
    margin-right: 10px;
  }

  ._33Mj_ ._21Gul {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
  }

  ._33Mj_ ul {
    list-style: none;
    padding: 0;
  }

  ._33Mj_ a {
    text-decoration: none;
    color: #696969;
  }

  ._33Mj_ a:hover {
    color: #EF394F;
  }

  ._33Mj_ li {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #696969;
    margin-bottom: 22px;
    cursor: pointer;
    font-size: 13px;
    line-height: 16px;
  }

  ._3CjRu a:hover {
    color: #EF394F;
  }

  ._2UGOd {
    display: none;
  }

  .j2bUd {
    display: none;
  }

  ._1_oGr {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}



@media(max-width: 768px) {
  ._1CVdT {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 18px 19px 22px;
    height: 58px;
  }

  ._3OROx {
    display: block;
  }

  ._2qDeE {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #000000;
  }

  ._2qDeE.highlighted {
    color: #EF394F;
  }

  .PmHhd {
    width: 334px;
    border: 1px solid #E8E8E8;
  }

  ._1-1HZ:not(._3BXZt) {
    box-sizing: border-box;
    display: block;
    background: #FFFFFF;
    margin: auto;
    max-width: 1440px;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .3s ease-in-out;
    right: 45px;
    left: 100%;
    position: absolute;
    z-index: 9;
  }

  ._1-1HZ:not(._3BXZt).active  {
    transform: translateX(-100%);
  }

  .jgr26 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 18px;
    grid-row-gap: 6px;
    white-space: nowrap;
  }


  ._3CjRu {
    outline: none;
  }

  ._3CjRu li{
    margin-bottom: 10px;
  }

  ._3CjRu a {
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._1pIaD {
    display: inline-block;
    margin-left: 50px;
    vertical-align: top;
    margin-top: 30px;
  }



  ._33Mj_ ._21Gul {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
  }

  ._33Mj_ ul {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  ._33Mj_ a {
    text-decoration: none;
    color: #696969;
  }

  ._33Mj_ a:hover {
    color: #EF394F;
  }

  ._33Mj_ li {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #696969;
    margin-bottom: 22px;
    cursor: pointer;
    font-size: 13px;
    line-height: 16px;
  }

  ._3CjRu a:hover {
    color: #EF394F;
  }

  ._39FPz {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin-top: 16px;
  }

  ._3bY7F {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    cursor: pointer;
    margin-bottom: 16px;
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
  }

  ._34qHk {
    display: none;
    grid-template-columns: 1fr;
  }

  ._34qHk.active {
    display: grid;
  }

  ._1czpe {
    display: block;
    padding: 0 21px;
  }

  ._2UGOd {
    transform: rotate(-90deg);
  }
  ._2UGOd.active {
    transform: rotate(90deg);
    transition: all .3s ease-in-out;
  }

  ._1_oGr {
    margin-top: 24px;
  }
}

