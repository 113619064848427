@font-face {
  font-family: "TT Norms";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url(/assets/src/assets/fonts/ttnorms-regular-webfont.woff2) format('woff2'),
       url(/assets/src/assets/fonts/ttnorms-regular-webfont.woff) format('woff');
}

@font-face {
  font-family: "TT Norms";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url(/assets/src/assets/fonts/ttnorms-bold-webfont.woff2) format('woff2'),
       url(/assets/src/assets/fonts/ttnorms-bold-webfont.woff) format('woff');
}

@font-face {
  font-family: "ABChanelCorpo";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url(/assets/src/assets/fonts/abchanel-corpo-bold.woff2) format('woff2');
}

@keyframes _20xiV {
  0% {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
  1.8%,
  48.5% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  50% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}

._3AwTG {
  margin: auto;
}

._1qRCL {
  display: flex;
  max-width: 1440px;
  margin: auto;
  justify-content: space-between;
  position: relative;
  padding: 0 70px;
  height: 60px;
}

._2AeG3 {
  width: 100%;
  z-index: 700;
  background-color: #FFFFFF;
}

._2jKwz {
  margin: 0;
}

._2jKwz a {
  display: flex;
}

._2jKwz img {
  height: 20px;
}

._3xWfL {
  display: none;
}

._2X3M3 {
  display: block;
}

._1BV1S {
  display: none;
}

.kLwfF,
._3HSIy {
  display: flex;
  align-items: center;
}

.kLwfF {
  flex: 1;
}

._2553O,
.t5ttT {
  position: relative;
  height: 36px;
}

._2553O,
.t5ttT {
  padding: 12px 8px;
  text-align: center;
  background: #ef394f;
  color: #fff;
  font-size: .7em;
}

._2553O span,
.t5ttT span {
  margin: 10px auto;
  font-size: 11px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  position: absolute;
  left: 8px;
  top: 0;
  right: 8px;
}

.t5ttT span {
  opacity: 0;
}

._2553O a,
.t5ttT a {
  color: #FFF;
}

._2553O a:hover,
.t5ttT a:hover {
  text-decoration: underline;
}

.t5ttT span:nth-of-type(1) {
  animation: _20xiV 10s 0s ease-in-out infinite;
}

.t5ttT span:nth-of-type(2) {
  animation: _20xiV 10s 5s ease-in-out infinite;
}

@media (max-width: 1023px) {
  ._1qRCL {
    padding: 0 18px;
  }

  ._3xWfL {
    display: flex;
    margin-right: 24px;
  }

  ._2jKwz img {
    height: 18px;
  }

  ._2X3M3 {
    position: fixed;
    z-index: 99999;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(-100%);
    transition: all .3s ease-in-out;
  }

  ._1qRCL.hidden {
    visibility: hidden;
  }

  ._2X3M3.active {
    transform: translateX(0);
  }

  ._2X3M3::before {
    position: fixed;
    opacity: 0;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    z-index: -1;
    transform: translateX(100%);
    transition: all .3s ease-in-out;
    visibility: hidden;
  }

  ._2X3M3.active::before {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }

  ._1BV1S {
    display: flex;
    margin: 14px 14px;
  }

  ._1h-Rk {
    width: 14px;
    height: 14px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  ._1qRCL {
    padding: 0 20px;
  }

  ._3nc3H {
    display: none;
  }

  ._2X3M3 {
    display: block;
  }

  ._1BV1S {
    display: none;
  }
}

@media (max-width: 768px) {
  ._2553O,
  .t5ttT {
    height: 52px;
  }
}

