.gw24c {
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

._2FWSf {
  max-width: 1440px;
  margin: 48px auto;
}

._1C31k ul {
  margin: 0;
}

._1C31k a {
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  margin: 4px 0;
  cursor: pointer;
  text-decoration: none;
}

._1C31k a:hover {
  color: #ef394f;
}

._1C31k > span {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-bottom: 14px;
  color: #000000;
}

._2xv04 a {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #696969;
  text-decoration: none;
}

._2xv04 a:hover {
  color: #ef394f;
}

.DYjjy {
  margin-top: 24px;
  display: inline-block;
  white-space: nowrap;
}

.DYjjy img {
  width: 48px;
  margin-right: 10px;
}

._1C31k img {
  width: 20px;
  height: 20px;
  margin-right: 13px;
}

.F9WnC {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  color: #696969;
}

.a97Ls p,
._1C31k p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  color: #696969;
}

._1C31k p a {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #696969;
  display: inline;
  display: initial;
  margin: 0;
  text-decoration: underline;
}

._1A5DJ a {
  display: inline-block;
}

._1A5DJ a:not(:last-child) {
  margin-right: 12px;
}

@media (min-width: 1024px) {
  ._2FWSf {
    padding: 0 70px;
  }

  ._2xv04 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ._2xv04 ul > li {
    display: inline-block;
    margin-right: 18px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  ._2FWSf {
    padding: 0 80px;
  }

  ._1C31k {
    display: inline-block;
    vertical-align: top;
  }

  ._3CMhl {
    grid-column-gap: 78px;
  }
}

@media (max-width: 768px) {
  ._2FWSf {
    margin: 24px 0;
    padding: 0 21px;
  }

  ._1C31k > span {
    margin-bottom: 10px;
  }

  ._1C31k {
    margin-top: 24px;
  }

  .gw24c {
    display: inline;
    display: initial;
  }

  ._2xv04 {
    margin-top: 24px;
  }

  ._2xv04 ul > li {
    margin-bottom: 8px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  ._2FWSf {
    padding: 0 21px;
  }

  .gw24c {
    grid-template-columns: repeat(2, 1fr);
  }
}

.trustpilot-widget {
  margin: 48px auto 24px;
}

@media screen and (max-width: 768px) {
  .trustpilot-widget {
    margin: 36px 0 24px;
  }
}

.Aimxc p {
  margin-top: 6px;
}

.Aimxc > span:nth-of-type(2) {
  margin-top: 24px;
}
