._3UphM progress {
  width: 225px;
  height: 8px;
  margin-right: 10px;
}

._3UphM progress {
  -webkit-appearance: none;
}

._3UphM progress::-webkit-progress-value {
  background-color:#EF394F;
}

._3UphM progress::-webkit-progress-bar {
  background-color: #E9E9E9;
}

._3UphM ._3-hhf {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #B0B0B0;
}

.-aXlb {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.-aXlb ._1KqVx {
  font-family: "TT Norms", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  color: #000000;
  margin-right: 8px;
}

._2cRN1 ._3J8k6 {
  display: flex;
  justify-content: flex-end;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
}

._2cRN1 p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #696969;
}

@media(min-width: 1024px) {
    ._3oi2g {
        display: flex;
        justify-content: space-between;
    }

    ._2-DK5 ._19tHw {
      justify-content: center;
      margin-top: -30px;
    }

    .tMTD2 {
      display: flex;
      margin-bottom: 25px
    }

    .tMTD2 div {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #000000;
        margin-right: 40px;
    }

    ._3YCFY {
        display: block;
    }

    ._3YCFY .Ws_gL {
        display: flex;
        margin-bottom: 13px;
    }

    ._3YCFY ._26MWd {
        width: 349px;
        height: 47px;
        margin-right: 19px;
    }

    ._3YCFY ._28g7U {
        width: 100%;
        height: 47px;
    }


    .Ws_gL input {
        background: #FFFFFF;
        border: 1px solid #777777;
        box-sizing: border-box;
        border-radius: 3px;
        padding-left: 20px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #A9A9A9;
        outline: none;
    }

    ._3YCFY textarea {
        background: #FFFFFF;
        border: 1px solid #777777;
        box-sizing: border-box;
        border-radius: 3px;
        padding-left: 20px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #A9A9A9;
        width: 100%;
        height: 122px;
        margin-bottom: 53px;
    }


    ._3YCFY ._35pK8 {
        display: flex;
        justify-content: center;
    }

    ._1wZPd._3K3Cc {
        background-color: #000000;
        color: #FFFFFF;
        margin-left: 41px;
    }

    ._1wZPd {
        display: block;
        width: 244px;
        height: 48px;
        border: 1px solid #000000;
        border-radius: 3px;
        box-sizing: border-box;
        background-color: transparent;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        text-decoration: none;
        outline: none;
        cursor: pointer;
    }
}

@media(max-width: 768px) {
    ._3oi2g {
        display: block;
    }

    ._3oi2g .HJNFn {
        display: block;
    }

    ._3oi2g .HJNFn ._3UphM {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
    }

    ._3oi2g .HJNFn img {
        margin: 0 7px 0 6px;
    }

    ._3oi2g .HJNFn ._3UphM .WcLw9 {
        width: 225px;
        height: 8px;
        background: #E9E9E9;
        margin: 0;
    }
    ._3oi2g .HJNFn div {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
    }

    ._3oi2g .HJNFn .-aXlb {
        font-family: "TT Norms", sans-serif;
        font-weight: bold;
        font-size: 29px;
        line-height: 34px;
        color: #000000;
        margin-bottom: 24px;
    }

    ._3oi2g ._3rL_3 {
        display: block;
        margin-left: 370px;
        width: 521px;
        height: 264px;
        overflow: auto;
    }

    ._3oi2g ._3rL_3 h1 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
    }

    [dir="ltr"] ._3oi2g ._3rL_3 ._3J8k6 {
        text-align: right;
  }

    [dir="rtl"] ._3oi2g ._3rL_3 ._3J8k6 {
        text-align: left;
  }

    ._3oi2g ._3rL_3 .YYWG4 {
        width: 489px;
        border: 1px solid #CCCCCC;
        margin: 0;
    }

    ._19tHw {
        background: #FAFAFA;
        justify-content: center;
      margin-top: 20px;
    }

    ._3YCFY h1 {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }



    ._3YCFY {
        display: block;
    }

    ._3YCFY ._26MWd {
        width: 100%;
        height: 47px;
        margin-bottom: 12px;
    }

    ._3YCFY ._28g7U {
        width: 100%;
        height: 47px;
        margin-bottom: 12px;
    }

    ._3YCFY .Ws_gL {
        display: block;
        margin-bottom: 13px;
    }


    .Ws_gL input {
        background: #FFFFFF;
        border: 1px solid #777777;
        box-sizing: border-box;
        border-radius: 3px;
        padding-left: 20px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 17px;
        color: #A9A9A9;
        outline: none;
    }

    ._3YCFY textarea {
        background: #FFFFFF;
        border: 1px solid #777777;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 13px 15px 0 20px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #A9A9A9;
        width: 318px;
        height: 218px;
        margin-bottom: 34px;
    }

    ._3YCFY ._35pK8 {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
    }

    ._1wZPd._3K3Cc {
        background-color: #000000;
        color: #FFFFFF;
        margin-bottom: 19px;
    }

    ._1wZPd {
        display: block;
        width: 244px;
        height: 48px;
        line-height: 48px;
        border: 1px solid #000000;
        border-radius: 3px;
        box-sizing: border-box;
        background-color: transparent;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        text-decoration: none;
        outline: none;
        cursor: pointer;
    }
}



