.OT7mK {
  display: block;
}

._2nGjc {
  display: block;
  max-width: 1440px;
  margin: auto;
  padding: 0 120px;
}

._2hLR4 {
  background-color: #fafafa;
}

._2hLR4 > div:first-child {
  max-width: 1440px;
  margin: auto;
  padding: 16px 120px 18px 120px;
  position: relative;
}

._2hLR4 a {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

._2hLR4 span {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.s0wke {
  display: block;
  padding-top: 106px;
}

.s0wke h3 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 37px;
  color: #000000;
}

.s0wke p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 32px;
}

._158Ef {
  display: flex;
  justify-content: space-between;
}

._9gsXc {
  height: 206px;
  width: 338px;
  position: relative;
}

._158Ef .b7SEd {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #FFFFFF;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 24px 80px 24px 24px;
  background-color: rgba(0, 0, 0, .2);
}

._158Ef a {
  text-decoration: none;
  transition: all .2s ease-in-out;
}

._158Ef a:hover {
  opacity: .8;
}

@media(max-width: 768px) {
  ._2nGjc {
    padding: 0 21px;
  }

  ._2hLR4 > div:first-child {
    padding: 12px 0 12px 21px;
  }

  ._9gsXc {
    width: 300px;
    padding: 12px 9px 0 9px;
    margin-right: 15px;
  }

  ._158Ef {
    overflow-y: hidden;
    overflow-x: auto;
  }
  ._158Ef .b7SEd {
    font-size: 16px;
    line-height: 19px;
  }
}


