._3l0IN {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 12px;
  font-family: "Inter", sans-serif;
}

._3l0IN h1 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #a6a6a6;
}

._3rilr {
  display: none;
}

._99liG {
  display: flex;
}

._1X4mn {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
}

._1fdrk {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
}

._2Fq3H {
  position: relative;
  display: inline-block;
}

._2Obsj {
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: #ffffff;
  min-width: 150px;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
}

._2Obsj a {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
  margin: 6px 0;
}

._2Fq3H:hover ._2Obsj {
  display: flex;
}

._2Fq3H:hover span {
  font-weight: bold;
}

._2ZQ9E {
  background: #f8f8f8;
  border: 1px solid #fafafa;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 4px;
}

._2cKMt {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
}

._2ZQ9E h2 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 12px;
  color: #000000;
}

._2jPPX {
  display: block;
}

.RcWSw {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 700px;
}

._1OxzS {
  display: flex;
}

._3QRbM {
  display: block;
  margin: 20px 0;
}

._1Fs8o {
  margin-bottom: 15px;
  border: 1px solid #a6a6a6;
  border-radius: 4px;
  padding: 12px;
}

._3tLX2 {
  margin-top: 12px;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  padding-bottom: 8px;
  margin-bottom: 12px;
}

.OCbUz {
  position: relative;
  border-radius: 4px;
  border: 2px solid #fff;
}

.OCbUz span {
  position: absolute;
  background-color: #ef394f;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  bottom: -8px;
  right: -8px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 12px;
}

._1Fjyb {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

._1Fjyb div {
  font-weight: 600;
  color: #8e8e8e;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
}

._2jPPX {
  margin-top: 30px;
}

._2jPPX div {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
}

._2jPPX div:first-child {
  font-size: 20px;
  margin-bottom: 10px;
  white-space: pre;
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  color: #696969;
}

._1mHaq {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 30px;
  white-space: pre;
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  color: #696969;
}

._1rmWn {
  display: flex;
  flex-direction: column;
}

._1rmWn:not(:last-child) {
  margin-right: 50px;
}

.X0QUs div {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #a6a6a6;
  margin-bottom: 5px;
}

.X0QUs div:first-child {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  color: #000000;
}

.X0QUs div:last-child {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

._2uks2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #a6a6a6;
  margin-bottom: 8px;
}

._1rmWn ._3PRT3 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #696969;
}

.nkxae {
  margin-top: 16px;
  margin-right: 50px;
}

._38ZW- {
  width: 100%;
  height: 1px;
  margin: 50px 0 20px 0;
  background-color: #e9e9e9;
}

.HMNsU {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

.rUiIj {
  font-weight: 600;
  color: #696969;
}

.HMNsU div {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #696969;
}

.o8gC3 {
  display: flex;
  justify-content: space-evenly;
  margin-top: 44px;
}

._2c6zy {
  margin-top: 16px;
}

._2EekL {
  display: flex;
  flex-wrap: nowrap;
  grid-gap: 12px;
  gap: 12px;
}

._1EsN0 {
  background: #fff;
  width: 340px;
  padding: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
}

._1EsN0 img {
  height: 100px;
  margin-right: 16px;
}

._1EsN0 ._1rDxO {
  display: flex;
  flex-direction: column;
}

._1EsN0 ._1rDxO ._2uks2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
  margin-bottom: 5px;
}

@media (min-width: 1024px) {
  ._3dI48.active {
    padding: 50px 120px;
    margin: auto;
    max-width: 1440px;
  }
}

@media (max-width: 768px) {
  .o8gC3 {
    flex-direction: column;
  }

  .RcWSw {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 700px;
  }

  ._3dI48.active {
    padding: 50px 20px;
  }

  .o8gC3 a {
    height: 100%;
  }

  .o8gC3 a:first-of-type {
    margin-bottom: 10px;
  }

  ._2ZQ9E {
    overflow-y: hidden;
  }

  .RcWSw ._1rmWn:first-of-type {
    display: none;
  }

  .RcWSw ._1rmWn:last-of-type {
    display: none;
  }

  ._12czj {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ._3rilr {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #696969;
    display: flex;
  }

  .RcWSw {
    margin-top: 10px;
  }

  ._1X4mn {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 158.8%;
    color: #696969;
  }

  ._2uks2 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 158.8%;
    color: #939393;
    white-space: pre;
  }

  .HMNsU div {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }
}

._1PbTq {
  display: flex;
  color: #939393;
  font-size: 0.9em;
  position: relative;
}

._1PbTq > span:not(:last-of-type)::after {
  content: "|";
  margin: 0 12px;
  color: #ddd;
}

@media (max-width: 768px) {
  ._1PbTq {
    flex-direction: column;
  }
  ._1PbTq > span::after {
    display: none;
  }
  ._2EekL {
    grid-gap: 6px;
    gap: 6px;
    flex-direction: column;
  }
  ._1EsN0 {
    width: 100%;
    padding: 6px;
  }
  ._2ZQ9E {
    border-radius: 0;
  }
  ._3l0IN {
    padding: 0;
  }
  ._3l0IN h1 {
    margin-left: 12px;
  }
}

._1Knro {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1em;
  margin: 0 0 6px;
  color: #000000;
}
