._1waEx {
  outline: none;
  min-width: 84px;
}

._1waEx a {
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #696969;
}

._1waEx a:hover {
  color: #EF394F;
}

.G-zuJ {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 2px;
  white-space: nowrap;
  padding: 0;
  margin: 0;
}

._2qDbN {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin: 24px 0 12px;
}

@media(min-width: 1024px) {
  ._1waEx.active {
    display: flex;
  }
}

@media(max-width: 768px) {
  .G-zuJ {
    grid-column-gap: 18px;
    grid-row-gap: 6px;
  }
}

