/* Dropdown content (hidden by default) */
ul {
  list-style: none;
  padding: 0;
}

@media(max-width: 1023px) {
    .MMK-g {
        display: block;
    }
    ._37uJR {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /*margin-right: 10px;*/
        padding: 19px 18px 19px 22px;
        height: 58px;
    }
    .J41Df {
        font-family: "TT Norms", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 20px;
        color: #000000;
    }
    ._2UE2B {
        width: 100%;
        border: 1px solid #E8E8E8;
    }
    ._3CaPQ {
        box-sizing: border-box;
        display: block;
        background: #FFFFFF;
        margin: auto;
        max-width: 1440px;
        width: 100%;
        top: 100%;
        left: 0;
        right: 0;
        padding: 19px 17px 19px 17px;
    }
    ._3O9YD ._3LHXz {
        display: inline-block;
        vertical-align: top;
        width: 17%;
    }
    ._2Uyb7 {
        left: 100%;
        position: absolute;
        /*padding: 19px 0 19px 17px;*/
        background-color: white;
        transition: all .3s ease-in-out;
        width: 100%;
        height: 100%;
        z-index: 9;
        top: 0;
    }


    ._2Uyb7.active {
        /*position: absolute;*/
        transform: translateX(-100%);

    }
}

@media(min-width: 1024px) {
    .MMK-g {
        display: none;
    }
    ._1UZ_G:hover:not(.ZE-Lq) > ._8DUW- {
        display: flex;
    }
    ._3CaPQ {
        box-sizing: border-box;
        display: none;
        position: absolute;
        background: #FFFFFF;
        margin: auto;
        max-width: 1440px;
        top: 100%;
        left: 0;
        right: 0;
        padding: 20px 70px 34px;
        box-shadow: 0 2038px 0 2038px rgba(0,0,0,0.3);
    }
    ._3O9YD ._3LHXz {
        display: inline-block;
        vertical-align: top;
        width: 17%;
    }
    ._2Uyb7 {
        display: none;
        background: white;
        width: 100%;
    }
    ._2Uyb7.active {
        display: flex;
    }
}





