._1B44N {
  max-width: calc(1200px + 1rem);
  margin: 48px auto;
  display: flex;
  flex-wrap: wrap;
}

._1p6BU {
  width: 25%;
  overflow: hidden;
  padding: 0 0.5rem 2.5rem;
  transition: opacity .2s ease-in-out;
}

._5sPHP {
  padding: 12px 16px;
  background: #FAFAFA;
}

._5sPHP a {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-decoration: none;
}

._5sPHP p {
  margin: 0px 0 0;
  font-size: 14px;
}

._1p6BU:hover {
  opacity: .8;
}

._3vZ7V {
  display: block;
  font-size: 0;
}

._3vZ7V img {
  width: 100%;
}

@media(max-width: 768px) {
  ._1B44N {
    margin: 24px .2rem;
  }

  ._1p6BU {
    width: 50%;
  }
}
