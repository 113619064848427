._1QZdz {
  background: #FAFAFA;
  border-radius: 3px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  text-decoration: none;
}

._1QZdz:hover {
  font-weight: 600;
}


