._1XF_k {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

._3Q5Q3 {
  display: block;
  margin-bottom: 42px;
}
._1XF_k .nvlrA a {
  display: none;
}

._3JvvB img {
  max-height: 370px;
}

._3Q5Q3 h2 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 37px;
  color: #000000;
}

._3Q5Q3 p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 30px;
  color: #000000;
  margin: 0;
  padding-top: 18px;
  /*max-width: 600px;*/
}


._3JvvB {
  display: flex;
  justify-content: space-between;
  padding-top: 64px;
  align-items: center;
}


@media(min-width: 1024px) {
  ._3JvvB:nth-child(2n) {
    flex-direction: row-reverse;
  }
}


@media(max-width: 768px) {
  ._3JvvB {
    display: flex;
    flex-direction: column-reverse;
  }
  ._3JvvB img {
    margin-bottom: 20px;
  }
  ._3Q5Q3 h1 {
    font-size: 26px;
  }

}
