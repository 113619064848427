._3u3-Y {
  margin: 48px 0 0;
  font-family: "Inter", sans-serif;
}

.faKJC {
  margin: 0 0 24px;
}

.faKJC h4 {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  margin: 0 0 18px;
}

.faKJC dl {
  -moz-columns: 2;
       columns: 2;
  -moz-column-fill: balance;
       column-fill: balance;
  grid-column-gap: 10%;
  -moz-column-gap: 10%;
       column-gap: 10%;
  word-break: break-word;
}

.faKJC dl > div {
  -moz-column-break-inside: avoid;
       break-inside: avoid-column;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  margin: 0 0 12px;
}

.faKJC dt {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  color: #696969;
}

.faKJC dd {
  margin: 0;
  font-size: 13px;
  color: #696969;
}
