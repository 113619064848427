._3NMCg {
  margin-left: 90px;
}

._3NMCg.active {
  background: #fafafa;
}

._1JB90 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 10px 10px;
  cursor: pointer;
  border-top: 1px solid #e8e8e8;
}

._1JB90 img {
  transform: rotate(-90deg);
  margin-right: 14px;
  transition: all 0.3s ease-in-out;
}

._3NMCg.active ._1JB90 img {
  transform: rotate(90deg);
}

._3NMCg ._1JB90 h2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

._3NMCg.active ._1JB90 h2 {
  font-weight: 600;
  font-size: 15px;
}

._3NMCg .SNjDh {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 222%;
  color: #696969;
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 0.5s linear;
  padding: 0px 24px 24px 24px;
}

._3NMCg.active .SNjDh {
  display: block;
  visibility: visible;
  opacity: 1;
}

._2DzPg {
  margin-top: 70px;
}

@media (max-width: 768px) {
  ._3WBF_ {
    margin-top: 15px;
  }

  ._3NMCg {
    margin-left: 0;
  }

  ._3NMCg .SNjDh {
    margin-top: 0;
    padding: 0px 21px 10px 21px;
  }

  ._1JB90 {
    padding: 16px 21px 10px 21px;
  }

  ._2qPDK {
    padding-right: 21px;
  }

  ._1_D77 {
    padding: 16px 21px 10px 21px;
  }

  ._1_D77 h2 {
    margin-left: 20px;
    margin-bottom: 0;
    font-size: 19px;
    line-height: 22px;
  }
  ._2DzPg {
    margin-top: 45px;
  }
}

.SNjDh table {
  width: 100%;
  border-collapse: collapse;
}

.SNjDh table th,
.SNjDh table td {
  padding: 10px;
}
