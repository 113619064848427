._1YxnY {
  background-color: #FAFAFA;
  padding: 24px 0;
}

.TLtvA {
  margin: auto;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 120px;
}

.c1lk0 {
  padding: 0 0 24px 0;
}

._1yaVH {
  font-family: "TT Norms", sans-serif;
  font-weight: bold;
  color: #696969;
  cursor: pointer;
  white-space: nowrap;
  overflow: auto;
  font-size: 20px;
  line-height: 24px;
  margin: 23px 60px 24px 0;
  transition: .2s ease-in-out;
}

._1yaVH:hover,
._1yaVH.active {
  color: #000000;
}

._2gebT {
  display: none;
}

._2gebT.active {
  display: inline;
  display: initial;
}

._1jAgc {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #696969;
  padding: 0;
  display: flex;
}

._1jAgc li:first-child {
  margin-right: 48px;
}

._1jAgc h4 {
  font-weight: 700;
  margin-bottom: 12px;
}

._1jAgc p {
  margin: 0 0 12px 0;
}

@media (max-width: 1023px) {
  .TLtvA {
    padding: 0 21px;
  }

  ._1yaVH {
    font-size: 16px;
    line-height: 19px;
    margin: 23px 30px 24px 0;
  }

  .c1lk0 {
    overflow: auto;
  }

  ._1jAgc {
    flex-direction: column;
  }

  ._1jAgc li:first-child {
    margin-right: 0;
  }

  ._2YO9v h1 {
    font-size: 12px;
    line-height: 16px;
  }

  ._2YO9v div {
    font-size: 11px;
    line-height: 15px;
  }
}
