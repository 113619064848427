
@media(min-width: 1024px) {
  ._2RnfZ {
    background-color: #FAFAFA;
    position: relative;
  }

  ._2RnfZ ._2gu2A {
    max-width: 1440px;
    margin: auto;
    padding-left: 45px;
    position: relative;
  }

  ._2gu2A ._8sqTf {
    display: flex;
    align-items: baseline;
  }

  ._2gu2A ._20MRe {
    padding: 16px 0 16px 75px;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    width: 465px;
    margin-right: 114px;
  }

  ._2e2jI {
    position: relative;
  }
  ._2e2jI ._2yYSt {
    right: 0;
  }

  .dr8VO {
    display: flex;
    align-items: center;
  }

  .dr8VO .LVLdK {
    height: 55px;
    display: flex;
    align-items: center;
    padding-right: 10px;
    background: #FAFAFA;
    cursor: pointer;
  }

  .dr8VO .LVLdK._2z0Px {
    background: #F3F3F3;
  }

  .dr8VO span {
    margin-right: 10px;
    margin-left: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
  }

  .dr8VO img {
    margin-right: 20px;
  }

  .dr8VO .LVLdK._2z0Px img {
    transform: rotate(-180deg);
  }

  ._2yYSt {
    position: absolute;
    top: 120%;
    opacity: 0;
    visibility: hidden;
    z-index: 998;
    background: #FAFAFA;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    width: 313px;
    height: 216px;
    overflow: auto;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  ._2yYSt.zvXLq {
    width: 378px;
    height: 138px;
  }

  ._2yYSt.KeGsE {
    width: 382px;
    height: 312px;
  }


  ._2yYSt.zvXLq ._2z0Px {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }

  ._2yYSt._2z0Px {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }


  ._6ae7C {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 4px;
    width: 338px;
    height: 104px;
    margin: 2px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ._23hYN ._2MQ_r {
    width: 20px;
    height: 20px;
    border-radius: 26px;
    overflow: hidden;
    top: 50%;
    background: #696969;
    margin-left: 20px;

  }

  ._23hYN._2PC5A {
    position: absolute;
    right: 35px;
    top: 23%;
  }

  ._23hYN.JFiuw {
    position: absolute;
    left: 35px;
    top: 23%;
  }

  ._23hYN div {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 3px;
    width: 65px;
    height: 27px;
    top: 30%;
  }

  ._23hYN span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #696969;
    margin: 6px 8px;
  }

  ._2iksQ {
    padding-top: 18px;
    margin-right: 17px;
  }

  ._2iksQ span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #696969;
    cursor: pointer;
  }

  ._3Xm8r {
    background: #FFFFFF;
    border-radius: 3px;
    margin: 0 9px 6px 15px;
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-height: 42px;
    text-decoration: none;
  }

  ._3Xm8r:last-child {
    margin: 0 9px 0 15px;
  }

  ._3Xm8r span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._3Xm8r._2z0Px span {
    font-weight: 600;
    color: #000000;
  }



  ._25M9K {
    display: flex;
    position: absolute;
    right: 0;
    top: 9%;
    margin-right: 10px;
  }

  ._1g_Er {
    display: block;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 339px;
    height: 44px;
    margin: 5px 0 13px 15px;
    padding-left: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    outline: none;
  }
}


@media(max-width: 768px) {
  ._2RnfZ {
    background-color: #FAFAFA;
    position: relative;
  }

  ._2RnfZ ._2gu2A {
    max-width: 1440px;
    margin: auto;
    padding-left: 45px;
    position: relative;
  }

  ._2gu2A ._8sqTf {
    display: flex;
    align-items: baseline;
  }

  ._2gu2A ._20MRe {
    padding: 16px 0 16px 75px;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    width: 465px;
    margin-right: 114px;
  }

  ._2e2jI {
    position: relative;
  }
  ._2e2jI ._2yYSt {
    right: 0;
  }

  .dr8VO {
    display: flex;
    align-items: center;
  }

  .dr8VO .LVLdK {
    height: 100%;
    padding-top: 22px;
    padding-bottom: 18px;
    background: #FAFAFA;
    cursor: pointer;
    display: flex;
  }

  .dr8VO .LVLdK._2z0Px {
    background: #F3F3F3;
  }

  .dr8VO .LVLdK span {
    margin-right: 5px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-left: 10px;
    color: #696969;
    white-space: nowrap;
    max-width: 56px;
    overflow: hidden;
  }

  .dr8VO img {
    margin-right: 10px;
  }

  .dr8VO .LVLdK._2z0Px img {
    transform: rotate(-180deg);
  }

  ._2yYSt {
    position: absolute;
    top: 120%;
    opacity: 0;
    visibility: hidden;
    z-index: 998;
    background: #FAFAFA;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    width: 313px;
    height: 216px;
    overflow: auto;
    padding-bottom: 15px;
    padding-top: 15px;
    left: -50%;
  }

  ._2yYSt.zvXLq {
    width: 378px;
    height: 138px;
  }

  ._2yYSt.KeGsE {
    width: 382px;
    height: 312px;
  }


  ._2yYSt.zvXLq ._2z0Px {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }

  ._2yYSt._2z0Px {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }


  ._6ae7C {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 4px;
    width: 338px;
    height: 104px;
    margin: 2px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ._23hYN ._2MQ_r {
    width: 20px;
    height: 20px;
    border-radius: 26px;
    overflow: hidden;
    top: 50%;
    background: #696969;
    margin-left: 20px;

  }

  ._23hYN._2PC5A {
    position: absolute;
    right: 35px;
    top: 23%;
  }

  ._23hYN.JFiuw {
    position: absolute;
    left: 35px;
    top: 23%;
  }

  ._23hYN div {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 3px;
    width: 65px;
    height: 27px;
    top: 30%;
  }

  ._23hYN span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #696969;
    margin: 6px 8px;
  }

  ._2iksQ {
    padding-top: 18px;
    margin-right: 17px;
  }

  ._2iksQ span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #696969;
    cursor: pointer;
  }

  ._3Xm8r {
    background: #FFFFFF;
    border-radius: 3px;
    margin: 0 9px 6px 15px;
    padding: 12px 27px;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-height: 42px;
  }

  ._3Xm8r:last-child {
    margin: 0 9px 0 15px;
  }

  ._3Xm8r span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._3Xm8r._2z0Px span {
    font-weight: 600;
    color: #000000;
  }



  ._25M9K {
    display: flex;
    position: absolute;
    right: 0;
    top: 9%;
    margin-right: 10px;
  }

  ._1g_Er {
    display: block;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 339px;
    height: 44px;
    margin: 5px 0 13px 15px;
    padding-left: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    outline: none;
  }
}

