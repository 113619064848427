
@media(min-width: 1024px) {
  ._3HJwI {
    height: 100%;
    display: flex;
    justify-content: center;
  }
  ._3Yodb {
    background-color: #FAFAFA;
    position: relative;
  }

  ._3Yodb .XTQSc {
    max-width: 1440px;
    margin: auto;
    padding-left: 45px;
    position: relative;
  }

  .XTQSc ._3j4wW {
    display: flex;
  }

  ._1mS9W {
    display: list-item;
    list-style: none;
  }

  .XTQSc ._35Bpm {
    padding: 16px 0 16px 75px;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    width: 465px;
    margin-right: 114px;
  }

  ._2_yA5 {
    display: flex;
    align-items: center;
  }

  ._1DHtR {
    position: relative;
    cursor: pointer;
  }
  ._1DHtR .dcX5I {
    right: 0;
  }

  ._1DHtR ._2miW1 {
    height: 100%;
    background: #FAFAFA;
    cursor: pointer;
    white-space: nowrap;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  ._1DHtR ._2miW1._1gTC_ {
    background: #F3F3F3;
  }

  ._2miW1._1gTC_ span {
    text-shadow: 0 0 0.01px #696969;
  }

  ._2miW1:hover {
    background: #F3F3F3;
  }

  ._2miW1 span::before {
    display: block;
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  ._2miW1 span {
    display: inline-block;
    margin-right: 10px;
    margin-left: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
    font-weight: normal;
  }

  ._2miW1 img {
    margin-right: 20px;
    opacity: 0.5;
    transform: rotate(-180deg);
  }

  ._2miW1._1gTC_ img {
    transform: rotate(0);
    opacity: 1;
  }

  .dcX5I {
    position: absolute;
    top: 120%;
    opacity: 0;
    visibility: hidden;
    z-index: 998;
    background: #FAFAFA;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    width: 313px;
    max-height: 216px;
    overflow: auto;
    overflow-x: hidden;
    padding: 16px 20px;
  }

  .dcX5I._7yk3u {
    width: 378px;
    height: 138px;
  }

  .dcX5I.FcVQU {
    width: 382px;
    height: 312px;
  }


  .dcX5I._7yk3u ._1gTC_ {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }

  .dcX5I._1gTC_ {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }


  .hEJIN {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 4px;
    /*width: 338px;*/
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ._2eTmJ ._1VMvI {
    width: 20px;
    height: 20px;
    border-radius: 26px;
    overflow: hidden;
    top: 50%;
    background: #696969;
    margin-left: 20px;

  }

  ._2eTmJ._siRj {
    position: absolute;
    right: 35px;
    top: 23%;
  }

  ._2eTmJ.bCfUV {
    position: absolute;
    left: 35px;
    top: 23%;
  }

  ._2eTmJ div {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 3px;
    width: 65px;
    height: 27px;
    top: 30%;
  }

  ._2eTmJ span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #696969;
    margin: 6px 8px;
  }

  ._2hJAu {
    background: #FFFFFF;
    border-radius: 3px;
    margin-bottom: 6px;
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-height: 42px;
  }

  ._2hJAu:hover span {
    color: #EF394F;

  }


  ._2hJAu span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._2hJAu._1gTC_ span {
    font-weight: 600;
    color: #000000;
  }



  ._2Dtrm {
    display: flex;
    position: absolute;
    right: 0;
    top: 14%;
    margin-right: 20px;
  }

  ._3ADnW {
    display: block;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-left: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    outline: none;
  }






  /* Test With Range Slider Price */

  ._3_JT7 {
    position: relative;
    width: 100%;
    max-width: 200px;
  }

  ._28sgq {
    position: relative;
    z-index: 1;
    height: 10px;
    margin: 0 15px;
  }
  ._28sgq > ._3V145 {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #c6aee7;
  }
  ._28sgq > ._26BLe {
    position: absolute;
    z-index: 2;
    left: 25%;
    right: 25%;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #6200ee;
  }
  ._28sgq > ._1kEsN {
    position: absolute;
    z-index: 3;
    width: 30px;
    height: 30px;
    background-color: #6200ee;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(98,0,238,.1);
    transition: box-shadow .3s ease-in-out;
  }
  ._28sgq > ._1kEsN.bCfUV {
    left: 25%;
    transform: translate(-15px, -10px);
  }
  ._28sgq > ._1kEsN._siRj {
    right: 25%;
    transform: translate(15px, -10px);
  }
  ._28sgq > ._1kEsN._2tAIR {
    box-shadow: 0 0 0 20px rgba(98,0,238,.1);
  }
  ._28sgq > ._1kEsN._1gTC_ {
    box-shadow: 0 0 0 40px rgba(98,0,238,.2);
  }

  input[type=range] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 10px;
    width: 100%;
    opacity: 0;
  }
  input[type=range]::-webkit-slider-thumb {
    pointer-events: all;
    width: 30px;
    height: 30px;
    border-radius: 0;
    border: 0 none;
    background-color: red;
    -webkit-appearance: none;
  }
}




@media(max-width: 768px) {
  ._2miW1._1gTC_ span {
    color: #000000;
  }

  ._3Yodb {
    background-color: #FAFAFA;
    position: relative;
  }

  ._3Yodb .XTQSc {
    max-width: 1440px;
    margin: auto;
    padding-left: 45px;
    position: relative;
  }

  .XTQSc ._3j4wW {
    display: flex;
  }


  ._2_yA5 {
    display: flex;
    align-items: center;
  }

  ._1DHtR {
    position: relative;
    padding: 0 21px;
    border-bottom: 1px solid #E1E1E1;
  }
  ._1DHtR .dcX5I {
    right: 0;
  }

  ._1DHtR ._2miW1 {
    height: 100%;
    cursor: pointer;
  }

  ._2miW1 img {
    opacity: 0.5;
    transform: rotate(-180deg);
  }

  ._2miW1._1gTC_ img {
    transform: rotate(0);
    opacity: 1;
  }


  ._2_yA5 span {
    margin-right: 10px;
    margin-left: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
  }

  .dcX5I {

    max-height: 216px;
    display: none;
    padding: 15px 21px;
    text-align: center;
  }



  .dcX5I._1gTC_ {
    display: block;
  }


  .hEJIN {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 4px;
    /*width: 338px;*/
    height: 104px;
    margin: 2px 20px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ._2eTmJ ._1VMvI {
    width: 20px;
    height: 20px;
    border-radius: 26px;
    overflow: hidden;
    top: 50%;
    background: #696969;
    margin-left: 20px;

  }

  ._2eTmJ._siRj {
    position: absolute;
    right: 35px;
    top: 23%;
  }

  ._2eTmJ.bCfUV {
    position: absolute;
    left: 35px;
    top: 23%;
  }

  ._2eTmJ div {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 3px;
    width: 65px;
    height: 27px;
    top: 30%;
  }

  ._2eTmJ span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #696969;
    margin: 6px 8px;
  }

  ._36u5a {
    padding-top: 18px;
    margin-right: 17px;
  }

  ._36u5a span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #696969;
    cursor: pointer;
  }

  ._2hJAu {
    background: #FFFFFF;
    border-radius: 3px;
    margin: 0 9px 6px 15px;
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-height: 42px;
  }

  ._2hJAu:last-child {
    margin: 0 9px 0 15px;
  }

  ._2hJAu span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._2hJAu._1gTC_ span {
    font-weight: 600;
    color: #000000;
  }



  ._2Dtrm {
    display: flex;
    position: absolute;
    right: 0;
    top: 14%;
    margin-right: 10px;
  }

  ._3ADnW {
    display: block;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 339px;
    height: 44px;
    margin: 5px 0 13px 15px;
    padding-left: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    outline: none;
  }

  /* Test With Range Slider Price */

  ._3_JT7 {
    position: relative;
    width: 100%;
    max-width: 500px;
  }

  ._28sgq {
    position: relative;
    z-index: 1;
    height: 10px;
    margin: 0 15px;
  }
  ._28sgq > ._3V145 {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #c6aee7;
  }
  ._28sgq > ._26BLe {
    position: absolute;
    z-index: 2;
    left: 25%;
    right: 25%;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #6200ee;
  }
  ._28sgq > ._1kEsN {
    position: absolute;
    z-index: 3;
    width: 30px;
    height: 30px;
    background-color: #6200ee;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(98,0,238,.1);
    transition: box-shadow .3s ease-in-out;
  }
  ._28sgq > ._1kEsN.bCfUV {
    left: 25%;
    transform: translate(-15px, -10px);
  }
  ._28sgq > ._1kEsN._siRj {
    right: 25%;
    transform: translate(15px, -10px);
  }
  ._28sgq > ._1kEsN._2tAIR {
    box-shadow: 0 0 0 20px rgba(98,0,238,.1);
  }
  ._28sgq > ._1kEsN._1gTC_ {
    box-shadow: 0 0 0 40px rgba(98,0,238,.2);
  }

  input[type=range] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 10px;
    width: 100%;
    opacity: 0;
  }
  input[type=range]::-webkit-slider-thumb {
    pointer-events: all;
    width: 30px;
    height: 30px;
    border-radius: 0;
    border: 0 none;
    background-color: red;
    -webkit-appearance: none;
  }
}


._3_JT7 .DefaultProgressBar_progressBar  {
  background-color: #C4C4C4;
}

._3_JT7 .DefaultProgressBar_background__horizontal {
  height: 6px;
}

._3_JT7 .DefaultBackground {
  display: none;
}

._3_JT7 .rheostat button {
  width: 20px;
  height: 20px;
  border: 1px solid #d8d8d8;
  background: #696969;
  border-radius: 50%;
  outline: none;
  z-index: 2;
  box-shadow: 0 2px 2px #dbdbdb;
  margin-left: -12px;
  top: -6px;
}
