.Lgejy {
  margin: 48px auto 48px;
  max-width: 1200px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-size: .875rem;
  letter-spacing: 0;
  line-height: 1.35rem;
  color: #000;
}

.Lgejy a {
  color: #ef394f;
}

.Lgejy a:hover {
  text-decoration: underline;
}

.Lgejy h2 {
  font-size: 1rem;
  letter-spacing: -.16px;
  line-height: 1.5rem;
  margin-bottom: 8px;
  margin-top: 24px;
}

._3Bv0G {
  margin: 96px auto 48px;
  max-width: 1200px;
  box-sizing: border-box;
  padding: 0;
  -moz-column-count: 4;
       column-count: 4;
  grid-column-gap: 24px;
  -moz-column-gap: 24px;
       column-gap: 24px;
  -moz-column-fill: balance;
       column-fill: balance;
}

._3Bv0G a {
  text-decoration: none;
  color: #000;
}

._3Bv0G a:hover {
  color: #ef394f;
}

._3Bv0G > li {
  page-break-inside: avoid;
  -moz-column-break-inside: avoid;
       break-inside: avoid;
  margin-bottom: 24px;
  font-size: 14px;
}

._3Bv0G ul > li > a {
  color: #585858;
}

@media(max-width: 1236px) {
  .Lgejy,
  ._3Bv0G {
    margin-left: 18px;
    margin-right: 18px;
  }
}

@media(max-width: 768px) {
  ._3Bv0G {
    -moz-column-count: 2;
         column-count: 2;
    margin-top: 48px;
  }
}
