.lko0H {
  display: block;
  margin-right: 120px;
  vertical-align: top;
  min-width: 150px;
}

.lko0H li {
  margin-bottom: 12px;
}

.lko0H li a {
  font-family: "Inter", sans-serif;
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
  color: #222222;
  cursor: pointer;
  outline: none;
}

.lko0H li a._4lMsE {
  text-decoration: underline;
}

.lko0H li a:hover {
  color: #EF394F;
}

@media (max-width: 1023px) {
  .lko0H {
    min-width: 150px;
    padding: 0 18px 0 22px;
  }

  .lko0H li a {
    font-family: "Inter", sans-serif;
    color: #A6A6A6 !important;
  }
}
