.-Q_Vb {
    max-width: 1440px;
    margin: 47px auto 40px;
    padding: 0 120px;
}

._1_3XD {
  background-color: #FAFAFA;
}

.wZwWB {
    max-width: 1440px;
    margin: auto;
    padding-left: 45px;
}

._3mGVq {
  display: flex;
  align-items: center;
}

.wZwWB {
    display: flex;
    align-items: center;
}

._1_3XD .ySrZs {
    padding: 16px 0 16px 75px;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    width: 465px;
    margin-right: 114px;
}

._2gGvP {
    margin-right: 17px;
    width: 188px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
}

._2gGvP.Zrpsa {
    background: #EDEDED;
}

._2gGvP.Zrpsa span {
    color: #000000;
}

._10cSj {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 10px;
}


._2gGvP span {
    outline: none;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
    cursor: pointer;
    white-space: nowrap;
}

.eQn4t {
    display: flex;
    align-items: center;
    margin-bottom: 55px;
}

.eQn4t span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-right: 28px;
}

.eQn4t ._1nBBn {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-right: 24px;
  border-width: 0;
  background: #ffffff;
  cursor: pointer;
}

.eQn4t ._1nBBn:hover {
  color: #EF394F;
}

._2E9j2 {
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
}

._2GVwJ {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 50px;
}

/*.brandLetter {*/
/*    margin-right: 90px;*/
/*}*/

._3CU4L ._1nBBn {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
}

._3CU4L ul {
    list-style: none;
    padding: 0;
}

._3CU4L a {
    text-decoration: none;
}

._3CU4L a:hover {
  color: #EF394F;
}

._3CU4L li {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
    margin-bottom: 22px;
    cursor: pointer;
}

@media(max-width: 768px) {
  .-Q_Vb {
    max-width: 1440px;
    margin: 47px auto 40px;
    padding: 0 20px;
  }

  .pakCB {
    background-color: #FAFAFA;
    position: relative;
  }

  .pakCB ._1_3XD {
    max-width: 1440px;
    margin: auto;
    padding-left: 45px;
    position: relative;
  }



  ._10cSj {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 80px;
    grid-row-gap: 10px;
  }

  .eQn4t {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 10px;
    row-gap: 10px;
    align-items: center;
    margin-bottom: 55px;
  }

  .eQn4t span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-right: 28px;
    white-space: nowrap;
    display: none;
  }

  ._1nBBn {
    display: flex;
    align-items: flex-start;
  }

  ._2E9j2 {
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
  }

  ._2GVwJ {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 80px;
  }

  /*.brandLetter {*/
  /*    margin-right: 90px;*/
  /*}*/

  ._3CU4L ._1nBBn {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
  }

  ._3CU4L ul {
    list-style: none;
    padding: 0;
  }

  ._3CU4L a {
    text-decoration: none;
  }

  ._3CU4L li {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
    margin-bottom: 22px;
    cursor: pointer;
  }

  .wZwWB {
    max-width: 1440px;
    margin: auto;
    display:  block;
    padding: 0;
  }

  ._3mGVq {
    background-color: #FAFAFA;
    padding: 14px 20px;
    overflow: auto;
  }

  ._1_3XD {
    background-color: white;
  }

  ._1_3XD .ySrZs {
    padding: 40px 20px 16px 20px;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    color: #000000;
    width: 100%;
  }

  ._2gGvP {
    margin-right: 17px;
    width: 188px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ._2gGvP span {
    font-size: 13px;
    line-height: 16px;
  }

  ._2gGvP.Zrpsa {
    background: #EDEDED;
  }

  ._2gGvP.Zrpsa span {
    color: #000000;
  }

}
