.jfRjc ._3MGl6 {
  background-size: cover;
  width: 100%;
  height: 100%;
}



._3SLFU ._2edBr {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 47px;
  line-height: 55px;
  color: #FFFFFF;
}

._3SLFU p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 202%;
  color: #FFFFFF;
  max-width: 455px;
}

.rn1kP {
  width: 1200px;
  margin: auto;
  padding: 38px 120px;
}

._1tmJW {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 16px;
}

._1tmJW a {
  color: #696969;
}

._3OYPw {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
}

/*@media (min-width: 1024px) {*/
/*  .wrapperFound .header .text {*/
/*    display: block;*/
/*    width: 1440px;*/
/*    margin: auto;*/
/*    padding: 48px 120px 78px 120px;*/
/*  }*/
/*}*/

@media (max-width: 768px) {
  /*.header .text {*/
  /*  display: block;*/
  /*  width: 100%;*/
  /*  margin: auto;*/
  /*  padding: 48px 42px 48px 42px;*/
  /*}*/

  .rn1kP {
    width: 100%;
    padding: 38px 21px;
  }

  ._1tmJW {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 16px;
  }

  ._1tmJW > div:last-child {
    grid-column: span 2;
  }

  /*.header {*/
  /*  background-size: cover;*/
  /*  width: 100%;*/
  /*  height: 100%;*/
  /*  background-position: 50%;*/
  /*}*/

}

