
._39H4w > div {
    flex: auto;
}

._3F0nT ._2kTDH {
    margin-right: 20px;
}

._3F0nT {
    display: inline-flex;
    position: absolute;
    top: 85%;
    margin: 0 171px 0 171px;
}

._33bzo {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    box-shadow: 0 1px 9px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.Tq-tX {
    position: absolute;
    width: 53px;
    height: 53px;
    top: 50%;
    border-radius: 50%;
    margin-top: -35px;
    z-index: 10;
    transition: visibility 1s 0s, opacity .2s ease-in-out;
    opacity: 0.5;
    cursor: pointer;
    /* background-image: url(../../assets/arrow-slider.svg); */
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.Tq-tX:hover {
    opacity: 1;
}

.Tq-tX.nauNA {
    left: 50px;
    transform: rotate(-180deg);
}
.Tq-tX._9vdGE {
    position: absolute;
    right: 50px;
}