.SjQ3e ._1KwCA {
  margin-bottom: 10px;
}

._118Vx {
  -moz-column-break-inside: avoid;
       break-inside: avoid-column;
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  ._1Lu-c {
    display: none;
  }

  .tJ-bi {
    -moz-column-count: 3;
         column-count: 3;
    width: 100%;
  }

  .Eb81K {
    margin: 0 50px 10px 0;
    outline: none;
    width: 232px;
    text-decoration: none;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
  }

  .Eb81K a {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    text-decoration: none;
    text-align: left;
    margin-bottom: 10px;
  }

  .Eb81K:hover {
    color: #EF394F;
  }
}

@media (max-width: 768px) {
  ._118Vx.active {
    background: #FAFAFA;
    width: 100%;
  }

  ._118Vx {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding: 13px 18px 0 22px;
    margin-bottom: 11px;
  }

  .Eb81K {
    margin: 0 0 10px 0;
    outline: none;
    text-decoration: none;
  }

  .Eb81K {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    text-decoration: none;
    text-align: left;
    width: 100%;
  }

  .Eb81K {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  ._2QIlt{
    display: flex;
    align-items: baseline;
  }

  ._1Lu-c {
    transform: rotate(-90deg);
  }

  ._1Lu-c.active {
    transform: rotate(90deg);
    transition: all .3s ease-in-out;
  }
}
