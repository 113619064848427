._3Xm1F {
    display: block;
    position: relative;
    transform-origin: top left;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: left;
}

.lAfve {
    position: absolute;
    width: 53px;
    height: 53px;
    top: 50%;
    border-radius: 50%;
    margin-top: -45px;
    z-index: 10;
    transition: visibility 1s 0s, opacity .2s ease-in-out;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    background-image: url(/assets/src/assets/arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, .7);
}

.lAfve._2i8Cl {
    visibility: visible;
    opacity: .9;
}
.lAfve:hover {
    opacity: 1;
}

.lAfve._3owXN {
    left: 20px;
    transform: rotate(-180deg);
}
.lAfve.AAMRi {
    position: absolute;
    right: 20px;
}

._1diM4 {
    display: block;
    overflow-x: auto;
    position: relative;
    white-space: nowrap;
    padding-left: calc((100% - 1200px) / 2);
}

._1diM4::-webkit-scrollbar {
    display: none;
}

._1DmB8 {
    display: inline-block;
    padding-right: calc((100vw - 1200px) / 2);
}

._1DmB8 > div:last-child {
    margin-right: 0 !important;
}