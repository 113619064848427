._3mHoS {
  list-style: none;
  padding: 0 0 24px 0;
  margin: 0;
}

._3mHoS li {
  display: inline-block;
  margin: 0 16px 16px 0;
}

._3mHoS a {
  padding: 12px 24px;
  font-size: 15px;
  background: #FFF;
  color: #111;
  display: inline-block;
  text-decoration: none;
  transition: .2s all ease-in-out;
  border-radius: 4px;
}

._3mHoS a:hover {
  color: #EF394F;
}

@media (max-width: 768px) {
  ._3mHoS {
    padding: 0 18px 10px;
    margin: 0;
  }

  ._3mHoS li {
    margin: 0 10px 10px 0;
  }

  ._3mHoS a {
    padding: 6px 12px;
    font-size: 12px;
  }
}
