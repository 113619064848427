._1i_0t ._2bZ0a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*width: 100%;*/
}

._1i_0t a {
  text-decoration: none;
}

._1i_0t ._2bZ0a div {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #696969;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

._1i_0t ._2bZ0a ._2EGuv {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #000000;
  margin-top: 0;
  margin-bottom: 4px;
  width: 140px;
}

._1i_0t ._2bZ0a div:last-child {
  font-weight: normal;
}

.L1DjH {
  height: 82px;
}

._1i_0t img:last-child {
  cursor: pointer;
}

._1i_0t ._3zrxN {
  margin: 0 68px 0 29px;
  justify-content: space-between;
  display: flex;
}

._1i_0t ._3zrxN span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #A6A6A6;
}

._1i_0t ._3zrxN span:last-child {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  color: #000000;
}


._2JjXc {
  width: 100%;
  height: 1px;
  margin: 25px 0;
  background-color: #E9E9E9;
}

._2utBH {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 29px;

}

._1i_0t ._2bZ0a ._1d3Q2 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

._1i_0t ._2bZ0a .rJQyf {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #EF394F;
}

._1i_0t ._2bZ0a ._29Ail {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  text-decoration: line-through;
}

._3cbzB {
  position: absolute;
  right: 0;
}

.erZn_ {
  display: flex;
  justify-content: flex-end;
}


@media (min-width: 1024px) {
  ._1i_0t ._27JO7 {
    width: 150px;
    display: block;
  }

  ._1i_0t .AM6bo {
    display: contents;
  }

  .GZI8x {
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    margin-left: 25px;
  }


  .GZI8x span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: textfield;
    flex: none;
    text-align: center;
    width: 15px;
    cursor: default;
  }

  .GZI8x span::-webkit-inner-spin-button, ::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  .GZI8x button {
    font-family: "Apercu Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #A9A9A9;
    background: none;
    border: none;
    padding: 4px 10px;
    cursor: pointer;
    outline: inherit;
  }

  .GZI8x ._2Rfrr {
    font-size: 8px;
    padding: 4px 10px;
  }


}

@media (max-width: 1023px) {
  ._1i_0t ._27JO7 {
    width: 150px;
    display: block;
  }

  ._1i_0t .AM6bo {
    display: block;
  }

  .GZI8x {
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    display: inline-block;
  }


  .GZI8x span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: textfield;
    flex: none;
    text-align: center;
    width: 15px;
    cursor: default;
  }

  .GZI8x span::-webkit-inner-spin-button, ::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  .GZI8x button {
    font-family: "Apercu Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #A9A9A9;
    background: none;
    border: none;
    padding: 4px 10px;
    cursor: pointer;
    outline: inherit;
  }

  .GZI8x ._2Rfrr {
    font-size: 8px;
    padding: 4px 10px;
  }


}
