._3DbD8 {
  max-width: 1440px;
  margin: 41px auto auto auto;
}

._6laXA {
  display: flex;
  width: 100%;
  padding: 0 120px;
}

._1qaHQ {
  margin-top: 80px;
  display: block;
  width: 662px;
}

._1qaHQ img {
  width: 662px;
}

.oPi9L {
  display: block;
  text-align: center;
  padding-right: 120px;
  margin-top: 64px;
}

.oPi9L span {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 37px;
  color: #000000;
  padding-left: 120px;
}

.oPi9L .RrlfC {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 222%;
  text-align: center;
  color: #000000;
  margin-bottom: 46px;
  padding-left: 120px;
}

._167iF {
  display: flex;
  padding-left: 25px;
  padding-right: 110px;
}

._167iF img {
  margin-top: 8%;
  margin-right: 10px;
}

._3e9je {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 16px;
  width: 100%;
  height: 100%;
}

._3e9je a {
  color: #696969;
}

._2kXwJ {
  display: flex;
  padding: 0 120px;
  align-items: center;
}

._2QD12 {
  display: block;
  background: #f8f8f8;
  width: 100%;
  position: relative;
  padding: 20px 40px 28px 40px;
  margin-right: 25px;
}

._2QD12 span {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 37px;
  color: #000000;
  position: absolute;
  top: -8%;
}

._2QD12 p {
  line-height: 1.7;
  color: #333;
  font-family: Inter,sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 1.5em;
}

._6laXA .react-multi-carousel-list {
  display: flex;
  overflow: hidden;
  position: relative
}

._6laXA .react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
}

._6laXA .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer
}

._6laXA .react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.8)
}

._6laXA .react-multiple-carousel__arrow::before {
  font-size: 20px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative
}

._6laXA .react-multiple-carousel__arrow--left {
  left: calc(4% + 1px)
}

._6laXA .react-multiple-carousel__arrow--left::before {
  content: "\E824"
}

._6laXA .react-multiple-carousel__arrow--right {
  right: calc(4% + 1px)
}

._6laXA .react-multiple-carousel__arrow--right::before {
  content: "\E825"
}

._6laXA .react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center
}

._6laXA .react-multi-carousel-dot button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background .5s;
  border-width: 1px;
  border-style: solid;
  border-color: grey;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  background: transparent;
  cursor: pointer
}

._6laXA .react-multi-carousel-dot button:hover:active {
  background: #080808
}

._6laXA .react-multi-carousel-dot--active button {
  background: #C1C1C1
}

._6laXA .react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden
}

@media(max-width: 768px) {
  ._6laXA .react-multi-carousel-item {
    height: 100%;
  }
  ._6laXA .react-multi-carousel-list {
    height: 100%;
  }
  ._6laXA .react-multi-carousel-track {
    height: 100%;
  }
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
  ._6laXA .react-multi-carousel-item {
    flex-shrink: 0 !important
  }

  ._6laXA .react-multi-carousel-track {
    overflow: visible !important
  }
}


._1IT9x {
  background: transparent;
  border-width: 0;
  position: absolute;
  right: 20px;
  transition: visibility 1s 0s, opacity .2s ease-in-out;
  opacity: 0.5;
}
.ppT5p {
  background: transparent;
  border-width: 0;
  position: absolute;
  left: 20px;
  transform: rotate(-180deg);
}

/*.slideArrow {*/
/*    position: absolute;*/
/*    width: 53px;*/
/*    height: 53px;*/
/*    top: 50%;*/
/*    border-radius: 50%;*/
/*    margin-top: -35px;*/
/*    z-index: 10;*/
/*    transition: visibility 1s 0s, opacity .2s ease-in-out;*/
/*    opacity: 0.5;*/
/*    cursor: pointer;*/
/*    background-image: url(../../assets/arrow-slider.svg);*/
/*    background-repeat: no-repeat;*/
/*    background-size: contain;*/
/*    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);*/
/*}*/



@media (min-width: 768px) and (max-width: 1024px){
  ._3M1Hd {
    overflow: hidden;
    width: 100%;
    height: calc(0.2625 * 100vw);
    max-height: 100%;
    position: relative;
  }
  ._1FyLW {
    display: block;
  }
  .QHLzy {
    display: none;
  }
  ._2NjN3 > div {
    flex: auto;
  }

  ._3heum ._3JP5f {
    color: #FFFFFF;
  }

  ._3heum .vyL3o {
    color: #000000;
  }

  ._3heum ._374WV {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 31px;
    line-height: 37px;
    margin: 18px 0 0 0;
  }

  ._1F1PI {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }


  ._1BWD9 img {
    position: absolute;
    width: 53px;
    height: 53px;
    margin-top: -35px;
    z-index: 10;
    transition: visibility 1s 0s, opacity .2s ease-in-out;
    opacity: 0.5;
    cursor: pointer;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    right: 50px;
    border-radius: 50%;
  }

  ._1BWD9 img:hover {
    opacity: 1;
  }

  ._1BWD9.RV9w9 img {
    right: 0;
    left: 50px;
    transform: rotate(-180deg);
  }
}

@media (min-width: 1025px) {
  ._6laXA {
    overflow: hidden;
    width: 100%;
    /*height: calc(0.2625 * 100vw);*/
    max-height: 100%;
    position: relative;
  }

  ._1FyLW {
    display: flex;
  }



  ._1BWD9 img {
    position: absolute;
    width: 53px;
    height: 53px;
    margin-top: -35px;
    z-index: 10;
    transition: visibility 1s 0s, opacity .2s ease-in-out;
    opacity: 0.5;
    cursor: pointer;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    right: 50px;
    border-radius: 50%;
  }

  ._1BWD9 img:hover {
    opacity: 1;
  }

  ._1BWD9.RV9w9 img {
    right: 0;
    left: 50px;
    transform: rotate(-180deg);
  }
}

@media (max-width: 768px) {

  ._1FyLW {
    display: flex;
  }

  .LGzPm {
    margin-top: 20px;
  }

  ._6laXA {
    display: block;
    width: 100%;
    padding: 0;
  }

  ._1qaHQ {
    margin-top: 0;
    display: block;
    width: 100%;
  }

  ._1qaHQ img {
    width: 100%;
  }

  .oPi9L {
    padding: 0 21px;
  }

  .oPi9L span {
    padding-left: 0;
  }

  .oPi9L img {
    display: none;
  }

  ._167iF {
    padding: 0;
  }

  .oPi9L .RrlfC {
    padding-left: 0;
  }

  ._2kXwJ {
    display: block;
    padding: 0;
    align-items: center;
    margin-top: 50px;
  }

  ._2kXwJ img {
    width: 100%;
    padding: 28px 21px 0;
  }

  ._2QD12 {
    display: block;
    background: #F8F8F8;
    width: 100%;
    position: relative;
    padding: 0 21px 28px;
    margin-right: 25px;
  }

  ._2QD12 span {
    position: relative;
  }

  ._1BWD9 img {
    position: absolute;
    width: 46px;
    height: 46px;
    margin-top: -35px;
    z-index: 10;
    transition: visibility 1s 0s, opacity .2s ease-in-out;
    opacity: 0.5;
    cursor: pointer;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    right: 25px;
    border-radius: 50%;
  }

  ._1BWD9 img:hover {
    opacity: 1;
  }

  ._1BWD9.RV9w9 img {
    right: 0;
    left: 25px;
    transform: rotate(-180deg);
  }
}

