._2q4Ch {
  padding: 0;
}

.qroa7 a {
  font-family: "Inter", sans-serif;
  text-decoration: none;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1.25rem;
  color: #696969;
}

.qroa7 a:hover {
  color: #EF394F;
}

@media (max-width: 1023px) {
  ._2q4Ch {
    display: none;
  }

  ._2q4Ch.active {
    display: block;
  }

}

