html {
  scroll-behavior: smooth !important;
}

._3Ea8x {
  text-decoration: none;
  transition: color .2s ease-in-out;
}

._3Ea8x:hover {
  color: #EF394F;
}

.mdHwC {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.mdHwC h1 {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.mdHwC ._2hweG {
  display: flex;
}

._2XSBu {
  margin: 0 36px 0 24px;
}

._1373o span {
  font-family: serif;
  font-family: initial;
}

._37rKg {
  display: flex;
}

.yOuRW {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #000000;
  margin-left: 5px;
}

@media (min-width: 1024px) {
  ._3Tdbq ._3zELv {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 222%;
    color: #696969;
    margin: 14px 0 12px 0;
  }

  ._3Tdbq ._3zELv a {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #696969;
  }

  ._3Ea8x {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
    order: 1;
    margin-bottom: 7px;
  }

  ._3Tdbq h1 ._3pPRl {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #222;
    margin: 5px 0 7px 0;
    order: 2;
  }

  ._3Tdbq h1 .GGB4r {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #222;
    order: 3;
  }

  ._3Tdbq .IF1nh {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 222%;
    color: #696969;
    margin: 14px 0 12px 0;
  }

  ._2Aszl {
    display: flex;
    word-wrap: break-word;
  }

  ._2Aszl ._1MLNp {
    min-width: 34px;
    height: 34px;
    background: #F2F2F2;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px 33px 0;
  }

  ._2Aszl p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #A3A3A3;
    max-width: 196px;
    margin-top: 2px
  }

  ._2Aszl ._3pPRl {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #515151;
    margin: 0;
  }


  /*.productOptions .button {*/
  /*  transition: 0.3s;*/
  /*  cursor: pointer;*/
  /*  position: relative;*/
  /*  display: block;*/
  /*  background: #EF394F;*/
  /*  border-radius: 3px;*/
  /*  width: 244px;*/
  /*  height: 48px;*/
  /*  font-family: "Inter", sans-serif;*/
  /*  font-style: normal;*/
  /*  font-weight: 500;*/
  /*  font-size: 14px;*/
  /*  text-align: center;*/
  /*  color: #FFFFFF;*/
  /*  border-width: 0;*/
  /*  box-sizing: border-box;*/
  /*  text-decoration: none;*/
  /*  outline: none;*/
  /*  margin: 0 43px 0 21px;*/
  /*}*/
  /*@keyframes button-anim {*/
  /*  from {*/
  /*    transform: rotate(0);*/
  /*  }*/
  /*  to {*/
  /*    transform: rotate(360deg);*/
  /*  }*/
  /*}*/

  /*.productOptions .button:after {*/
  /*  content: '';*/
  /*  display: block;*/
  /*  width: 1.5em;*/
  /*  height: 1.5em;*/
  /*  position: absolute;*/
  /*  left: 50%;*/
  /*  top: 50%;*/
  /*  margin: -1em;*/
  /*  border: 0.25em solid transparent;*/
  /*  border-right-color: white;*/
  /*  border-radius: 50%;*/
  /*  animation: button-anim 0.7s linear infinite;*/
  /*  opacity: 0;*/
  /*}*/

  /*.productOptions .button.loading {*/
  /*  color: transparent;*/
  /*}*/

  /*.productOptions .button.loading:after {*/
  /*  opacity: 1;*/
  /*}*/

  ._154Ga {
    width: 44px;
    height: 44px;
    border-radius: 20px;
    background: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  ._154Ga img {
    width: 18px;
    height: 18px;
  }


  ._3Tdbq ._2OSN6:last-child {
    margin-right: 0;
  }


  /*.productOptions .variation img {*/
  /*    margin: 0 6px 0 9px;*/
  /*}*/
  ._1ONXV {
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    width: 118px;
    height: 47px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  ._1ONXV div {
    display: flex;
    justify-content: space-between;
    margin: 4px 10px;
  }

  ._1ONXV input:disabled {
    cursor: not-allowed;
  }

  ._1ONXV input {
    font-family: "Apercu Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #A9A9A9;
    border: 0;
    background: transparent;
  }

  ._1ONXV ._1Zk83 {
    font-family: "Apercu Pro", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin: 0 23px;
    width: 100%;
  }


  ._3DJRC {
    display: flex;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  ._2Lyat {
    display: none;
  }
}

@media (max-width: 768px) {
  ._3Tdbq {
    width: 100vw;
  }
  
  ._3Tdbq ._3JiJj {
    display: flex;
    flex-direction: row-reverse;
    width: -moz-fit-content;
    width: fit-content;
  }

  ._3Tdbq ._3zELv {
    display: none;
    order: 3;
  }

  ._3Tdbq ._3lejX {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #000000;
  }

  ._3Tdbq {
    max-width: 500px;

  }

  ._3Ea8x {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
    order: 1;
    margin-bottom: 5px;
    text-decoration: none;
    transition: color .2s ease-in-out;
  }



  ._3Tdbq h1 ._3pPRl {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #000000;
    margin: 5px 0 7px 0;
    font-size: 15px;
    line-height: 16px;
    order: 2;
  }

  ._3Tdbq h1 .GGB4r {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #555;
    font-size: 15px;
    order: 3;
  }

  ._2Aszl {
    display: flex;
    word-wrap: break-word;

  }

  ._2Aszl ._1MLNp {
    min-width: 34px;
    height: 34px;
    background: #F2F2F2;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px 33px 0;
  }

  ._2Lyat {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #A3A3A3;
    margin-bottom: 44px;
  }

  ._2Aszl p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #A3A3A3;
    max-width: 100px;
    margin-top: 2px
  }

  ._2Aszl .mhCHa {
    display: none
  }

  ._2Aszl ._3pPRl {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #515151;
    margin: 0;
  }

  /*.productOptions .blockButtons button {*/
  /*  background: #EF394F;*/
  /*  border-radius: 3px;*/
  /*  width: 218px;*/
  /*  font-family: "Inter", sans-serif;*/
  /*  font-style: normal;*/
  /*  font-weight: 500;*/
  /*  font-size: 14px;*/
  /*  text-align: center;*/
  /*  color: #FFFFFF;*/
  /*  border-width: 0;*/
  /*  display: block;*/
  /*  box-sizing: border-box;*/
  /*  text-decoration: none;*/
  /*  cursor: pointer;*/
  /*  outline: none;*/
  /*  margin: 0 0 0 21px;*/
  /*}*/

  ._3DJRC button {
    width: 218px;
  }

  ._154Ga {
    display: none;
  }

  ._154Ga img {
    display: none;
  }


  ._3Tdbq ._2OSN6:last-child {
    margin-right: 0;
  }


  /*.productOptions .variation img {*/
  /*    margin: 0 6px 0 9px;*/
  /*}*/
  ._1ONXV {
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    width: 86px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  ._1ONXV div {
    display: flex;
    justify-content: space-between;
    margin: 4px 10px;
  }

  ._1ONXV input:disabled {
    cursor: not-allowed;
  }

  ._1ONXV input {
    font-family: "Apercu Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #A9A9A9;
    border: 0;
    background: transparent;
  }

  ._1ONXV ._1Zk83 {
    font-family: "Apercu Pro", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin: 0 23px;
    width: 100%;
  }

  .zzZay {
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    width: 117px;
    height: 47px;
    display: flex;
    align-items: center;
    margin-right: 27px;
    cursor: pointer;
    position: relative;
  }

  ._3DJRC {
    display: flex;
    margin-bottom: 11px;
    margin-top: 33px;
    justify-content: space-between;
  }

  ._3Tdbq .EzdpU {
    display: flex;
    flex-direction: column;
    padding: 0 21px;
  }

  ._33fmP {
    padding: 0 21px;
  }
  ._2XSBu {
    margin: 0;
  }
}
