._9-Un8 {
  margin: 48px auto;
  max-width: 1200px;
  box-sizing: border-box;
}

.-itW7 {
  margin: 72px auto;
  position: relative;
}

.-itW7 p {
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #696969;
}

@media (min-width: 1024px) {
  ._14UDt {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 48px;
    grid-row-gap: 48px;
  }

  ._34vas {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  ._14UDt {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 12px;
    grid-row-gap: 26px;
  }

  ._34vas {
    display: flex;
    justify-content: center;
  }

  ._9-Un8 {
    margin: 20px auto 40px;
    max-width: 1440px;
    box-sizing: border-box;
    padding: 0 18px;
    flex-wrap: wrap;
  }

  .sGpyB {
    display: inline-flex;
    margin-top: 26px;
  }

  .sGpyB > a {
    margin-right: 0;
    width: 100%;
    display: inline-block;
  }

  .sGpyB:nth-child(2n) > div {
    margin: 0;
  }
}


@media (min-width: 768px) and (max-width: 1024px) {
  ._14UDt {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 28px;
    grid-row-gap: 26px;
  }

  ._9-Un8 {
    margin: 47px auto 40px;
    max-width: 1440px;
    box-sizing: border-box;
    /*padding: 0 84px 0 120px*/
    padding: 0 22px;
    flex-wrap: wrap;
  }

  .sGpyB {
    display: inline-flex;
    margin-top: 26px;
  }

  .sGpyB div {
    margin-right: 0;
    width: 100%;
  }

  .sGpyB:nth-child(2n) > div {
    margin: 0;
  }
}


