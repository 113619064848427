._2FfVV {
  max-width: 1440px;
  margin: 0 auto 90px;
  padding: 0 120px;
  display: grid;
  grid-template-columns: repeat(6,1fr);
}

._2FfVV img {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

._2FfVV a {
  background-color: #FAFAFA;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

@media(max-width: 1023px) {
  ._2FfVV {
    padding: 0 21px;
    overflow: auto;
    margin-bottom: 45px;
  }

  ._2FfVV a {
    width: 130px;
    height: 130px;
    padding: 0;
  }

  ._2FfVV a:last-child {
    border-right: 21px solid #FFFFFF;
    box-sizing: content-box;
  }
}
