._3PN7a {
  max-width: 1440px;
  margin: auto;
  padding: 50px 120px;
}

._2i4q3 span {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 95%;
  color: #000000;
}

._2i4q3 p {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 95%;
  color: #000000;
}

._3WWJ7 {
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  display: block;
}

._3WWJ7 p {
  margin-top: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 160%;
  color: #000000;
}

._146ko {
  display: grid;
}
