._3h8jR {
    width: 100%;
}


._3h8jR h1 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #A6A6A6;
}

._1JJoT {
  position: relative;
  display: inline-block;
}

._2_9RI {
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: #ffffff;
  min-width: 150px;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;
}

._2_9RI a {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
  margin: 6px 0;
}

._1JJoT:hover ._2_9RI {
  display: flex;
}

._1JJoT:hover span {
  font-weight: bold;
}

._39FbZ a {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #696969;
}

._39FbZ {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 0;
  text-align: right;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #696969;
  cursor: pointer;
}
._2lVib {
  display: none;
}

.rfLWO {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
}

._3Ecff {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #8E8E8E;
}

._3hwJ- {
    background: #F8F8F8;
    border: 1px solid #FAFAFA;
    box-sizing: border-box;
    padding: 20px 40px;
    width: 100%;
}

._2ehvl {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #8E8E8E;

}

._3hwJ- h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

._3Eeu3 {
    display: flex;
}

.k-xXt {
    display: flex;
    flex-direction: column;
}

.k-xXt:not(:last-child) {
    margin-right: 50px;
}

._196pt div {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #A6A6A6;
  margin-bottom: 5px;
}

._196pt div:first-child {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  color: #000000;
}

._196pt div:last-child {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

._1pGoj {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #A6A6A6;
    margin-bottom: 8px;
}


.k-xXt ._3ZGWD {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #696969;
}

._35MbP {
    margin-top: 16px;
}

._2aTSR {
    width: 100%;
    height: 1px;
    margin: 50px 0 20px 0;
    background-color: #E9E9E9;
}

.Ndjaj {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
}

.Ndjaj a {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #696969;
}

.Ndjaj div {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #696969;
}

._3A1CT {
    display: flex;
    justify-content: space-evenly;
    margin-top: 44px;
}

._3lr9j {
    margin-top: 16px;
}

._4VZFY {
    display: flex;
    flex-wrap: nowrap;
}

.Mez_4 {
    background: #FFFFFF;
    width: 361px;
    height: 92px;
    padding: 10px 0 10px 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Mez_4 img {
    width: 40px;
    height: 65px;
    margin-right: 10px;
}

.Mez_4 ._1-xWV {
    display: flex;
    flex-direction: column;
}



.Mez_4 ._1-xWV ._1pGoj {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
    margin-bottom: 5px;
}

@media(max-width: 768px) {
  ._3A1CT {
    flex-direction: column;
  }

  ._3A1CT a {
    height: 100%;
  }

  ._3A1CT a:first-of-type {
    margin-bottom: 10px;
  }

  ._3hwJ- {
    overflow-y: hidden;
  }

  ._3Eeu3 .k-xXt:first-of-type {
    display: none;
  }

  ._3Eeu3 .k-xXt:last-of-type {
    display: none;
  }

  ._1M7sA {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ._3hwJ- h2 {
    margin: 0;
  }

  ._2lVib {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #696969;
    display: flex;
  }

  ._3Eeu3 {
    margin-top: 10px;
  }

  .rfLWO {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 158.8%;
    color: #696969;
  }

  ._1pGoj {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 158.8%;
    color: #939393;
    white-space: pre;
  }

  .Ndjaj div {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }
}
