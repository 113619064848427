._1LWiT {
  margin: 96px 0;
}

._3itbL:hover,
._3itbL.active {
  background: #F5F5F5;
}

._3itbL {
  display: flex;
  margin: 0 12px;
  justify-content: center;
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  padding: 8px 0;
  width: 254px;
  height: 40px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

@media (max-width: 768px) {
  ._1LWiT {
    margin: 24px 0;
  }

  ._3itbL {
    font-size: 16px;
  }
}


