@media (min-width: 1024px) {
  .dpeSP {
    background-color: #FAFAFA;
    position: relative;
  }

  .dpeSP ._11J9P {
    max-width: 1440px;
    margin: auto;
    padding-left: 45px;
    position: relative;
  }

  ._11J9P ._218Nv {
    display: flex;
  }

  ._3P66J {
    display: list-item;
    list-style: none;
  }

  ._11J9P ._1KnUO {
    padding: 16px 0 16px 75px;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    width: 465px;
    margin-right: 114px;
  }

  ._2DfAe {
    display: flex;
    align-items: center;
  }

  ._3Zf37 {
    position: relative;
    cursor: pointer;
  }

  ._3Zf37 ._38UC3 {
    right: 0;
  }

  ._2DfAe .xSxOH {
    height: 100%;
    background: #FAFAFA;
    cursor: pointer;
    white-space: nowrap;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  ._2DfAe .xSxOH.C76Fk {
    background: #F3F3F3;
  }

  ._2DfAe .xSxOH.C76Fk span {
    text-shadow: 0 0 0.01px #696969;
  }

  .xSxOH:hover {
    background: #F3F3F3;
  }
  .xSxOH span::before {
    display: block;
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  .xSxOH span {
    display: inline-block;
    margin-right: 10px;
    margin-left: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
    font-weight: normal;
  }

  ._2DfAe .xSxOH img {
    margin-right: 20px;
    opacity: 0.5;
    transform: rotate(-180deg);
  }

  ._2DfAe .xSxOH.C76Fk img {
    transform: rotate(0);
    opacity: 1;
  }

  ._38UC3 {
    position: absolute;
    top: 120%;
    opacity: 0;
    visibility: hidden;
    z-index: 998;
    background: #FAFAFA;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    width: 313px;
    max-height: 216px;
    overflow: auto;
    overflow-x: hidden;
    padding: 15px 9px 15px 15px;
  }

  ._38UC3._3ukaG {
    width: 378px;
    height: 138px;
  }

  ._38UC3._33emP {
    width: 382px;
    height: 312px;
  }


  ._38UC3._3ukaG .C76Fk {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }

  ._38UC3.C76Fk {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }


  ._3HLQv {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 4px;
    /*width: 338px;*/
    height: 104px;
    margin: 2px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .BhPsr ._1AUyD {
    width: 20px;
    height: 20px;
    border-radius: 26px;
    overflow: hidden;
    top: 50%;
    background: #696969;
    margin-left: 20px;

  }

  .BhPsr._30MMv {
    position: absolute;
    right: 35px;
    top: 23%;
  }

  .BhPsr._2SBXK {
    position: absolute;
    left: 35px;
    top: 23%;
  }

  .BhPsr div {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 3px;
    width: 65px;
    height: 27px;
    top: 30%;
  }

  .BhPsr span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #696969;
    margin: 6px 8px;
  }

  ._21iCi {
    padding-top: 18px;
    margin-right: 17px;
  }

  ._21iCi span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #696969;
    cursor: pointer;
  }

  ._1yupF {
    background: #FFFFFF;
    border-radius: 3px;
    margin-bottom: 6px;
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-height: 42px;
  }

  ._1yupF:hover span {
    color: #EF394F;

  }


  ._1yupF span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._1yupF.C76Fk span {
    font-weight: 600;
    color: #000000;
  }


  ._39nyX {
    display: flex;
    position: absolute;
    right: 0;
    top: 14%;
    margin-right: 20px;
  }

  ._2f5ex {
    display: block;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-left: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    outline: none;
  }


  /* Test With Range Slider Price */
  ._3zhah {
    position: relative;
    width: 100%;
    max-width: 200px;
  }

  ._2o4Rf {
    position: relative;
    z-index: 1;
    height: 10px;
    margin: 0 15px;
  }

  ._2o4Rf > .m2qwM {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #c6aee7;
  }

  ._2o4Rf > ._3CzG3 {
    position: absolute;
    z-index: 2;
    left: 25%;
    right: 25%;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #6200ee;
  }

  ._2o4Rf > ._3oRy9 {
    position: absolute;
    z-index: 3;
    width: 30px;
    height: 30px;
    background-color: #6200ee;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(98, 0, 238, .1);
    transition: box-shadow .3s ease-in-out;
  }

  ._2o4Rf > ._3oRy9._2SBXK {
    left: 25%;
    transform: translate(-15px, -10px);
  }

  ._2o4Rf > ._3oRy9._30MMv {
    right: 25%;
    transform: translate(15px, -10px);
  }

  ._2o4Rf > ._3oRy9._127Rf {
    box-shadow: 0 0 0 20px rgba(98, 0, 238, .1);
  }

  ._2o4Rf > ._3oRy9.C76Fk {
    box-shadow: 0 0 0 40px rgba(98, 0, 238, .2);
  }

  input[type=range] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 10px;
    width: 100%;
    opacity: 0;
  }

  input[type=range]::-webkit-slider-thumb {
    pointer-events: all;
    width: 30px;
    height: 30px;
    border-radius: 0;
    border: 0 none;
    background-color: red;
    -webkit-appearance: none;
  }
}


@media (max-width: 768px) {
  .dpeSP {
    background-color: #FAFAFA;
    position: relative;
  }

  .dpeSP ._11J9P {
    max-width: 1440px;
    margin: auto;
    padding-left: 45px;
    position: relative;
  }

  ._11J9P ._218Nv {
    display: flex;
  }

  ._3P66J {
    display: list-item;
    list-style: none;
  }

  ._11J9P ._1KnUO {
    padding: 16px 0 16px 75px;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    width: 465px;
    margin-right: 114px;
  }

  ._2DfAe {
    display: inline-block;
    width: 100%;
  }

  ._3Zf37 {
    position: relative;
    padding: 0 21px;
    border-bottom: 1px solid #E1E1E1;
  }

  ._3Zf37 ._38UC3 {
    right: 0;
  }

  ._2DfAe .xSxOH {
    height: 100%;
    background: #fafafa;
    cursor: pointer;
    display: flex;
  }

  ._2DfAe .xSxOH.C76Fk {
    background: #F3F3F3;
  }

  ._2DfAe span {
    margin-right: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    color: #696969;
    white-space: nowrap;
  }

  ._2DfAe img {
    margin-right: 20px;
  }

  /*.filters .filterButton.active img {*/
  /*  transform: rotate(-180deg);*/
  /*}*/

  ._2DfAe .xSxOH.C76Fk img {
    transform: rotate(0);
    opacity: 1;
  }

  ._2DfAe ._38UC3 {
    position: absolute;
    top: 120%;
    opacity: 0;
    visibility: hidden;
    z-index: 998;
    background: #FAFAFA;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    width: 313px;
    height: 216px;
    overflow: auto;
    padding-bottom: 15px;
    padding-top: 15px;
    text-align: center;
  }

  ._38UC3._3ukaG {
    width: 378px;
    height: 138px;
  }

  ._38UC3._33emP {
    width: 382px;
    height: 312px;
  }


  ._38UC3._3ukaG .C76Fk {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }

  ._38UC3.C76Fk {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }


  ._3HLQv {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 4px;
    /*width: 338px;*/
    height: 104px;
    margin: 2px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .BhPsr ._1AUyD {
    width: 20px;
    height: 20px;
    border-radius: 26px;
    overflow: hidden;
    top: 50%;
    background: #696969;
    margin-left: 20px;

  }

  .BhPsr._30MMv {
    position: absolute;
    right: 35px;
    top: 23%;
  }

  .BhPsr._2SBXK {
    position: absolute;
    left: 35px;
    top: 23%;
  }

  .BhPsr div {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 3px;
    width: 65px;
    height: 27px;
    top: 30%;
  }

  .BhPsr span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #696969;
    margin: 6px 8px;
  }

  ._21iCi {
    padding-top: 18px;
    margin-right: 17px;
  }

  ._21iCi span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #696969;
    cursor: pointer;
  }

  ._1yupF {
    background: #FFFFFF;
    border-radius: 3px;
    margin: 0 9px 6px 15px;
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-height: 42px;
  }

  ._1yupF:last-child {
    margin: 0 9px 0 15px;
  }

  ._1yupF span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._1yupF.C76Fk span {
    font-weight: 600;
    color: #000000;
  }


  ._39nyX {
    display: flex;
    position: absolute;
    right: 0;
    top: 14%;
    margin-right: 10px;
  }

  ._2f5ex {
    display: block;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 339px;
    height: 44px;
    margin: 5px 0 13px 15px;
    padding-left: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    outline: none;
  }

  /* Test With Range Slider Price */
  ._3zhah {
    position: relative;
    width: 100%;
    max-width: 500px;
  }

  ._2o4Rf {
    position: relative;
    z-index: 1;
    height: 10px;
    margin: 0 15px;
  }

  ._2o4Rf > .m2qwM {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #c6aee7;
  }

  ._2o4Rf > ._3CzG3 {
    position: absolute;
    z-index: 2;
    left: 25%;
    right: 25%;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #6200ee;
  }

  ._2o4Rf > ._3oRy9 {
    position: absolute;
    z-index: 3;
    width: 30px;
    height: 30px;
    background-color: #6200ee;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(98, 0, 238, .1);
    transition: box-shadow .3s ease-in-out;
  }

  ._2o4Rf > ._3oRy9._2SBXK {
    left: 25%;
    transform: translate(-15px, -10px);
  }

  ._2o4Rf > ._3oRy9._30MMv {
    right: 25%;
    transform: translate(15px, -10px);
  }

  ._2o4Rf > ._3oRy9._127Rf {
    box-shadow: 0 0 0 20px rgba(98, 0, 238, .1);
  }

  ._2o4Rf > ._3oRy9.C76Fk {
    box-shadow: 0 0 0 40px rgba(98, 0, 238, .2);
  }

  input[type=range] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 10px;
    width: 100%;
    opacity: 0;
  }

  input[type=range]::-webkit-slider-thumb {
    pointer-events: all;
    width: 30px;
    height: 30px;
    border-radius: 0;
    border: 0 none;
    background-color: red;
    -webkit-appearance: none;
  }
}

