._5gNud > div {
    flex: auto;
}

.HMGKV {
    margin: 40px auto;
    width: 1200px;
}

._129pl {
    width: 580px;
    height: 104px;
}

._129pl span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 43px;
    line-height: 51px;
    color: #FFFFFF;

}

._129pl._2OhPg span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    font-size: 13px;
    line-height: 202%;
}