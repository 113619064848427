._1vfNA {
  display: none;
}

._21uVV {
  margin: 72px auto 24px;
}

@media (max-width: 768px) {
  ._2dyxG {
    max-width: 1440px;
    margin: auto;
    /*padding: 0 0 0 120px;*/
    position: relative;
  }

  ._2EI7i {
    background: #FAFAFA;
  }

  ._1vfNA {
    display: flex;
    padding: 12px 21px 30px 21px;
    justify-content: space-between;
  }

  .SSVeD a {
    margin-left: 26px;
  }
}

