.tbGjS {
  background-color: #f6b0ac;
}

._2DEKa {
  background-size: cover;
  vertical-align: top;
}

._2DEKa.fixedHeight {
  height: calc(201 / 1116 * 100vw);
}

@media (max-width: 768px) {
  ._2DEKa:not(.fixedHeight) {
    height: auto;
  }

  ._2VCjQ ._1Cuna {
    display: none;
  }
}

._2VCjQ {
  max-width: 1440px;
}

._18nro {
  width: 1200px;
  margin: auto;
}

@media (min-width: 1024px) {
  .hO3cx {
    overflow: hidden;
    width: 100%;
    position: relative;
  }

  .hO3cx > div {
    flex: auto;
  }

  ._2VCjQ ._1-nJV {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    color: #ffffff;
    margin: 0;
    padding-top: 36px;
    text-shadow: 0 0 60px rgba(0, 0, 0, 0.7);
  }

  ._2VCjQ ._1Cuna {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #fff;
    line-height: 180%;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
    max-width: 600px;
    padding: 10px 0 36px;
    margin: 0;
  }

  ._2VCjQ ._1zYpw {
    color: #000000;
  }
}

@media (max-width: 768px) {
  ._18nro {
    width: 100%;
  }

  .hO3cx {
    overflow: hidden;
    width: 100%;
    position: relative;
  }

  .hO3cx > div {
    flex: auto;
  }

  ._2VCjQ ._1-nJV {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    color: #ffffff;
    margin: 0;
    padding: 18px 0 17px 22px;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  }

  ._2VCjQ ._1Cuna {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 202%;
    color: #ffffff;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    padding: 0 22px 17px 22px;
    margin: 0;
  }

  ._2VCjQ ._1zYpw {
    color: #000000;
  }

  ._1jK5T {
    display: none;
  }
}

.fNWqn {
  padding: 0 0 48px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  grid-gap: 24px;
  gap: 24px;
}

.fNWqn li {
  padding: 0;
}

.fNWqn h2 {
  margin: 0;
}

.fNWqn a {
  border: 1px solid #524273;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  overflow: hidden;
  background-color: #fff;
  text-decoration: none;
  color: #13032f;
  font-size: 0;
  display: block;
  transition: box-shadow 0.2s ease-in-out;
}

.fNWqn a:hover {
  box-shadow: 0 0 20px rgba(97, 45, 106, 0.8);
}

.fNWqn a > img {
  width: 100%;
  height: auto;
}

.fNWqn a > span {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

@media screen and (max-width: 768px) {
  .fNWqn {
    overflow: auto;
    padding: 0 22px 18px;
  }

  .fNWqn a {
    width: 180px;
  }

  .fNWqn a > span {
    padding: 6px 12px;
  }
}
