@media(min-width: 1025px) {
  .zvpc6 {
    margin-top: 20px;
    margin-right: 50px;
    margin-left: -400px;
  }

  .riHQN {
    min-width: 660px;
    max-width: 660px;
    margin-top: 50px;
    margin-right: 100px;
    margin-left: 80px;
  }

  ._15Yvn {
    vertical-align: top;
    display: flex;
    justify-content: space-between;
  }

  ._15Yvn a {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #696969;
    text-decoration: none;
  }
}

@media(max-width: 768px) {

  .riHQN {
    margin-top: 50px;
  }

  ._15Yvn {
    vertical-align: top;
    display: flex;
    justify-content: space-between;
  }

  ._15Yvn a {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #696969;
    text-decoration: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .zvpc6 {
    margin: 20px 20px 0 20px;
    width: 100%;
  }

  .riHQN {
    max-width: 660px;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 30px;
  }
  ._15Yvn {
    vertical-align: top;
    display: flex;
    justify-content: space-between;
  }

  ._15Yvn a {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #696969;
    text-decoration: none;
  }

}

._1vfSO {
  display: inline-block;
  white-space: nowrap;
}

._1vfSO img {
  width: 42px;
  margin: 0 5px;
}

._15Yvn {
  align-items: center;
}

@media(max-width: 768px) {
  ._1vfSO img {
    width: 32px;
    margin: 0 4px;
  }
}