.u0FE2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._2gwoX {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

._3gJuk {
  width: 100%;
  margin: 13px 0 25px 0;
  border: 1px solid #E9E9E9;
}

._2gwoX span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #0AB06A;
}

._2gwoX span:last-child {
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #696969;
}

._2gwoX ._AjeP {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #696969;
}

._2gwoX ._AjeP span {
  font-weight: bold;
}


.R1KUa {
  /*margin-left: 29px;*/
  /*margin-right: 68px;*/
  display: flex;
  justify-content: space-between;
  /*margin-top: 22px;*/
}

.R1KUa span {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  color: #000000;
}

.R1KUa span:first-child {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #A6A6A6;
}

._19SNG {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #A6A6A6;
  margin-bottom: 15px;
}

._2gwoX progress {
  width: 100%;
  height: 5px;
  margin-top: 11px;
}

._2gwoX progress {
  -webkit-appearance: none;
}

._2gwoX progress::-webkit-progress-value {
  background-color: #ef394f;
  border-radius: 8px;
}

._2gwoX progress::-webkit-progress-bar {
  background-color: #E9E9E9;
  border-radius: 8px;
}

._2gwoX ._1vd4c {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

._2gwoX ._1JGOd {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

._2_0Ns {
  width: 100%;
  height: 1px;
  margin: 14px 28px 14px 0;
  background-color: #E9E9E9;
}

._2zc8d {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
}


@media (min-width: 1024px) {
  ._2gwoX ._1h4w9 {
    text-align: center;
    margin: 57px 0 10px 0;
    justify-content: center;
    display: block;
  }

  ._2gwoX ._3iCvN {
    text-align: center;
    margin: 57px 0 10px 0;
    justify-content: space-between;
    display: flex;
  }


  ._2gwoX ._3iCvN a:first-child {
    width: 226px;
  }


  ._2gwoX ._3iCvN button {
    width: 191px;
  }

  ._2gwoX ._3iCvN button:hover {
    width: 191px;
  }

  .FbD62 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 18px;
    grid-row-gap: 12px;
  }

  .FbD62 > a:last-child {
    grid-column: span 2;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  ._2gwoX ._1h4w9 {
    text-align: center;
    margin: 32px 0 10px 0;
    justify-content: center;
    display: block;
  }

  ._2gwoX ._3iCvN {
    text-align: center;
    margin: 32px 0 10px 0;
    justify-content: center;
    display: block;
  }

  ._2gwoX ._3iCvN a:first-child {
    width: 175px;
  }

  ._2gwoX ._3iCvN button {
    width: 135px;
  }

  .FbD62 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .FbD62 > a:last-child {
    grid-column: span 2;
  }
}

@media(max-width: 768px) {
  ._3iCvN {
    display: flex;
    flex-direction: column;
  }

  ._3iCvN a:first-child {
    margin: 20px 0;
  }

  ._1h4w9 a {
    margin: 20px 0;
  }
}
