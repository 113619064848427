._2zjx3 div {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 222%;
  color: #000000;
}

._2zjx3 ._2ncd2 span {
  color: #22BC7B;
}

._2zjx3 ._2YVHp {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
  -moz-column-gap: 12px;
       column-gap: 12px;
}
@media (min-width: 1025px) {
  .s2ZTN {
    display: inline-block;
    vertical-align: top;
  }

  .s2ZTN ._1qvDK {
    display: inline-block;
  }

  .s2ZTN {
    width: 62.5%;
    padding: 0 140px 28px 90px;
    position: relative;
  }

  .s2ZTN.cPyi5 {
    padding: 0 0 0 42px;
  }

  ._2zjx3 {
    display: flex;
  }

  ._2zjx3 ._2ncd2 {
    display: block;
    margin-left: 58px;
  }


  ._2zjx3 ._2YVHp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
    -moz-column-gap: 12px;
         column-gap: 12px;
  }

  .s2ZTN:before {
    position: absolute;
    content: '';
    top: 20px;
    left: -6%;
    right: 0;
    bottom: 0;
    background: #F8F8F8;
    z-index: -1;
  }

  .s2ZTN.cPyi5:before {
    position: absolute;
    content: '';
    top: 20px;
    left: 0;
    right: -40%;
    bottom: 8%;
    background: #F8F8F8;
    z-index: -1;
  }

  .s2ZTN h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 31px;
    color: #000000;
    margin: 0;
    vertical-align: top;
  }

  .s2ZTN.cPyi5 h2 {
    font-size: 47px;
    line-height: 55px;
    position: absolute;
    white-space: pre;
  }


  .s2ZTN ._2Wlpw {
    font-family: "TT Norms", sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 17px;
    color: #696969;
  }

  .s2ZTN p {
    line-height: 1.7;
    color: #333;
    font-family: Inter,sans-serif;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 1.5em;
  }

  .s2ZTN.cPyi5 p {
    padding-right: 31px;
    margin-top: 80px;
  }
}


@media (max-width: 1023px) {
  .s2ZTN {
    display: inline-block;
    vertical-align: top;
  }

  .s2ZTN ._1qvDK {
    display: inline-block;
  }

  .s2ZTN {
    width: 13.5%;
    padding: 0 140px 28px 21px;
    position: relative;
  }

  .s2ZTN:before {
    position: absolute;
    content: '';
    top: 3%;
    left: 0;
    right: -140%;
    bottom: 0;
    background: #F8F8F8;
    z-index: -1;
  }

  .s2ZTN h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    color: #000000;
    margin: 0;
    vertical-align: top;
  }

  .s2ZTN ._2Wlpw {
    font-family: "TT Norms", sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 14px;
    color: #696969;
    margin-top: 4px;
  }

  .s2ZTN p {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 178%;
    color: #000000;
    margin-top: 0;
    width: 330px;
  }

  .s2ZTN.cPyi5 {
    padding: 0 21px 0 21px;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .s2ZTN {
    display: inline-block;
    vertical-align: top;
  }

  .s2ZTN ._1qvDK {
    display: inline-block;
  }

  .s2ZTN {
    width: 62.5%;
    padding: 0 160px 28px 60px;
    position: relative;
  }

  .s2ZTN:before {
    position: absolute;
    content: '';
    top: 20px;
    left: -6%;
    right: 0;
    bottom: 0;
    background: #F8F8F8;
    z-index: -1;
  }

  .s2ZTN h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 31px;
    color: #000000;
    margin: 0;
    vertical-align: top;
  }

  .s2ZTN ._2Wlpw {
    font-family: "TT Norms", sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 17px;
    color: #696969;
    margin-top: 4px;
  }

  .s2ZTN p {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 185%;
    color: #000000;
    margin-top: 16px;
  }

  .s2ZTN.cPyi5 {
    padding: 0 21px 0 21px;
  }
}


