._3koFn {
    margin: 47px auto 40px;
    max-width: 1440px;
    box-sizing: border-box;
    padding: 0 120px;
    display: flex;
    position: relative;
}

._3dGWh {
    margin-right: 50px;
    cursor: pointer;
    display: flex;
    flex: 1;
    position: relative;
}

._3dGWh:last-child {
    margin-right: 0;
}

._3dGWh ._3ZrgJ {
    background: rgba(255, 255, 255, 0.7);
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    position: absolute;
    z-index: 2;
    top: 66%;
    width: 100%;
    height: 91px;
    align-items: center;
    justify-content: center;
    display: block;
}

._3dGWh ._3ZrgJ h1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    padding-top: 8px;
    margin: 0;
}

._3dGWh ._3ZrgJ p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    padding: 5px 20px 0 20px;
    margin: 0;
}