@media(min-width: 1024px) {
    .q6erq {
        display: block;
    }

    ._228fw {
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        box-sizing: border-box;
        border-radius: 3px;
        width: 330px;
        margin-right: 24px;
        display: flex;
        padding: 20px;
        justify-content: space-between;
    }


    ._2NU-r p {
        margin: 0;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
    }

    ._2NU-r span {
        margin: 0;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
    }

    .ZHh5h:first-child {
        margin-bottom: 5px;
    }

    .ZHh5h img {
        cursor: pointer;
    }

    .q6erq h1 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        margin: 0 0 10px 0;
    }

    ._1XY9q {
        display: flex;
    }
}

@media(max-width: 1023px) {
    .q6erq {
        display: flex;
        flex-direction: column;
    }

    ._228fw {
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        box-sizing: border-box;
        border-radius: 3px;
        margin-bottom: 10px;
        display: flex;
        padding: 20px;
        justify-content: space-between;
    }


    ._2NU-r p {
        margin: 0;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
    }

    ._2NU-r span {
        margin: 0;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
    }

    .ZHh5h:first-child {
        margin-bottom: 5px;
    }

    .ZHh5h img {
        cursor: pointer;
    }

    .q6erq h1 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        margin: 0 0 10px 0;
    }

    ._1XY9q {
        display: block;
    }
}
