._33svU {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 0 24px 0 48px;
  align-items: center;
  border: 1px solid #A6A6A6;
  border-radius: 3px;
  line-height: 46px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: .1s ease-in-out;
}

.hYoBw {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-right: 48px;
}

.nVIRG[type="radio"]:checked + ._33svU  {
  background: #FBFBFB;
  border: 1px solid #777777;
}

.nVIRG[type="checkbox"] {
  margin-right: 16px;
}

.nVIRG[type="radio"] {
  display: inline-block;
  margin: 18px 0 0 20px;
  padding: 0;
  vertical-align: top;
  position: absolute;
  transition: all .2s ease-in-out;
}

.nVIRG[type="radio"]:after {
  background-color: #000000;
  content: " ";
}

.nVIRG[type="radio"]:checked:after {
  background-color: #ffa500;
  content: " ";
}

._2ob1z {
  display: flex;
  justify-content: flex-end;
  font-family: 'TT Norms', sans-serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #696969;
  transition: .2s ease-in-out;
}

._28Z7t {
  display: flex;
  justify-content: flex-end;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #7A7A7A;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  transition: .2s ease-in-out;
}

.ewWmt {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 46px;
  color: #000000;
  width: 100%;
  transition: .2s ease-in-out;
}

._2y1GA {
  display: flex;
  width: 150%;
  align-items: center;
}

.nVIRG[type="radio"]:checked + ._33svU ._2ob1z  {
  color: #000000
}

.nVIRG[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 13px;
  height: 13px;
  padding: 1px;
  /* background-color only for content */
  background-clip: content-box;
  outline: none;
  border: 1px solid #000000;
  background-color: #ffffff;
  border-radius: 50%;
}


.nVIRG[type='radio']:checked {
  background-color: #ef394f;
}

._33svU:hover {
  background: #FBFBFB;
  border-color: #696969;
}

@media (max-width: 768px) {
  .hYoBw {
    flex-direction: column;
    margin: 0;
  }

  ._33svU {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .ewWmt {
    line-height: 20px;
  }

  ._2y1GA {
    display: block;
  }

  ._28Z7t {
    display: block;
  }

  .nVIRG[type="radio"] {
    margin: 25px 16px 0 20px;
  }
}
