@media(min-width: 1024px) {
    .dAnIY {
        width: 530px;
        padding: 30px 44px;
    }
    ._3aOyL {
        width: 510px;

    }
}

@media(max-width: 1023px) {
    .dAnIY {
        width: 100%;
        padding: 30px 24px;
    }
    ._3aOyL {
        width: 100%;

    }
}


.dAnIY {
    overflow-x: hidden;
    display: flex;
    background-color: #FFFFFF;
    position: fixed;
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    z-index: 1000;
    transition-property: transform;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4,0,1,1);
    transform: translateX(0);
    box-sizing: border-box;
}

._3aOyL {
    overflow-x: hidden;
    display: flex;
    background-color: #FFFFFF;
    position: fixed;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    z-index: 1000;
    transition-property: transform;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.0,0,0.2,1);
    transform: translateX(100%);
    box-sizing: border-box;
}
