@media(min-width: 1024px) {

  ._1oYqA span {
    margin-right: 10px;
    margin-left: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
  }
  ._1oYqA {
    height: 55px;
    display: flex;
    align-items: center;
    background: #FAFAFA;
    cursor: pointer;
    padding-right: 10px;
  }

  ._1oYqA.active {
    background: #F3F3F3;
  }

  ._1oYqA.active img {
    transform: rotate(-180deg);
  }


  ._2Ub3r {
    position: absolute;
    display: none;
    z-index: 998;
    background: #FAFAFA;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    left: 56%;
    width: 378px;
    height: 138px;
    padding: 15px;
  }

  ._2Ub3r.active {
    display: block;
  }

  ._2Ub3r .rheostat button {
    width: 20px;
    height: 20px;
    border: 1px solid #d8d8d8;
    background: #696969;
    border-radius: 50%;
    outline: none;
    z-index: 2;
    box-shadow: 0 2px 2px #dbdbdb;
    margin-left: -12px;
    top: -5px;
  }

  ._2Ub3r .rheostat {
    margin: 30px;
  }

  ._2Ub3r .rheostat button:after {
    display: none;
  }

  ._2Ub3r .rheostat button:before {
    display: none;
  }

  ._2Ub3r .DefaultProgressBar_progressBar  {
    background-color: #C4C4C4;
  }

  ._2Ub3r .DefaultProgressBar_background__horizontal {
    height: 6px;
  }

  ._2Ub3r .DefaultBackground {
    display: none;
  }


}

@media(max-width: 1023px) {

  ._1yBYm {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 18px;
    grid-row-gap: 6px;
    white-space: nowrap;
  }

  .C7Vj9 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin-top: 16px;
  }


  ._2Ub3r {
    position: absolute;
    top: 120%;
    display: none;
    z-index: 998;
    background: #FAFAFA;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    width: 313px;
    height: 216px;
    overflow: auto;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  ._2Ub3r.active {
    display: block;
  }
}

