._3sw8s {
  text-align: center;
  padding: 51px 170px 50px;
  width: 1440px;
  margin: auto;
  display: block;
  align-items: center;
  flex-direction: column;
}

._3sw8s ._2G5Ei {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
  margin: 25px 0 10px 0;
}

._3sw8s p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
  margin-bottom: 15px;
}

._3sw8s form {
  width: 400px;
}

.KoWdE {
  display: flex;
  align-items: center;
  justify-content: center;
}

.KoWdE input {
  margin-bottom: 15px;
}

.KoWdE textarea {
  background: #FFFFFF;
  border: 1px solid #777777;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #A9A9A9;
  margin-bottom: 10px;
  padding: 15px 20px;
  outline: none;
  width: 100%;
}

@media(max-width: 768px) {
  ._3sw8s {
    padding: 0 21px;
    width: 100%;
    display: block;
  }

  ._3sw8s form {
    width: 100%;
  }
}
