@keyframes _15BEO {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

._2a549 {
  transition: 0.3s;
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 0 40px;
  background: transparent;
  border-radius: 3px;
  height: 48px;
  line-height: 48px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  border: 1px solid #000000;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  color: #FFFFFF;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
}

._2a549._1BiQk {
  color: #FFF;
  border-color: #FFF;
}

._2a549:disabled {
  color: #9C9C9C;
  background: #EEE;
  cursor: not-allowed;
}

._2a549:after {
  content: '';
  display: block;
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -1em;
  border: 0.25em solid transparent;
  border-right-color: white;
  border-radius: 50%;
  animation: _15BEO 0.7s linear infinite;
  opacity: 0;
}

._1gH9y {
  background-color: #EF394F;
  border: 0;
}

._22F7i {
  background-color: #000;
  color: #FFF;
}

._22F7i:after {
  border-right-color: #EF394F;
}

._3wy6b {
  color: #000;
}

._3wy6b:after {
  border-right-color: #000;
}

._22F7i._15BEO {
  border-color: transparent;
  background: transparent;
}

._2a549._15BEO {
  color: transparent;
  cursor: auto;
  cursor: initial;
}

._2a549._15BEO:after {
  opacity: 1;
}

._2a549:not(._15BEO):not(:disabled):hover {
  background-color: #000;
  color: #FFF;
}

._1gH9y:not(._15BEO):not(:disabled):hover {
  background-color: #EF394F;
  opacity: .9;
}

._22F7i:not(._15BEO):not(:disabled):hover {
  background-color: #EF394F;
  border-color: #EF394F;
}

._1BiQk:hover {
  background-color: #FFF !important;
  color: #000 !important;
}

.kp2OC {
  width: 144px;
  background: #F8F8F8;
  border-radius: 3px;
  border: 0;
  color: #A6A6A6;
}

.kp2OC._15BEO {
  border-color: transparent;
  background: black;
}

@media (max-width: 768px) {
  ._2a549 {
    padding: 0 36px;
  }
}
