@media (min-width: 1024px) {
  .FgckL {
    position: relative;
  }

  .FgckL .TrkjI {
    right: 0;
  }

  ._1qkHq {
    display: flex;
    align-items: center;
  }

  ._1qkHq ._1ciSn {
    height: 100%;
    background: #FAFAFA;
    cursor: pointer;
    white-space: nowrap;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  ._1qkHq ._1ciSn.qBd1P {
    background: #F3F3F3;
  }

  ._1qkHq ._1ciSn.qBd1P span {
    text-shadow: 0 0 0.01px #696969;
  }

  ._1qkHq span {
    margin-right: 10px;
    margin-left: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
  }

  ._1qkHq img {
    margin-right: 20px;
    opacity: 0.5;
    transform: rotate(-180deg);
  }

  ._1qkHq ._1ciSn.qBd1P img {
    transform: rotate(0);
    opacity: 1;
  }

  ._1ciSn:hover {
    background: #F3F3F3;
  }

  .TrkjI {
    position: absolute;
    top: 120%;
    opacity: 0;
    visibility: hidden;
    z-index: 998;
    background: #FAFAFA;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    width: 313px;
    max-height: 216px;
    overflow: auto;
    padding-bottom: 15px;
    padding-top: 15px;
  }


  .TrkjI.qBd1P {
    opacity: 1;
    visibility: visible;
    z-index: 998;
  }

  .upyLP {
    background: #FFFFFF;
    border-radius: 3px;
    margin: 0 9px 6px 15px;
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-height: 42px;
  }

    .upyLP:hover span {
      color: #EF394F;
    }

  .upyLP:last-child {
    margin: 0 9px 0 15px;
  }

  .upyLP span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  .upyLP.qBd1P span {
    font-weight: 600;
    color: #000000;
  }

}


@media (max-width: 1023px) {


  .FgckL {
    position: relative;
    width: 100%;
  }

  .FgckL .TrkjI {
    right: 0;
  }

  ._1qkHq {
    display: flex;
    align-items: center;
    padding: 0 21px;
    border-bottom: 1px solid #E1E1E1;
  }

  ._1qkHq ._1ciSn {
    height: 100%;
    cursor: pointer;
  }

  /*.sortFilter .sortButton.active {*/
  /*  background: #F3F3F3;*/
  /*}*/

  ._1qkHq ._1ciSn span {
    margin-right: 5px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    color: #696969;
    white-space: nowrap;
    max-width: 56px;
    overflow: hidden;
  }

  ._1ciSn img {
    opacity: 0.5;
    transform: rotate(-180deg);
  }

  ._1ciSn.qBd1P img {
    transform: rotate(0);
    opacity: 1;
  }

  .TrkjI {
    display: none;
    padding: 15px 0;
  }

  .TrkjI.CI_0z {
    width: 378px;
    height: 138px;
  }


  .TrkjI.qBd1P {
    display: block;
  }


  .upyLP {
    background: #FFFFFF;
    border-radius: 3px;
    margin: 0 9px 6px 5px;
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-height: 42px;
  }

  .upyLP div {
    margin-right: 10px;
  }



  .upyLP span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  .upyLP.qBd1P span {
    font-weight: 600;
    color: #000000;
  }


  ._3ha0S {
    display: flex;
    position: absolute;
    right: 0;
    top: 9%;
    margin-right: 10px;
  }

  ._3Dqga {
    display: block;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 339px;
    height: 44px;
    margin: 5px 0 13px 15px;
    padding-left: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    outline: none;
  }

  ._1qkHq ._1ciSn.qBd1P span {
    color: #000000;;
  }
}

