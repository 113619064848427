._1Lilo {
  display: flex;
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #696969;
  margin-top: 24px;
}

._1Lilo ul {
  margin: 0;
  padding: 0;
}

._1Lilo li {
  display: inline-block;
  margin-right: 5px;
}

._1Lilo li span {
  margin-left: 5px;
}

._1Lilo li a {
  text-decoration: none;
  outline: 0;
  color: #696969;
}

._1Lilo li a:hover,
li._17SIf {
  color: #EF394F;
}

._3T0f4._1MHqY a:hover {
  color: #EF394F;
}

._3T0f4._1MHqY a {
  color: #ffffff;
}

._3T0f4._1MHqY span {
  color: #ffffff;
}

@media (max-width: 1023px) {
  ._1Lilo {
    display: none;
  }
}
