@media (min-width: 1024px) {
  ._3vV9k:hover:not(.hzvZC) > ._126mm {
    display: flex;
  }

  .crYIX {
    display: none;
  }

  ._2MLjB {
    display: none;
  }

  ._2z53v:not(.hzvZC) {
    box-sizing: border-box;
    display: none;
    position: absolute;
    background: #FFFFFF;
    margin: auto;
    max-width: 1440px;
    width: 100%;
    top: 100%;
    left: 0;
    right: 0;
    padding: 20px 70px 34px;
    box-shadow: 0px 2038px 0px 2038px rgba(0, 0, 0, 0.3);
  }

  ._3_E61 {
    display: inline-block;
    vertical-align: top;
    width: 360px;
  }

  ._2NGXS {
    margin-top: 10px;
    outline: none;
    max-width: 450px;
  }

  ._2NGXS li {
    margin-bottom: 10px;
  }

  ._2NGXS a {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    text-decoration: none;
    text-align: left;
    bottom: 100px;
  }

  .QQy0B {
    display: inline-flex;
    vertical-align: top;
  }

  .QQy0B ._1HfN_ {
    margin-bottom: 12px;
    display: inline-flex;
    width: 107px;
    min-width: 50px;
    margin-right: 40px;

  }

  ._13kra {
    display: inline-block;
    margin-left: 50px;
    vertical-align: top;
    margin-top: 30px;
  }
}

@media (max-width: 1023px) {
  ._29ZJk {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 18px 19px 22px;
    height: 58px;
  }

  ._2MLjB {
    display: block;
  }

  ._3qbTw {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #000000;
  }

  ._3qbTw.highlighted {
    color: #EF394F;
  }

  ._2Ba3l {
    width: 334px;
    border: 1px solid #E8E8E8;
  }

  ._2z53v:not(.hzvZC) {
    box-sizing: border-box;
    display: block;
    background: #FFFFFF;
    margin: auto;
    max-width: 1440px;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .3s ease-in-out;
    right: 45px;
    left: 100%;
    position: absolute;
    z-index: 9;
  }

  ._2z53v:not(.hzvZC).active {
    transform: translateX(-100%);
  }

  ._3_E61 {
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }

  ._2NGXS {
    margin-top: 10px;
    outline: none;
    max-width: 450px;
  }

  ._2NGXS.active {
    background: #fafafa;
  }


  ._2NGXS li {
    margin-bottom: 10px;
  }

  ._2NGXS a {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    text-decoration: none;
    text-align: left;
    bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px 0 22px;
    line-height: 54px;
  }


}

