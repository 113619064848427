._14Kk4 {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

._3pyFy ._3Nnv3 {
  font-weight: bold;
}

@media (min-width: 1024px) {
  ._3pyFy ._21Nlz {
    position: relative;
  }

  ._3pyFy ._21Nlz .Mbx7c {
    right: 0;
    bottom: 15%;
    background: white;
  }

  ._3pyFy ._1Mm6O {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #000000;
  }

  ._3pyFy ._2vcdW {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    color: #000000;
  }

  ._3pyFy .Yl7dD {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    color: #333333;
  }

  ._3pyFy ._3Nnv3 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-size: 19px;
    line-height: 22px;
    color: #EF394F;
    margin-right: 9px;
  }

  ._3pyFy ._2SR6m {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #898989;
    margin: 12px 0 9px 0;
  }

  ._3pyFy {
    max-width: 500px;
  }

  ._3pyFy ._3DqIy:last-child {
    margin-right: 0;
  }


  ._3JcqO {
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    width: 117px;
    height: 47px;
    display: flex;
    align-items: center;
    margin-right: 27px;
    cursor: pointer;
    position: relative;
  }

  ._3JcqO input:disabled {
    cursor: not-allowed;
  }

  ._3JcqO img {
    margin: 0 6px 0 9px;
  }

  ._3JcqO span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._3pyFy ._3DqIy {
    width: 100%;
    height: 100%;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    margin: 0;
    border: 0;
  }

  ._3pyFy ._3DqIy:checked {
    border: 1px solid black;
  }

  ._3pyFy ._19MlO {
    display: block;
  }

  .uuT8g {
    display: flex;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  ._1ErRA {
    display: none;
  }
}

@media (max-width: 768px) {
  ._3pyFy ._21Nlz {
    display: none;
  }

  ._3pyFy ._1Mm6O {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #000000;
  }

  ._3pyFy ._2vcdW {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-right: 12px;
  }

  ._3pyFy .Yl7dD {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-size: 14px;
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    color: #333;
  }

  ._3pyFy ._3Nnv3 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    color: #EF394F;
    margin-right: 9px;
  }

  ._3pyFy ._2SR6m {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #898989;
    margin: 12px 0 9px 0;
  }

  ._3pyFy {
    margin-top: 20px;
  }

  ._3pyFy ._9GEwq {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 0;
  }

  ._9GEwq ._11bTM {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
    order: 1;
    margin-bottom: 5px;
  }

  ._9GEwq ._35D1G {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #515151;
    margin: 5px 0 7px 0;
    font-size: 13px;
    line-height: 16px;
    order: 2;
  }

  ._9GEwq ._3jCNs {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 14px;
    color: #696969;
    font-size: 11px;
    order: 3;
  }

  ._3pyFy ._2N62E {
    position: relative;
    max-height: 58px;
    overflow: hidden;
    transition: max-height 1s ease;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 222%;
    color: #696969;
    margin: 14px 0 12px 0;
  }

  ._3E3uU {
    display: flex;
    word-wrap: break-word;

  }


  ._3E3uU .O253M {
    min-width: 34px;
    height: 34px;
    background: #F2F2F2;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px 33px 0;
  }

  ._1ErRA {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #A3A3A3;
    margin-bottom: 44px;
  }

  ._3E3uU p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #A3A3A3;
    max-width: 100px;
    margin-top: 2px
  }

  ._3E3uU ._3PuZj {
    display: none
  }

  ._3E3uU ._35D1G {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #515151;
    margin: 0;
  }

  ._3pyFy .iwCM_ {
    background: #EF394F;
    border-radius: 3px;
    width: 218px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    border-width: 0;
    display: block;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    margin: 0 0 0 21px;
  }

  ._2ojZx {
    display: none;
  }

  ._2ojZx img {
    display: none;
  }


  ._3pyFy ._3DqIy:last-child {
    margin-right: 0;
  }


  /*.productOptions .variation img {*/
  /*    margin: 0 6px 0 9px;*/
  /*}*/
  ._2ws0n {
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    width: 86px;
    height: 42px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  ._2ws0n div {
    display: flex;
    justify-content: space-between;
    margin: 4px 10px;
  }

  ._2ws0n input:disabled {
    cursor: not-allowed;
  }

  ._2ws0n input {
    font-family: "Apercu Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #A9A9A9;
    border: 0;
    background: transparent;
  }

  ._2ws0n ._2i6iB {
    font-family: "Apercu Pro", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin: 0 23px;
    width: 100%;
  }

  ._3JcqO {
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 3px;
    width: 117px;
    height: 47px;
    display: flex;
    align-items: center;
    margin-right: 27px;
    cursor: pointer;
    position: relative;
  }

  ._3JcqO input:disabled {
    cursor: not-allowed;
  }

  ._3JcqO img {
    margin: 0 6px 0 9px;
  }

  ._3JcqO span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
  }

  ._3pyFy ._3DqIy {
    width: 100%;
    height: 100%;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    margin: 0;
    border: 0;
  }

  ._3pyFy ._3DqIy:checked {
    border: 1px solid black;
  }

  .uuT8g {
    display: flex;
    margin-bottom: 11px;
    margin-top: 33px;
  }
}
