.cCdjE .react-multi-carousel-list {
  display: flex;
  position: relative
}

.cCdjE .react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition
}

.cCdjE .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer
}

.cCdjE .react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.8)
}

.cCdjE .react-multiple-carousel__arrow::before {
  font-size: 20px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative
}

.cCdjE .react-multiple-carousel__arrow--left {
  left: calc(4% + 1px)
}

.cCdjE .react-multiple-carousel__arrow--left::before {
  content: "\E824"
}

.cCdjE .react-multiple-carousel__arrow--right {
  right: calc(4% + 1px)
}

.cCdjE .react-multiple-carousel__arrow--right::before {
  content: "\E825"
}

.cCdjE .react-multi-carousel-dot-list {
  position: absolute;
  bottom: 20px;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center
}

.cCdjE .react-multi-carousel-dot button {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background .5s;
  border-width: 1px;
  border-style: solid;
  border-color: grey;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  background: transparent;
  cursor: pointer
}

.cCdjE .react-multi-carousel-dot button:hover:active {
  background: #080808
}

.cCdjE .react-multi-carousel-dot--active button {
  background: #000000;
}

.cCdjE .react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden
}

@media (max-width: 767px) {
  .cCdjE .react-multi-carousel-item {
    height: 100%;
  }

  .cCdjE .react-multi-carousel-list {
    height: 100%;
  }

  .cCdjE .react-multi-carousel-track {
    height: 100%;
  }
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
  .cCdjE .react-multi-carousel-item {
    flex-shrink: 0 !important
  }

  .cCdjE .react-multi-carousel-track {
    overflow: visible !important
  }
}

._2hPS0 {
  align-items: center;
}

._1NOR0 {
  background: transparent;
  border-width: 0;
  position: absolute;
  right: 20px;
  transition: visibility 1s 0s, opacity .2s ease-in-out;
  opacity: 0.5;
}

.ab4jE {
  background: transparent;
  border-width: 0;
  position: absolute;
  left: 20px;
  transform: rotate(-180deg);
}

@media (min-width: 767px) and (max-width: 1024px) {
  ._3sP75 img {
    position: absolute;
    width: 53px;
    height: 53px;
    margin-top: -35px;
    z-index: 10;
    transition: visibility 1s 0s, opacity .2s ease-in-out;
    opacity: 0.5;
    cursor: pointer;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    right: 50px;
    border-radius: 50%;
  }

  ._3sP75 img:hover {
    opacity: 1;
  }

  ._3sP75._2XFFn img {
    right: 0;
    left: 50px;
    transform: rotate(-180deg);
  }
}

@media (min-width: 1024px) {
  ._1Usg1 {
    display: none !important;
  }

  ._3sP75 img {
    position: absolute;
    width: 53px;
    height: 53px;
    margin-top: -35px;
    z-index: 10;
    transition: visibility 1s 0s, opacity .2s ease-in-out;
    opacity: 0.5;
    cursor: pointer;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    right: 50px;
    border-radius: 50%;
  }

  ._3sP75 img:hover {
    opacity: 1;
  }

  ._3sP75._2XFFn img {
    right: 0;
    left: 50px;
    transform: rotate(-180deg);
  }
}

.cCdjE {
  height: calc(380 / 1440 * 100vw);
}

.cCdjE a,
.cCdjE picture {
  display: block;
}

.cCdjE a img {
  width: 100%;
}

@media (max-width: 767px) {
  .cCdjE {
    height: calc(500 / 768 * 100vw);
  }
}
