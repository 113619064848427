._2Vmgh {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 37px;
  color: #000000;
  text-align: left;
  margin: 0;
}

@media (min-width: 1024px) {
  .wJ9MO {
    margin: 48px auto 24px;
    max-width: 1440px;
    box-sizing: border-box;
    padding: 0 120px;
    /*position: relative;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .wJ9MO p {
    text-align: left;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #000000;
    max-width: 720px;
    margin-bottom: 0;
  }

  .wJ9MO ._1XHZK a {
    display: none;
  }

  .wJ9MO ._3114F {
    display: contents;
  }
}

@media (max-width: 1024px) {
  .wJ9MO {
    margin: 48px auto 24px;
    box-sizing: border-box;
    padding: 0 18px;
  }

  ._2Vmgh {
    font-size: 19px;
    line-height: 22px;
  }

  .wJ9MO p {
    display: none;
  }

  .wJ9MO ._1XHZK {
    display: flex;
    justify-content: space-between;
  }

  .wJ9MO ._1XHZK img {
    transform: rotate(-180deg);
  }

  .wJ9MO ._3114F {
    display: none;
  }
}

/* Tablet  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .wJ9MO {
    margin: 47px auto 40px;
    box-sizing: border-box;
    padding: 0 80px;
    /*position: relative;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .wJ9MO p {
    text-align: left;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 160.2%;
    color: #000000;
    max-width: 628px;
  }

  .wJ9MO a {
    display: flex;
    /*position: absolute;*/
    /*right: 0;*/
    align-items: center;
    align-self: center;
  }

  .wJ9MO ._1XHZK a {
    display: none;
  }
}
