@media (min-width: 1024px) {
  ._3juAB {
    display: flex;
    margin-top: 20px;
  }

  ._34Qit {
    margin: 0 auto 100px;
    max-width: 1440px;
    box-sizing: border-box;
    /*padding: 0 84px 0 120px*/
    padding: 0 120px;
    display: block;

  }

  ._34Qit .hwpeu {
    display: flex;
  }

  ._3bsta {
    max-width: 1440px;
    padding: 23px 0 19px 170px;
    position: relative;
    margin: auto
    /* margin: 0 0 57px 0;*/
  }

  ._3bsta .wBj7o ._2XPqY {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #696969;
    margin: 23px 60px 24px 0;
  }

  ._3bsta .wBj7o ._2XPqY._3LxBe {
    color: #000000;
  }

  ._3bsta .wBj7o {
    padding: 0 0 24px 0;
  }

  ._3bsta ._2BS5W {
    display: block;
  }

  ._3bsta ._2BS5W ._3K7Bf {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    width: 167px;
    height: 77px;
    justify-content: center;
    display: flex;
    align-items: center;
  }


  ._3bsta ._2BS5W ._2Oivu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
    cursor: pointer;
  }

  ._3bsta ._2BS5W ._2Oivu span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #696969;
    margin-left: 9px
  }

  ._3bsta ._2Xen5 {
    display: inline-flex;
  }

  ._3bsta ._2Xen5 span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 203%;
    color: #696969;
  }


  ._3bsta ._2BS5W {
    display: block;
  }

  ._3bsta ._2BS5W ._3K7Bf {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    width: 167px;
    height: 77px;
    justify-content: center;
    display: flex;
    align-items: center;
  }


  ._3bsta ._2BS5W ._2Oivu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
    cursor: pointer;
  }

  ._3bsta ._2BS5W ._2Oivu span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #696969;
    margin-left: 9px
  }


  ._1re7s {
    position: relative;
  }

  ._1re7s a {
    right: 0;
    background: #FAFAFA;
    bottom: 2%
  }

  ._2Xen5 ._37YHn, .-rqZT {
    position: absolute;
    bottom: -1em;
    z-index: 100;
    text-align: center;
  }

  ._2Xen5 ._37YHn {
    display: block;
  }

  ._2Xen5 .-rqZT {
    display: none;
  }

  ._2Xen5 ._37YHn:target {
    display: none;
  }

  ._2Xen5 ._37YHn:target ~ ._10iOq.-rqZT {
    display: block;
  }

  ._2Xen5 ._37YHn:target ~ ._1Q4Ku {
    max-height: 2000px;
  }

  ._2Xen5 ._37YHn:target ~ .VSflA {
    margin-top: 0;
  }

  ._2Xen5 ._1Q4Ku {
    position: relative;
    max-height: 200px;
    overflow: hidden;
    transition: max-height 1s ease;
  }

  ._2Xen5 .VSflA {
    background: linear-gradient(to bottom, rgba(250, 250, 250, 0) 0%, rgba(250, 250, 250, 1) 100%);
    height: 100px;
    margin-top: -100px;
    position: relative;
  }


  .WxeNO {
    max-width: 437px;
    height: 168px;
    margin: 0 208px 59px 0;
  }

  .WxeNO h1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 11px;
  }

  .WxeNO div {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
    margin-bottom: 17px;
  }

  ._10iOq {
    display: none;
    width: 244px;
    height: 48px;
    line-height: 48px;
    border: 1px solid #000000;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: transparent;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    outline: none;
    cursor: pointer;
  }

  ._10iOq:hover {
    background-color: #000000;
    color: #FFFFFF;
    transition: 0.5s;
  }
}

@media (max-width: 768px) {
  ._34Qit {
    margin: 0 auto 16px;
    max-width: 1440px;
    box-sizing: border-box;
    display: block;
  }

  ._34Qit > div:first-child {
    padding: 0 21px;
  }

  ._34Qit .hwpeu {
    display: flex;
  }

  ._3bsta {
    max-width: 1440px;
    padding: 23px 0 19px 170px;
    position: relative;
    margin: auto
    /* margin: 0 0 57px 0;*/
  }

  ._3bsta .wBj7o ._2XPqY {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #696969;
    margin: 23px 60px 24px 0;
  }

  ._3bsta .wBj7o ._2XPqY._3LxBe {
    color: #000000;
  }

  ._3bsta .wBj7o {
    padding: 0 0 24px 0;
  }

  ._3bsta ._2BS5W {
    display: block;
  }

  ._3bsta ._2BS5W ._3K7Bf {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    width: 167px;
    height: 77px;
    justify-content: center;
    display: flex;
    align-items: center;
  }


  ._3bsta ._2BS5W ._2Oivu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
    cursor: pointer;
  }

  ._3bsta ._2BS5W ._2Oivu span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #696969;
    margin-left: 9px
  }

  ._3bsta ._2Xen5 {
    display: inline-flex;
  }

  ._3bsta ._2Xen5 span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 203%;
    color: #696969;
  }


  ._3bsta ._2BS5W {
    display: block;
  }

  ._3bsta ._2BS5W ._3K7Bf {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    width: 167px;
    height: 77px;
    justify-content: center;
    display: flex;
    align-items: center;
  }


  ._3bsta ._2BS5W ._2Oivu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
    cursor: pointer;
  }

  ._3bsta ._2BS5W ._2Oivu span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #696969;
    margin-left: 9px
  }


  ._1re7s {
    position: relative;
  }

  ._1re7s a {
    right: 0;
    background: #FAFAFA;
    bottom: 2%
  }

  ._2Xen5 ._37YHn, .-rqZT {
    position: absolute;
    bottom: -1em;
    z-index: 100;
    text-align: center;
  }

  ._2Xen5 ._37YHn {
    display: block;
  }

  ._2Xen5 .-rqZT {
    display: none;
  }

  ._2Xen5 ._37YHn:target {
    display: none;
  }

  ._2Xen5 ._37YHn:target ~ ._10iOq.-rqZT {
    display: block;
  }

  ._2Xen5 ._37YHn:target ~ ._1Q4Ku {
    max-height: 2000px;
  }

  ._2Xen5 ._37YHn:target ~ .VSflA {
    margin-top: 0;
  }

  ._2Xen5 ._1Q4Ku {
    position: relative;
    max-height: 200px;
    overflow: hidden;
    transition: max-height 1s ease;
  }

  ._2Xen5 .VSflA {
    background: linear-gradient(to bottom, rgba(250, 250, 250, 0) 0%, rgba(250, 250, 250, 1) 100%);
    height: 100px;
    margin-top: -100px;
    position: relative;
  }


  .WxeNO {
    max-width: 437px;
    height: 168px;
    margin: 0 208px 59px 0;
  }

  .WxeNO h1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 11px;
  }

  .WxeNO div {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
    margin-bottom: 17px;
  }

  ._10iOq {
    display: none;
    width: 244px;
    height: 48px;
    line-height: 48px;
    border: 1px solid #000000;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: transparent;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    outline: none;
    cursor: pointer;
  }

  ._10iOq:hover {
    background-color: #000000;
    color: #FFFFFF;
    transition: 0.5s;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  ._34Qit {
    margin: 29px auto 100px;
    max-width: 1440px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    padding: 0;
  }

  ._34Qit .hwpeu {
    display: flex;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  ._34Qit {
    display: flex;
  }

}
