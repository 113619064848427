._2J2kr {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  color: #696969;
  font-size: 18px;
  line-height: 20px;
}

._2J2kr.open {
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  font-weight: 700;
}

@media(max-width: 1023px) {
  ._2J2kr {
    font-size: 16px;
    line-height: 22px;
  }

  ._2J2kr.open {
    font-size: 19px;
    line-height: 22px;
  }
}

