._3df7U {
  width: 100%;
  display: block;
  margin-top: 40px;
}

.BiODU {
  display: flex;
}

.BiODU button {
  background: #B9B9B9;
  border-radius: 3px;
  width: 383px;
  -webkit-appearance: none;
  margin-right: 42px;
  border-width: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

._2D24O {
  width: 44px;
  height: 44px;
  border-radius: 20px;
  background: #F2F2F2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

._2D24O img {
  width: 18px;
  height: 18px;
}

._2iiwq {
  background: #FAFAFA;
  padding: 18px 16px 36px;
}

._1lA9M {
  display: flex;
  align-items: center;
}
.m6Oak {
  margin-right: 10px;
  background-color: #EF394F;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

._1ggcc {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

._1K876 {
  margin-top: 16px;
  display: flex
}

._1K876 form {
  display: flex;
}

._1K876 form div {
  margin-right: 18px;
}

@media (max-width: 768px) {
  ._1K876 form div {
    margin-right: 0;
  }
  ._1K876 form {
    display: flex;
    flex-direction: column;
  }
}

._1K876 input {
  min-width: 300px;
}


@media(max-width: 1023px) {
  .BiODU {
    padding: 0 21px;
  }

  .BiODU button {
    width: 100%;
    height: 42px;
    margin-right: 0;
  }

  ._2D24O {
    display: none;
  }

  ._2iiwq {
    padding: 16px 21px 19px;
  }

  ._1ggcc {
    font-size: 14px;
    line-height: 17px;
  }

  ._1K876 {
    display: block
  }

  ._1K876 button {
    margin-top: 15px;
    width: 100%;
  }
}
