._-8Ad- {
  background: #FAFAFA;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 282px;
  margin: auto;
}

.vSapo {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.vSapo h2 {
  font-family: "TT Norms", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.vSapo span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #696969;
  margin-bottom: 30px;
}

._3pf8e {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

@media(max-width: 768px) {

  ._-8Ad- {
    background: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 20px 0 30px 0;
  }
  .vSapo h2 {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align: center;
  }

  .vSapo span {
    margin-bottom: 20px;
    font-size: 13px;
  }
}


